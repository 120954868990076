/* Tooltip container */
$arrow-color: #fff;
$animation-time: 1.2s;
$close-icon-position: calc(-18px - 14px);

.c-tool-tips {
  position: relative;
  //display: inline-block;
  //border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  //overflow: visible !important;
  .tooltiptext {
    animation: jumpTwo $animation-time infinite linear;
    visibility: hidden;
    background-color: #fff;
    color: #333 !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    padding: 7px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.25;
    white-space: nowrap;
    //cursor: pointer;
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    min-width: 130px;
    /* Fade in tooltip */
    opacity: 0;
    transition: all 0.5s;

    i {
      display: flex;
      width: 24px;
      height: 24px;
      font-size: 14px;
      line-height: 1;
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(255, 255, 255, 0.8);
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      //top:calc( (31.5px - 16px)/2);
      top: 4px;
      right: $close-icon-position;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(100% - 2px);
      left: calc(50% - 5px);
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: $arrow-color transparent transparent transparent;
    }
    &.tooltip-top {
      bottom: 120%;
      left: 50%;
      margin-left: -64px;
      &::after {
      }
    }
    &.tooltip-right {
      animation-name: jumpright;
      top: calc(50% - 16px);
      left: calc(100% + 10px);
      bottom: auto;
      margin-left: 0;
      &::after {
        top: calc(50% - 10px);
        right: calc(100% - 3px);
        left: auto;
        border-color: transparent $arrow-color transparent transparent;
      }
    }
    &.tooltip-bottom {
      top: 135%;
      left: 50%;
      margin-left: -60px;
      bottom: auto;
      &::after {
        top: -18px;
        left: 50%;
        border-color: transparent transparent $arrow-color transparent;
      }
    }
    &.tooltip-left {
      animation-name: jumpleft;
      top: calc(50% - 16px);
      right: 115%;
      bottom: auto;
      left: auto;
      margin-left: 0;

      i {
        right: auto;
        left: $close-icon-position;
      }

      &::after {
        top: calc(50% - 10px);
        left: calc(100% + 2px);
        border-color: transparent transparent transparent $arrow-color;
      }
    }
  }
  &.three {
    .tooltiptext {
      &.tooltip-top {
        bottom: calc(120% + 36px);
      }
    }
  }
  &.show {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}

.show-tour {
  .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.button-slide {
  & + .c-tool-tips {
    .tooltiptext {
      right: calc(104% + 58px);
    }
  }
  &.button-delete{
    & + .c-tool-tips {
      .tooltiptext {
        top:9px;
      }
    }
  }
  &:hover,
  .active {
    & + .c-tool-tips {
      .tooltiptext {
        right: calc(104% + 232px);
      }
    }
  }
}
