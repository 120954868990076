//
// payment widget
// Bottom left corner widget
//

&.payment {
  .c-widgets-box {
    @media only screen and (min-width: 993px) {
      max-height: 646px;
      width: 406px;
      left: 20px;
    }

    .c-widgets-body {
      background: #fff;
      flex-grow: 1;
      height: 0;

      .payment-form {
        height: 100%;

        .form-crm {
          //background-color: red;
          flex: 1 1 0%;
          overflow-y: auto;
          padding: 36px 31px 16px 31px;

          .container {
            padding: 0;
            .form-row-container {
              padding: 0 0 14px 0;
              margin: 0;
            }
            .form-row {
              margin: 0;
              textarea {
                max-height: 31px;
              }
            }
            // payment-stripe
            .payment-stripe{
              .StripeElement{
                  margin-top: 0;
              }
            }
          }

          &.inactive{
            padding-top: 10px !important;
          }

          .MuiInputBase-input {
            padding: 14.185px 13px 8.185px 13px !important;
          }

          .MuiInputLabel-outlined{
            &:not(.MuiInputLabel-shrink) {
              transform: translate(14px, 13px) scale(1);
              color: #b3b3b3;
            }
            &.MuiInputLabel-shrink{
              color: #b3b3b3 !important;
            }
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #ececec;
          }

          .c-card {
            padding: 0 5px;
            .card-input-container{
              height: 41px;
              padding: 11px 6.5px 6.5px 13px;
            }
          }

          .form-btn {
            padding: 21px 5px 0 5px;
            .boxed {
              padding: 0;
            }
          }

          .text-reCAPTCHA{
            line-height: normal;
          }

          .input-group {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%;

            .input-group-text {
              display: flex;
              align-items: center;
              padding: 0.375rem 0.75rem;
              margin-bottom: 0;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: #464e5f;
              text-align: center;
              white-space: nowrap;
              background-color: #f3f6f9;
              border: 1px solid #e5eaee;
              border-radius: 0.42rem;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            .form-control {
              position: relative;
              flex: 1 1 auto;
              width: 1%;
              min-width: 0;
              margin-left: -1px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              margin-top: 0;
            }
          }

          .form-control {
            background: #fcfcfc;
            border: 1px solid #ececec;
          }
        }
      }
    }
  }

  .c-widgets-button {
    right: auto;
    left: 20px;

    [class^="material-icons"] {
      margin-right: 8px;
      color: #fff;
    }
  }
}
