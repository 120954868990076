//default
$breakpoint-md : 768px;
@mixin media-breakpoint-md {
    @media (min-width: $breakpoint-md) {
        @content;
    }
}

$breakpoint-lg : 992px;
@mixin media-breakpoint-lg {
    @media (min-width: $breakpoint-lg) {
        @content;
    }
}

$breakpoint-xl : 1400px;
@mixin media-breakpoint-xl {
    @media (min-width: $breakpoint-xl) {
        @content;
    }
}

$breakpoint-xxl : 1600px;
@mixin media-breakpoint-xxl {
    @media (min-width: $breakpoint-xxl) {
        @content;
    }
}

// down
$breakpoint-down-md : 767.98px;
@mixin media-breakpoint-md-down {
    @media (max-width: $breakpoint-down-md) {
        @content;
    }
}

$breakpoint-down-lg : 991.98px;
@mixin media-breakpoint-lg-down {
    @media (max-width: $breakpoint-down-lg) {
        @content;
    }
}

// height wise down
$breakpoint-down-height-md : 700px;
@mixin media-breakpoint-down-height-md {
    @media (max-height: $breakpoint-down-height-md) {
        @content;
    }
}

// Combination of width and height
$breakpoint-comb-height-md : 700px;
$breakpoint-comb-width-md : 767px;
@mixin media-comb-one {
    @media (min-height: $breakpoint-comb-height-md) and (min-width: $breakpoint-comb-width-md) {
        @content;
    }
}

$breakpoint-comb-two-height-md : 600px;
@mixin media-comb-two {
    @media (min-height: $breakpoint-comb-two-height-md) and (min-width: $breakpoint-comb-width-md) {
        @content;
    }
}