//
// block 56
//

// variables
$block_56_border_radius: 10px;

// SCSS
.block-56 {
  .item {
    line-height: 1.5;
    font-size: 14px;
    color: white;
    padding: 6px 3px;

    @include media-breakpoint-md {
      padding: 22px 12px;
    }

    margin-bottom: 12px;
    max-height: 93px;
    overflow: hidden;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $block_56_border_radius;

    &[data-overlay]:before {
      border-radius: $block_56_border_radius;
    }

    .container {
      position: relative;
      z-index: 1;
    }

    h4 {
      margin-bottom: 4px;
      font-weight: 600 !important;
    }

    .price {
      font-size: 13px;
      border: 1px solid white;
      border-radius: 16px;
      display: inline-block;
      padding: 2px 8px;
      margin-bottom: 2px;
      margin: 6px 16px 6px 0;

      @include media-breakpoint-md {
        margin: 0 0 2px 0;
      }
    }

    .available {
      display: inline-block;
      font-style: italic;
      position: absolute;
      bottom: 10px;
      right: 15px;

      @include media-breakpoint-md {
        position: static;
      }
    }

    .includes {
      font-size: 13px;
    }

    a {
      &.btn {
        padding: 1px 6px;
        position: absolute;
        top: 1px;
        right: 14px;
        font-size: 12px;
        @include media-breakpoint-md {
          padding: 6px 30px;
          margin-top: 0;
          position: static;
          font-size: 14px;
        }
      }
    }
  }
}