//
// package
//

@import "../../common/breakpoints";

// mixins
@mixin text-color($color) {

  h3,
  h4,
  li {
    color: $color;
  }
}

.page {
  &.package {

    // aside
    .login-aside {

      @include text-color(#252525);

      // style
      &.style {

        // dark overlay
        &.dark-overlay{
          &::before {
            
            background-color: rgba(0, 0, 0, 0.5);
            content: " ";
            z-index: -1 !important;

            @include media-breakpoint-lg-down {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }

            @include media-breakpoint-lg {
              position: fixed;
              height: 100vh;
              width: 100%;
              max-width: 450px;
            }

            @include media-breakpoint-xl {
              max-width: 600px;
            }

          }
        }

        // details
        .details {
          padding: 32px 32px 20px 32px;
        }

        // photo
        &.photo {

          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          @include media-breakpoint-lg-down {
            position: relative;
            z-index: 0;
          }

          @include text-color(#fff);

          

        }

        // illustration
        &.illustration {

          // details
          .details {
            @include media-breakpoint-lg {
              padding-top: 140px;
            }
          }

        }


      }

    }

  }
}