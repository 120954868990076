.block-51 .text-muted {
	color: #8492a6 !important;
}

.block-51 h6 {
	font-size: 16px !important;
}

.block-51 .h4,
.block-51 h4 {
	font-size: 1.5rem;
	line-height: 1.5;
	font-weight: 600;
}

.block-51 .rounded {
	border-radius: 6px !important;
}

.block-51 .shadow {
	-webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
	box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.block-51 .pricing-rates {
	position: relative;
	z-index: 1;
	border-bottom: 3px solid #adb5bd !important;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
    height: 100%;
}

.block-51 .pricing-rates .price,
.block-51 .pricing-rates .h1.price {
	font-weight: 400 !important;
}

.block-51 .pricing-rates.business-rate:hover {
	-webkit-transform: scale(1.03);
	transform: scale(1.03);
	z-index: 2;
	border-color: #2f55d4 !important;
	-webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
	box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.block-51 .pricing-rates.starter-plan {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	z-index: 2;
	border-color: #2f55d4 !important;
}

@media (max-width: 767px) {
	.block-51 .pricing-rates.starter-plan {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.block-51 .pricing-rates.best-plan:hover {
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
}

.block-51 .card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}

.block-51 .card .card-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: 1px;
}

.block-51 .list-unstyled li{
	position: relative;
	z-index: 0;
	padding: 0 0 0 23px;
}
.block-51 .list-unstyled li svg{
	position: absolute;
	top:5px;left:0;
}
