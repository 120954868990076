// Block 53
@import "../../common/breakpoints";

section {
  &.block-53 {
   &.editable-block{
    .imagebg:not(.image--light) span{
      color: inherit;
    }
   }
  }
}
