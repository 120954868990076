//
// Comopnents
// Week Calendar

.week-calendar {
  .top-bar {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    padding: 24px 0;

    a {
      font-weight: 400;
      color: var(--website-primary-color);
      text-decoration: underline;
    }
  }

  .days {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    text-transform: uppercase;

    .btn {
      margin: 0 1px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      transition: 0.25s ease-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 8px;
      font-weight: 500;
      line-height: 1;
      color: var(--website-primary-color);
      span{
        font-size: 14px;
        margin-top: 4px;
        color: var(--website-primary-color);
      }
      &.disabled{
        cursor: not-allowed;
      }
      &.selected{
        background-color: var(--website-primary-color);
        color: #fff;
        span{
          color: #fff;  
        }
      }
      @include media-breakpoint-md {
        width: 70px;
        height: 70px;
        margin: 0 2px;
        span{
          font-size: 25px;
          font-weight: 300;
        }
      }
    }

  }
}