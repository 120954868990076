// boi-card-modal

// default
.boi-card-modal {
  z-index: 2001;
  
  .modal-content {
    max-width: 500px !important;
  }

  .boi-card {
    margin: -2.78571429em;
    //background-color: #e1e1e1;

    .modal-head {
      padding: 20px 23px;
      border-bottom: 1px solid #ecf0f3;
      line-height: normal;
    }

    .modal-body {
      padding: 1.75rem;
    }
    
  }

  @include media-breakpoint-md-down {
    transition: all 0.4s ease-in-out !important;
    bottom: -40vh;

    &.show {
      bottom: 0;
      top: auto;
      height: auto;
    }

    .modal-dialog {
      margin: 0 auto;

      .modal-content {
        border-radius: 0;

        .modal-body {
          padding: 16px;
        }
      }
    }
  }

}