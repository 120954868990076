//
// Comopnents
// Product

@import "../../common/breakpoints";

.tile-product {
  @include media-breakpoint-md {
    display: flex;
    flex-direction: column;
    .feature{
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      .feature__body{
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        .price{
          margin-bottom: auto;
        }
      }
    }
  }
  a {
    overflow: hidden;
    border: 1px solid #ececec;
    border-radius: 6px 6px 0 0;
    span {
      &.tile-image {
        height: 234px;
        width: 100%;
        background: #fff url() center no-repeat;
        background-size: auto 100%;
        transition: all .3s;
      }
    }
    &:hover,
    &:focus{
      span{
        transform: scale(1.1);
      }
    }
  }
}
