//
// Comopnents
// Page

/* starts, btn-fixed-left classes */
.btn-fixed-left-container {
  position: fixed;
  left: -5px;
  top: calc(50% - 137px);
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  .btn,
  .btn:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border: 0;
    transform: none;
  }
  .btn {
    line-height: 26px;
    font-size: 14px;
    padding: 10px 11px 10px 15px;
    margin: 4px 0 4px 0;
    height: 50px;
    overflow: hidden;
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
    .btn__text {
      color: #ffffff !important;
      font-weight: normal;
      font-size: 14px;
      font-weight: 600;
      display: inline-flex;
      padding: 2px 0 2px 0;
      justify-content: center;
      align-items: center;
      /* background-color: red; */
      .material-icons {
        width: 24px;
        /* background-color: blue; */
      }
      .slide-text {
        /* background-color: green; */
        text-align: left;
        line-height: 24px;
        transition: all 0.5s;
        overflow: hidden;
        width: 0;
      }
    }
    &:hover,
    &.active {
      .slide-text {
        margin-left: 8px;
        width: 143px;
        text-align: center;
      }
    }
  }
}
/* end, btn-fixed-left classes */
