/**!**************/
/**! Contents **/
/**! 01. Reset **/
/**! 02. Typography **/
/**! 03. Position **/
/**! 04. Element Size **/
/**! 05. Images **/
/**! 06. Buttons **/
/**! 07. Icons **/
/**! 08. Lists **/
/**! 09. Lightbox **/
/**! 10. Menus **/
/**! 11. Dropdowns **/
/**! 12. Form Elements **/
/**! 13. Accordions **/
/**! 14. Breadcrumbs **/
/**! 15. Radials **/
/**! 16. Tabs **/
/**! 17. Boxes **/
/**! 18. Sliders Flickity **/
/**! 19. Hover Elements **/
/**! 20. Masonry **/
/**! 21. Modals **/
/**! 22. Maps **/
/**! 23. Parallax **/
/**! 24. Notifications **/
/**! 25. Video **/
/**! 26. Colors **/
/**! 27. Image Blocks **/
/**! 28. MailChimp & Campaign Monitor **/
/**! 29. Twitter **/
/**! 30. Transitions **/
/**! 31. Switchable Sections **/
/**! 32. Typed Effect **/
/**! 33. Gradient BG **/
/**! 34. Bars **/
/**! 35. Navigation In Page **/
/**! 36. Helper Classes **/
/**! 37. Spacing **/
/**! 38. Boxed Layout **/
/**! 39. Wizard **/
/**! 40. Alerts **/
/**! 41. Progress - Horizontal **/
/**! 42. Theme Overrides **/
/**!**************/
/**!**************/
/**!**************/
/**! 01. Reset **/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}
/**! 02. Typography **/
html {
  font-size: 87.5%;
}
@media all and (max-width: 768px) {
  html {
    font-size: 81.25%;
  }
}
body {
  font-size: 1em;
  line-height: 1.85714286em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #666666;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  font-weight: 300;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
}
h1,
.h1 {
  font-size: 3.14285714em;
  line-height: 1.31818182em;
}
h2,
.h2 {
  font-size: 2.35714286em;
  line-height: 1.36363636em;
}
h3,
.h3 {
  font-size: 1.78571429em;
  line-height: 1.5em;
}
h4,
.h4 {
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
h5,
.h5 {
  font-size: 1em;
  line-height: 1.85714286em;
}
h6,
.h6 {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.lead {
  font-size: 1.25714286em;
  line-height: 1.68421053em;
}
@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.35714286em;
    line-height: 1.36363636em;
  }
  h2,
  .h2 {
    font-size: 1.78571429em;
    line-height: 1.5em;
  }
  h3,
  .h3 {
    font-size: 1.35714286em;
    line-height: 1.85714286em;
  }
  .lead {
    font-size: 1.35714286em;
    line-height: 1.36842105em;
  }
}
p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.85714286em;
}
ul,
ol {
  list-style: none;
  line-height: 1.85714286em;
}
ul.bullets {
  list-style: inside;
}
ol {
  list-style-type: upper-roman;
  list-style-position: inside;
}
blockquote {
  font-size: 1.78571429em;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
strong {
  font-weight: 600;
}
hr {
  margin: 1.85714286em 0;
  border-color: #fafafa;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
/*! Typography -- Helpers */
.type--fade {
  opacity: .5;
}
.type--uppercase {
  text-transform: uppercase;
}
.type--bold {
  font-weight: bold;
}
.type--italic {
  font-style: italic;
}
.type--fine-print {
  font-size: 0.85714286em;
}
.type--strikethrough {
  text-decoration: line-through;
  opacity: .5;
}
.type--underline {
  text-decoration: underline;
}
.type--body-font {
  font-family: 'Open Sans', 'Helvetica';
}
/**! 03. Position **/
body {
  overflow-x: hidden;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.pos-absolute.container {
  left: 0;
  right: 0;
}
.pos-top {
  top: 0;
}
.pos-bottom {
  bottom: 0;
}
.pos-right {
  right: 0;
}
.pos-left {
  left: 0;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@media all and (max-width: 767px) {
  .float-left,
  .float-right {
    float: none;
  }
  .float-left-xs {
    float: left;
  }
  .float-right-xs {
    float: right;
  }
}
.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
@media all and (max-height: 600px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
.pos-vertical-align-columns {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.pos-vertical-align-columns > div[class*='col-'] {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
@media all and (max-width: 990px) {
  .pos-vertical-align-columns {
    display: block;
    width: auto;
  }
  .pos-vertical-align-columns > div[class*='col-'] {
    display: block;
  }
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
@media all and (max-width: 767px) {
  .block--xs {
    display: block;
  }
}
@media all and (max-width: 990px) {
  .text-center-md {
    text-align: center;
  }
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
}
@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
}
/**! 04. Element Size **/
.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}
@media all and (max-width: 767px) {
  .height-100,
  .height-90,
  .height-80,
  .height-70,
  .height-60,
  .height-50,
  .height-40,
  .height-30,
  .height-20,
  .height-10 {
    height: auto;
    padding: 4em 0;
  }
}
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  }
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}
section.height-auto {
  height: auto;
}
section.height-auto .pos-vertical-center {
  top: 0;
  position: relative;
  transform: none;
}
@media all and (max-width: 767px) {
  div[class*='col-'][class*='height-'] {
    padding-top: 5.57142857em !important;
    padding-bottom: 5.57142857em !important;
  }
}
/**! 05. Images **/
img {
  max-width: 100%;
  margin-bottom: 1.85714286em;
}
/*p+img, img:last-child{
	margin-bottom: 0;
}*/
.img--fullwidth {
  width: 100%;
}
[data-grid="2"].masonry {
  -webkit-column-count: 2;
  -webkit-column-gap: 0;
  -moz-column-count: 2;
  -moz-column-gap: 0;
  column-count: 2;
  column-gap: 0;
}
[data-grid="2"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="2"] li {
  width: 50%;
  display: inline-block;
}
[data-grid="3"].masonry {
  -webkit-column-count: 3;
  -webkit-column-gap: 0;
  -moz-column-count: 3;
  -moz-column-gap: 0;
  column-count: 3;
  column-gap: 0;
}
[data-grid="3"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="3"] li {
  width: 33.33333333%;
  display: inline-block;
}
[data-grid="4"].masonry {
  -webkit-column-count: 4;
  -webkit-column-gap: 0;
  -moz-column-count: 4;
  -moz-column-gap: 0;
  column-count: 4;
  column-gap: 0;
}
[data-grid="4"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="4"] li {
  width: 25%;
  display: inline-block;
}
[data-grid="5"].masonry {
  -webkit-column-count: 5;
  -webkit-column-gap: 0;
  -moz-column-count: 5;
  -moz-column-gap: 0;
  column-count: 5;
  column-gap: 0;
}
[data-grid="5"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="5"] li {
  width: 20%;
  display: inline-block;
}
[data-grid="6"].masonry {
  -webkit-column-count: 6;
  -webkit-column-gap: 0;
  -moz-column-count: 6;
  -moz-column-gap: 0;
  column-count: 6;
  column-gap: 0;
}
[data-grid="6"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="6"] li {
  width: 16.66666667%;
  display: inline-block;
}
[data-grid="7"].masonry {
  -webkit-column-count: 7;
  -webkit-column-gap: 0;
  -moz-column-count: 7;
  -moz-column-gap: 0;
  column-count: 7;
  column-gap: 0;
}
[data-grid="7"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="7"] li {
  width: 14.28571429%;
  display: inline-block;
}
[data-grid="8"].masonry {
  -webkit-column-count: 8;
  -webkit-column-gap: 0;
  -moz-column-count: 8;
  -moz-column-gap: 0;
  column-count: 8;
  column-gap: 0;
}
[data-grid="8"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="8"] li {
  width: 12.5%;
  display: inline-block;
}
@media all and (max-width: 767px) {
  [data-grid]:not(.masonry) li {
    width: 33.333333%;
  }
  [data-grid="2"]:not(.masonry) li {
    width: 50%;
  }
  [data-grid].masonry {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.background-image-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
  background: #252525;
}
.background-image-holder:not([class*='col-']) {
  width: 100%;
}
.background-image-holder.background--bottom {
  background-position: 50% 100% !important;
}
.background-image-holder.background--top {
  background-position: 50% 0% !important;
}
.image--light .background-image-holder {
  background: none;
}
.background-image-holder img {
  display: none;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: '';
  background: #252525;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
[data-overlay]
  *:not(.container):not(.background-image-holder):not(.yt-container):not(
    .rdw-editor-toolbar
  ):not(.rdw-link-wrapper):not(.rdw-link-modal) {
  z-index: 2;
}
[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #4a90e2;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
[data-overlay="10"]:before {
  opacity: 1;
}
[data-overlay="0"]:before {
  opacity: 0;
}
[data-scrim-bottom] {
  position: relative;
}
[data-scrim-bottom]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #252525));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #252525 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  backface-visibility: hidden;
}
[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #a5a5a5;
  color: #f1f1f1;
}
[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-bottom].image--light:before {
  background: #fff;
}
[data-scrim-bottom="1"]:before {
  opacity: 0.1;
}
[data-scrim-bottom="2"]:before {
  opacity: 0.2;
}
[data-scrim-bottom="3"]:before {
  opacity: 0.3;
}
[data-scrim-bottom="4"]:before {
  opacity: 0.4;
}
[data-scrim-bottom="5"]:before {
  opacity: 0.5;
}
[data-scrim-bottom="6"]:before {
  opacity: 0.6;
}
[data-scrim-bottom="7"]:before {
  opacity: 0.7;
}
[data-scrim-bottom="8"]:before {
  opacity: 0.8;
}
[data-scrim-bottom="9"]:before {
  opacity: 0.9;
}
[data-scrim-bottom="10"]:before {
  opacity: 1;
}
[data-scrim-top] {
  position: relative;
}
[data-scrim-top]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, left bottom, color-stop(0%, #252525), color-stop(100%, rgba(0, 0, 0, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}
[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}
[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-top].image--light:before {
  background: #fff;
}
[data-scrim-top="1"]:before {
  opacity: 0.1;
}
[data-scrim-top="2"]:before {
  opacity: 0.2;
}
[data-scrim-top="3"]:before {
  opacity: 0.3;
}
[data-scrim-top="4"]:before {
  opacity: 0.4;
}
[data-scrim-top="5"]:before {
  opacity: 0.5;
}
[data-scrim-top="6"]:before {
  opacity: 0.6;
}
[data-scrim-top="7"]:before {
  opacity: 0.7;
}
[data-scrim-top="8"]:before {
  opacity: 0.8;
}
[data-scrim-top="9"]:before {
  opacity: 0.9;
}
[data-scrim-top="10"]:before {
  opacity: 1;
}
.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #252525;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #666666;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p,
div[data-overlay] ul {
  color: #fff;
}
.parallax {
  overflow: hidden;
}
.parallax .background-image-holder {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
}
.image--xxs {
  max-height: 1.85714286em;
}
.image--xs {
  max-height: 3.71428571em;
}
.image--sm {
  max-height: 5.57142857em;
}
.image--md {
  max-height: 7.42857143em;
}
/**! 06. Buttons **/
.btn {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  border-radius: 6px;
  padding-top: 0.46428571em;
  padding-bottom: 0.46428571em;
  padding-right: 2.78571429em;
  padding-left: 2.78571429em;
  border: 1px solid #252525;
  border-width: 1px;
  font-size: inherit;
  line-height: 1.85714286em;
}
.btn .btn__text,
.btn i {
  color: #252525;
  border-color: #252525;
  font-weight: 700;
  font-size: 0.85714286em;
}
.btn[class*='col-'] {
  margin-left: 0;
  margin-right: 0;
}
.btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.btn.bg--facebook,
.btn.bg--twitter,
.btn.bg--instagram,
.btn.bg--googleplus,
.btn.bg--pinterest,
.btn.bg--dribbble,
.btn.bg--behance,
.btn.bg--dark {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn.bg--facebook .btn__text,
.btn.bg--twitter .btn__text,
.btn.bg--instagram .btn__text,
.btn.bg--googleplus .btn__text,
.btn.bg--pinterest .btn__text,
.btn.bg--dribbble .btn__text,
.btn.bg--behance .btn__text,
.btn.bg--dark .btn__text {
  color: #fff;
}
.btn.bg--facebook .btn__text i,
.btn.bg--twitter .btn__text i,
.btn.bg--instagram .btn__text i,
.btn.bg--googleplus .btn__text i,
.btn.bg--pinterest .btn__text i,
.btn.bg--dribbble .btn__text i,
.btn.bg--behance .btn__text i,
.btn.bg--dark .btn__text i {
  color: #fff;
}
.btn.bg--facebook:hover,
.btn.bg--twitter:hover,
.btn.bg--instagram:hover,
.btn.bg--googleplus:hover,
.btn.bg--pinterest:hover,
.btn.bg--dribbble:hover,
.btn.bg--behance:hover,
.btn.bg--dark:hover {
  opacity: .9;
}
.btn.bg--error {
  background: #e23636;
  border-color: #e23636 !important;
}
.btn.bg--error:hover {
  background: #e54c4c;
  border-color: #e54c4c !important;
  color: #fff !important;
}
.btn.bg--error .btn__text {
  color: #fff;
}
.btn.bg--error .btn__text i {
  color: #fff;
}
@media all and (min-width: 768px) {
  .btn + .btn {
    margin-left: 1.85714286em;
  }
}
.btn:first-child {
  margin-left: 0;
}
.btn:last-child {
  margin-right: 0;
}
.btn--xs {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.39285714em;
  padding-left: 1.39285714em;
}
.btn--sm {
  padding-top: 0.30952381em;
  padding-bottom: 0.30952381em;
  padding-right: 1.85714286em;
  padding-left: 1.85714286em;
}
.btn--lg {
  padding-top: 0.58035714em;
  padding-bottom: 0.58035714em;
  padding-right: 3.48214286em;
  padding-left: 3.48214286em;
}
.btn--lg .btn__text {
  font-size: 1.07142857em;
}
.btn--primary,
.btn--primary:visited {
  background: #4a90e2;
  border-color: #4a90e2;
}
.btn--primary .btn__text,
.btn--primary:visited .btn__text {
  color: #fff;
}
.btn--primary .btn__text i,
.btn--primary:visited .btn__text i {
  color: #fff;
}
.btn--primary:hover {
  background: #609de6;
}
.btn--primary:active {
  background: #3483de;
}
.btn--primary-1,
.btn--primary-1:visited {
  background: #31639c;
  border-color: #31639c;
}
.btn--primary-1 .btn__text,
.btn--primary-1:visited .btn__text {
  color: #fff;
}
.btn--primary-1:hover {
  background: #376faf;
}
.btn--primary-1:active {
  background: #2b5789;
}
.btn--primary-2,
.btn--primary-2:visited {
  background: #465773;
  border-color: #465773;
}
.btn--primary-2 .btn__text,
.btn--primary-2:visited .btn__text {
  color: #fff;
}
.btn--primary-2:hover {
  background: #506383;
}
.btn--primary-2:active {
  background: #3c4b63;
}
.btn--secondary {
  background: #fafafa;
  border-color: #fafafa;
}
.btn--secondary:hover {
  background: #ffffff;
}
.btn--secondary:active {
  background: #f5f5f5;
}
.btn--white {
  background: #fff;
  color: #252525;
  border-color: #fff;
}
.btn--white i {
  color: #252525;
}
.btn--transparent {
  background: none;
  border-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}
.btn--transparent.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled {
  background: none;
}
.btn--unfilled.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled.btn--white i {
  color: #fff;
}
.btn--floating {
  position: fixed;
  bottom: 3.71428571em;
  right: 3.71428571em;
  z-index: 10;
}
/**! 07. Icons **/
.icon {
  line-height: 1em;
  font-size: 3.14285714em;
}
.icon--xs {
  font-size: 1em;
}
.icon--sm {
  font-size: 2.35714286em;
}
.icon--lg {
  font-size: 5.57142857em;
}
/**! 08. Lists **/
ul:last-child {
  margin: 0;
}
.list-inline li {
  padding: 0 1em;
  margin-left: 0;
}
.list-inline li:first-child {
  padding-left: 0;
}
.list-inline li:last-child {
  padding-right: 0;
}
.list-inline.list-inline--narrow li {
  padding: 0 .5em;
}
.list-inline.list-inline--wide li {
  padding: 0 2em;
}
/**! 09. Lightbox **/
.lb-outerContainer {
  border-radius: 0;
}
.lb-outerContainer .lb-container {
  padding: 0;
}
.lb-outerContainer .lb-container img {
  margin: 0;
}
.lightbox-gallery {
  overflow: hidden;
}
.lightbox-gallery li {
  float: left;
}
.lightbox-gallery li img {
  margin: 0;
  width: 100%;
}
.lightbox-gallery.gallery--gaps li {
  padding: 0.46428571em;
}
/**! 10. Menus **/
.menu-horizontal > li:not(:hover) > a,
.menu-horizontal > li:not(:hover) > span,
.menu-horizontal > li:not(:hover) > .modal-instance > .modal-trigger {
  opacity: .5;
}
.menu-horizontal > li > a,
.menu-horizontal > li > span,
.menu-horizontal > li > .modal-instance > .modal-trigger {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  color: #252525;
}
.menu-horizontal > li > a:hover,
.menu-horizontal > li > span:hover,
.menu-horizontal > li > .modal-instance > .modal-trigger:hover {
  color: #252525;
}
.bg--dark .menu-horizontal > li > a,
.bg--dark .menu-horizontal > li > span {
  color: #fff;
}
.bg--dark .menu-horizontal > li > a:hover,
.bg--dark .menu-horizontal > li > span:hover {
  color: #fff;
}
.menu-vertical {
  width: 100%;
}
.menu-vertical li {
  width: 100%;
}
.menu-vertical li a {
  font-weight: normal;
}
@media all and (min-width: 990px) {
  .menu-horizontal {
    display: inline-block;
  }
  .menu-horizontal > li {
    display: inline-block;
  }
  .menu-horizontal > li:not(:last-child) {
    margin-right: 1.85714286em;
  }
  .menu-vertical {
    display: inline-block;
  }
  .menu-vertical li {
    white-space: nowrap;
  }
  .menu-vertical .dropdown__container {
    top: 0;
  }
  .menu-vertical .dropdown__container .dropdown__content:not([class*='bg-']) {
    background: #ffffff;
  }
  .menu-vertical .dropdown__container .dropdown__content {
    transform: translateX(75%);
  }
}
/**! 11. Dropdowns **/
.dropdown {
  position: relative;
}
.dropdown .dropdown__container {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 999;
}
.dropdown .dropdown__container .dropdown__container:before {
  height: 0;
}
.dropdown .dropdown__content {
  padding: 1.85714286em;
}
.dropdown .dropdown__content:not([class*='col-']) {
  width: 18.57142857em;
}
.dropdown .dropdown__content:not([class*='bg-']) {
  background: #ffffff;
}
.dropdown .dropdown__content:not([class='bg--dark']) .menu-vertical a {
  color: #666666;
}
.dropdown .dropdown__trigger {
  cursor: pointer;
  user-select: none;
}
.dropdown.dropdown--active > .dropdown__container {
  opacity: 1;
}
.dropdown.dropdown--active > .dropdown__container > .container > .row > .dropdown__content {
  pointer-events: all;
}
@media all and (min-width: 991px) {
  .dropdown .dropdown__container:before {
    height: 0.92857143em;
    width: 100%;
    content: '';
    display: block;
  }
  .dropdown .dropdown__content.dropdown__content--md {
    padding: 2.78571429em;
  }
  .dropdown .dropdown__content.dropdown__content--lg {
    padding: 3.71428571em;
  }
  .dropdown .dropdown__content.dropdown__content--xlg {
    padding: 4.64285714em;
  }
}
@media all and (max-width: 767px) {
  .dropdown .dropdown__container {
    min-width: 100%;
    position: relative;
    display: none;
  }
  .dropdown .dropdown__content {
    padding: 1.85714286em;
    left: 0 !important;
  }
  .dropdown.dropdown--active > .dropdown__container {
    display: block;
  }
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container {
  opacity: 1;
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container:before {
  pointer-events: all;
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container .dropdown__content {
  pointer-events: all;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container {
  opacity: 1;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container:before {
  pointer-events: all;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container .dropdown__content {
  pointer-events: all;
}
@media all and (max-width: 990px) {
  body.dropdowns--hover .dropdown:not(.dropdown--click).dropdown--active > .dropdown__container {
    display: block;
  }
  body.dropdowns--hover .dropdown:not(.dropdown--click).dropdown--active > .dropdown__container:before {
    pointer-events: all;
  }
}
@media all and (max-width: 767px) {
  .dropdown.dropdown--absolute .dropdown__container {
    position: absolute;
  }
}
/**! 12. Form Elements **/
form {
  max-width: 100%;
}
form + form {
  margin-top: 30px;
}
form:before,
form:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
form:after {
  clear: both;
}
label {
  margin: 0;
  font-size: 1.14285714em;
  font-weight: 400;
}
input[type],
textarea,
select {
  -webkit-appearance: none;
  background: #fcfcfc;
  padding: 0.46428571em;
  border-radius: 6px;
  border: 1px solid #ececec;
}
input[type]:focus,
textarea:focus,
select:focus {
  outline: none;
}
input[type]:active,
textarea:active,
select:active {
  outline: none;
}
input[type]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  /* Firefox 18- */
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type="image"] {
  border: none;
  padding: none;
  width: auto;
}
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
}
select {
  cursor: pointer;
  padding-right: 1.85714286em;
  -webkit-appearance: none;
}
select::ms-expand {
  display: none;
}
input[type="submit"] {
  background: none;
  outline: none;
  border: none;
  background: #4a90e2;
  padding: 0.46428571em 2.78571429em 0.46428571em 2.78571429em;
  color: #fff;
}
@keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
button {
  background: none;
}
button[type="submit"].btn--loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  color: rgba(0, 0, 0, 0);
}
button[type="submit"].btn--loading * {
  opacity: 0;
}
button[type="submit"].btn--loading:after {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background: #ddd;
  animation: load 1.5s ease-out infinite;
  left: 0;
  top: 0;
}
button[type="submit"].btn--loading .btn__text {
  opacity: 0;
}
button:focus {
  outline: none !important;
}
button.bg--error {
  color: #fff;
}
.input-icon {
  position: relative;
}
.input-icon i {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  position: absolute;
}
.input-checkbox,
.input-radio,
.input-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
}
.input-checkbox input,
.input-radio input,
.input-select input {
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
}
.input-checkbox label,
.input-radio label,
.input-select label {
  display: block !important;
  cursor: pointer;
}
.input-checkbox {
  padding: 0;
}
.input-checkbox label {
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 6px;
  background: #000;
}
.input-radio {
  padding: 0;
}
.input-radio label {
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 50%;
  background: #000;
}
.input-select {
  position: relative;
}
.input-select i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 1em;
  font-size: .87em;
}
.input-file {
  position: relative;
  display: inline-block;
}
.input-file input {
  display: none;
}
.form-error {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #D84D4D;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.form-success {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #1DC020;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.attempted-submit .field-error {
  border-color: #D84D4D !important;
}
.attempted-submit div.recaptcha.field-error {
  border-color: #D84D4D !important;
  border-style: solid;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}
div.recaptcha iframe {
  min-height: 0;
}
/**! 13. Accordions **/
.accordion li .accordion__title,
.accordion li .accordion__content,
.accordion li .accordion__content * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.accordion li .accordion__title {
  cursor: pointer;
  padding: 0.46428571em 0.92857143em;
  border: 1px solid #ececec;
  border-bottom: none;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion li:last-child .accordion__title {
  border-bottom: 1px solid #ececec;
}
.accordion li .accordion__content {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.accordion li .accordion__content > * {
  display: none;
}
.accordion li .accordion__content > *:first-child {
  padding-top: 0;
}
.accordion li .accordion__content > *:last-child {
  padding-bottom: 0;
}
.accordion li.active .accordion__title {
  background: #4a90e2;
  border-bottom: 1px solid #ececec;
}
.accordion li.active .accordion__content {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
}
.accordion li.active .accordion__content > * {
  display: inline-block;
}
/**! 14. Breadcrumbs **/
.breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
  display: inline-block;
}
.breadcrumb li {
  font-size: 1em;
}
.breadcrumb li + li:before {
  padding: 0 0.46428571em;
}
/**! 15. Pie Charts **/
.radial {
  position: relative;
}
.radial .radial__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  margin-bottom: 0;
}
/**! 16. Tabs **/
.tabs {
  display: block;
  margin-bottom: 0;
}
.tabs > li {
  display: inline-block;
  opacity: .5;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tabs > .active,
.tabs:hover {
  opacity: 1;
}
.tabs .tab__title {
  cursor: pointer;
}
.tabs .tab__title:not(.btn) {
  padding: 0 1.85714286em;
}
.tabs li:first-child .tab__title:not(.btn) {
  padding-left: 0;
}
.tabs .tab__content {
  display: none;
}
.tabs-content {
  margin-top: 1em;
}
.tabs-content li > .tab__content {
  width: 100%;
  display: none;
}
.tabs-content > .active > .tab__content {
  display: block;
}
.tabs-container[data-content-align="left"] .tabs-content {
  text-align: left;
}
@media all and (max-width: 767px) {
  .tabs-content > li:not(.active) .tab__content {
    display: none !important;
  }
}
/**! 17. Boxes **/
.boxed {
  position: relative;
  overflow: hidden;
  padding: 1.85714286em;
  margin-bottom: 30px;
}
.boxed.boxed--lg {
  padding: 2.78571429em;
}
.boxed.boxed--sm {
  padding: 1.23809524em;
}
.boxed.boxed--border {
  border: 1px solid #ececec;
}
.boxed > div[class*='col-']:first-child:not(.boxed) {
  padding-left: 0;
}
.boxed > div[class*='col-']:last-child:not(.boxed) {
  padding-right: 0;
}
img + .boxed {
  margin-top: -1.85714286em;
}
@media all and (max-width: 767px) {
  .boxed {
    padding: 1.23809524em;
    margin-bottom: 15px;
  }
  .boxed.boxed--lg {
    padding: 1.23809524em;
  }
  .boxed div[class*='col-']:not(.boxed) {
    padding: 0;
  }
  .boxed:last-child {
    margin-bottom: 15px;
  }
}
/**! 18. Sliders Flickity **/
.slides:not(.flickity-enabled) li.imagebg:not(:first-child) {
  display: none;
}
.slides:not(.flickity-enabled) li.imagebg:first-child {
  background: #252525;
  animation: backgroundLoad .5s ease alternate infinite;
}
.slides:not(.flickity-enabled) li.imagebg:first-child .container {
  opacity: 0;
}
@keyframes backgroundLoad {
  0% {
    background: #252525;
  }
  100% {
    background: #3f3f3f;
  }
}
.slider.height-10 {
  height: auto;
}
.slider.height-10 .slides .flickity-slider > li {
  height: 10vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-10 .slides li.imagebg {
    min-height: 10vh;
  }
}
.slider.height-20 {
  height: auto;
}
.slider.height-20 .slides .flickity-slider > li {
  height: 20vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-20 .slides li.imagebg {
    min-height: 20vh;
  }
}
.slider.height-30 {
  height: auto;
}
.slider.height-30 .slides .flickity-slider > li {
  height: 30vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-30 .slides li.imagebg {
    min-height: 30vh;
  }
}
.slider.height-40 {
  height: auto;
}
.slider.height-40 .slides .flickity-slider > li {
  height: 40vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-40 .slides li.imagebg {
    min-height: 40vh;
  }
}
.slider.height-50 {
  height: auto;
}
.slider.height-50 .slides .flickity-slider > li {
  height: 50vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-50 .slides li.imagebg {
    min-height: 50vh;
  }
}
.slider.height-60 {
  height: auto;
}
.slider.height-60 .slides .flickity-slider > li {
  height: 60vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-60 .slides li.imagebg {
    min-height: 60vh;
  }
}
.slider.height-70 {
  height: auto;
}
.slider.height-70 .slides .flickity-slider > li {
  height: 70vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-70 .slides li.imagebg {
    min-height: 70vh;
  }
}
.slider.height-80 {
  height: auto;
}
.slider.height-80 .slides .flickity-slider > li {
  height: 80vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-80 .slides li.imagebg {
    min-height: 80vh;
  }
}
.slider.height-90 {
  height: auto;
}
.slider.height-90 .slides .flickity-slider > li {
  height: 90vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-90 .slides li.imagebg {
    min-height: 90vh;
  }
}
.slider.height-100 {
  height: auto;
}
.slider.height-100 .slides .flickity-slider > li {
  height: 100vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-100 .slides li.imagebg {
    min-height: 100vh;
  }
}
.slider .slides .flickity-slider > li:not([class*='col-']) {
  width: 100%;
}
.slider .slides.slides--gapless li[class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}
.slider[data-arrows="true"].slider--arrows-hover:not(:hover) .flickity-prev-next-button {
  opacity: 0;
}
.slider[data-paging="true"]:not(section) {
  margin-bottom: 3.71428571em;
}
.slider[data-paging="true"]:not(section) .flickity-page-dots {
  bottom: -3.71428571em;
}
.slider[data-paging="true"]:not([class*='text-']) .flickity-page-dots {
  text-align: center;
}
.slider[data-children="1"] .flickity-prev-next-button {
  display: none;
}
.slider:not([data-paging="true"]) .slides {
  margin: 0;
}
.slider.controls--dark .flickity-page-dots .dot {
  background: #252525;
}
.slider.controls--dark .flickity-prev-next-button:before {
  color: #252525;
}
section.slider {
  padding: 0;
}
section.slider.height-10 {
  height: auto;
}
section.slider.height-10 .slides .flickity-slider > li {
  height: 10vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-10 .slides li.imagebg {
    min-height: 10vh;
  }
}
section.slider.height-20 {
  height: auto;
}
section.slider.height-20 .slides .flickity-slider > li {
  height: 20vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-20 .slides li.imagebg {
    min-height: 20vh;
  }
}
section.slider.height-30 {
  height: auto;
}
section.slider.height-30 .slides .flickity-slider > li {
  height: 30vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-30 .slides li.imagebg {
    min-height: 30vh;
  }
}
section.slider.height-40 {
  height: auto;
}
section.slider.height-40 .slides .flickity-slider > li {
  height: 40vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-40 .slides li.imagebg {
    min-height: 40vh;
  }
}
section.slider.height-50 {
  height: auto;
}
section.slider.height-50 .slides .flickity-slider > li {
  height: 50vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-50 .slides li.imagebg {
    min-height: 50vh;
  }
}
section.slider.height-60 {
  height: auto;
}
section.slider.height-60 .slides .flickity-slider > li {
  height: 60vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-60 .slides li.imagebg {
    min-height: 60vh;
  }
}
section.slider.height-70 {
  height: auto;
}
section.slider.height-70 .slides .flickity-slider > li {
  height: 70vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-70 .slides li.imagebg {
    min-height: 70vh;
  }
}
section.slider.height-80 {
  height: auto;
}
section.slider.height-80 .slides .flickity-slider > li {
  height: 80vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-80 .slides li.imagebg {
    min-height: 80vh;
  }
}
section.slider.height-90 {
  height: auto;
}
section.slider.height-90 .slides .flickity-slider > li {
  height: 90vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-90 .slides li.imagebg {
    min-height: 90vh;
  }
}
section.slider.height-100 {
  height: auto;
}
section.slider.height-100 .slides .flickity-slider > li {
  height: 100vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-100 .slides li.imagebg {
    min-height: 100vh;
  }
}
section.slider[data-paging="true"] .flickity-page-dots {
  bottom: 1.85714286em;
}
section.slider:not(.image--light)[data-paging="true"] .flickity-page-dots .dot {
  background: #fff;
}
section.slider .slides {
  margin: 0;
}
@media all and (max-width: 767px) {
  section.slider[class*='height-'] .slides .flickity-slider > li {
    height: auto;
    padding: 7.42857143em 0;
  }
  section.slider.space--lg .slides .flickity-slider > li {
    padding: 11.14285714em 0;
  }
  section.slider.space--xlg .slides .flickity-slider > li {
    padding: 11.14285714em 0;
  }
}
section.bg--dark .slider[data-paging="true"] .flickity-page-dots .dot,
section.bg--primary .slider[data-paging="true"] .flickity-page-dots .dot {
  background: #fff;
}
.flickity-page-dots .dot {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #252525;
  border: none;
  margin: 0 0.46428571em;
}
.flickity-page-dots .dot:hover:not(.is-selected) {
  opacity: .6;
}
.text-center .flickity-page-dots,
section.slider .flickity-page-dots {
  text-align: center;
}
.flickity-prev-next-button svg {
  display: none;
}
.flickity-prev-next-button:before {
  font-family: 'stack-interface';
  content: "\e80c";
  font-size: 1em;
  font-weight: normal;
}
.flickity-prev-next-button.previous:before {
  content: "\e80b";
}
.imagebg:not(.image--light) .flickity-page-dots .dot,
.bg--dark .flickity-page-dots .dot {
  background: #fff;
}
/**! 19. Hover Elements **/
.hover-element {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.hover-element * {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.hover-element .hover-element__reveal {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.hover-element .hover-element__reveal .boxed {
  height: 100%;
}
.hover-element:hover .hover-element__reveal,
.hover-element.hover--active .hover-element__reveal {
  opacity: 1;
}
.hover-element img {
  margin-bottom: 0;
}
@media all and (max-width: 1024px) {
  .hover-element {
    cursor: pointer;
  }
}
.row:last-child div[class*='col-']:last-child .hover-element {
  margin-bottom: 0;
}
/**! 20. Masonry **/
.masonry .masonry__container.masonry--active .masonry__item {
  opacity: 1;
  pointer-events: all;
}
.masonry .masonry__container .masonry__item {
  opacity: 0;
  pointer-events: none;
}
.masonry .masonry__filters li {
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.masonry .masonry__filters li.active {
  cursor: default;
}
.masonry.masonry--gapless .masonry__item {
  padding: 0 !important;
  margin-bottom: 0;
}
/**! 21. Modals **/
.modal-instance .modal-body {
  display: none;
}
.modal-container {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal-container.modal-active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.modal-container:before {
  background: rgba(0, 0, 0, 0.85);
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.modal-container .modal-content {
  backface-visibility: hidden;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  max-height: 100%;
  overflow-y: scroll;
  border: none;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-container .modal-content:not(.height--natural) {
  width: 50%;
  height: 50%;
}
.modal-container .modal-content .modal-close-cross {
  cursor: pointer;
  position: absolute;
  opacity: .5;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  top: 1em;
  right: 1em;
  z-index: 99;
}
.modal-container .modal-content .modal-close-cross:before {
  content: '\00D7';
  font-size: 1.5em;
}
.modal-container .modal-content .modal-close-cross:hover {
  opacity: 1;
}
.modal-container .modal-content.imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content iframe {
  width: 100%;
  outline: none;
  border: none;
  height: 100%;
  backface-visibility: hidden;
}
.modal-container .modal-content iframe:first-child + .modal-close-cross:last-child {
  top: -3.71428571em;
}
.modal-content.section-modal {
  pointer-events: none;
}
.modal-content.section-modal [class*='col-'] {
  pointer-events: all;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content {
    width: 97% !important;
    height: auto !important;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
/**! 22. Maps **/
.map-container {
  position: relative;
  overflow: hidden;
}
.map-container iframe,
.map-container .map-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
/**! 23. Parallax **/
.parallax > .background-image-holder,
.parallax .slides li > .background-image-holder {
  height: 100%;
  min-height: 100vh;
  top: -50vh;
  transition: opacity 0.3s ease !important;
  -webkit-transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}
.parallax:first-child .slides li > .background-image-holder,
.parallax:first-child .background-image-holder {
  top: 0;
}
.main-container > a:first-child + .parallax .background-image-holder {
  top: 0;
}
@media all and (max-width: 1024px) {
  .parallax > .background-image-holder,
  .parallax .slides li > .background-image-holder {
    -webkit-transition: transform 0.016s linear !important;
    transition: transform 0.016s linear !important;
  }
  .parallax.parallax-disable-mobile .background-image-holder,
  .parallax.parallax-disable-mobile .slides li > .background-image-holder {
    top: 0 !important;
    transform: none !important;
  }
}
/**! 24. Notifications **/
.notification {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 99;
  pointer-events: none;
  padding: 0;
  margin: 1em;
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.notification:not([class*='bg-']) {
  background: #fff;
}
.notification[class*='col-'] {
  min-width: 400px;
}
.notification .notification-close-cross {
  position: absolute;
  top: 1em;
  z-index: 99;
  right: 1em;
  cursor: pointer;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  opacity: .7;
}
.notification .notification-close-cross:before {
  content: '\00D7';
  font-size: 1.5em;
}
.notification .notification-close-cross:hover {
  opacity: 1;
}
.notification.notification--reveal {
  z-index: 99;
  pointer-events: initial;
}
.notification.notification--reveal[data-animation="from-bottom"] {
  animation: from-bottom 0.3s linear 0s forwards;
  -webkit-animation: from-bottom 0.3s linear 0s forwards;
  -moz-animation: from-bottom 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-top"] {
  animation: from-top 0.3s linear 0s forwards;
  -webkit-animation: from-top 0.3s linear 0s forwards;
  -moz-animation: from-top 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-left"] {
  animation: from-left 0.3s linear 0s forwards;
  -webkit-animation: from-left 0.3s linear 0s forwards;
  -moz-animation: from-left 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-right"] {
  animation: from-right 0.3s linear 0s forwards;
  -webkit-animation: from-right 0.3s linear 0s forwards;
  -moz-animation: from-right 0.3s linear 0s forwards;
}
.notification.notification--dismissed {
  animation: fade-out 0.4s linear 0s forwards !important;
  -webkit-animation: fade-out 0.4s linear 0s forwards !important;
  -moz-animation: fade-out 0.4s linear 0s forwards !important;
  pointer-events: none;
}
.bg--dark + .notification-close-cross:before {
  color: #fff;
}
a[data-notification-link] {
  text-decoration: none;
}
a[data-notification-link]:hover {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .notification[class*='col-'] {
    min-width: 0;
  }
}
@keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**! 25. Video **/
iframe {
  width: 100%;
  min-height: 350px;
  border: none;
}
@media all and (max-width: 767px) {
  iframe {
    min-height: 220px;
  }
}
.videobg {
  background: #252525;
  position: relative;
  overflow: hidden;
}
.videobg .container,
.videobg .background-image-holder {
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.videobg .background-image-holder {
  opacity: 0 !important;
}
.videobg.video-active .container {
  opacity: 1;
}
.videobg.video-active .loading-indicator {
  opacity: 0;
  visibility: hidden;
}
.videobg video {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  z-index: 0 !important;
  left: 0;
}
@media all and (max-width: 1024px) {
  .videobg .background-image-holder,
  .videobg .container {
    opacity: 1 !important;
  }
  .videobg .loading-indicator {
    display: none;
  }
  .videobg video {
    display: none;
  }
}
.youtube-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0 !important;
}
.youtube-background .mb_YTPBar {
  opacity: 0;
  height: 0;
  visibility: hidden;
}
@media all and (max-width: 1024px) {
  .youtube-background {
    display: none;
  }
}
.loading-indicator {
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 99 !important;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: loading-spinner 1s infinite ease-in-out;
  animation: loading-spinner 1s infinite ease-in-out;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.video-cover {
  position: relative;
}
.video-cover video {
  max-width: 100%;
}
.video-cover iframe {
  background: #252525;
}
.video-cover .background-image-holder {
  z-index: 3;
}
.video-cover .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
.video-cover .video-play-icon,
.video-cover .background-image-holder {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.video-cover.reveal-video .video-play-icon,
.video-cover.reveal-video .background-image-holder {
  opacity: 0 !important;
  pointer-events: none;
}
.video-cover[data-scrim-bottom]:before,
.video-cover[data-overlay]:before,
.video-cover[data-scrim-top]:before {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  z-index: 4;
}
.video-cover.reveal-video[data-scrim-bottom]:before,
.video-cover.reveal-video[data-overlay]:before,
.video-cover.reveal-video[data-scrim-top]:before {
  opacity: 0;
  pointer-events: none;
}
.video-play-icon {
  width: 7.42857143em;
  height: 7.42857143em;
  border-radius: 50%;
  position: relative;
  z-index: 4;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  background: #ffffff;
}
.video-play-icon.video-play-icon--sm {
  width: 3.71428571em;
  height: 3.71428571em;
}
.video-play-icon.video-play-icon--sm:before {
  border-width: 4px 0 4px 9px;
}
.video-play-icon.video-play-icon--xs {
  width: 1.85714286em;
  height: 1.85714286em;
}
.video-play-icon.video-play-icon--xs:before {
  border-width: 3px 0 3px 6px;
  margin-left: -3px;
}
.video-play-icon.bg--primary:before {
  border-color: transparent transparent transparent #fff;
}
.video-play-icon:before {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -4px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent #ffffff;
  border-color: transparent transparent transparent #808080;
}
.video-play-icon.video-play-icon--dark {
  border-color: #252525;
  background: #252525;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #252525;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #fff;
}
@media all and (max-width: 767px) {
  .video-play-icon {
    width: 4.95238095em;
    height: 4.95238095em;
  }
}
.col-12:not([class*='col-lg']):not([class*='col-md']) .video-cover iframe {
  min-height: 550px;
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='-12']:not([class*='xs-12']) .video-cover iframe {
    min-height: 350px;
  }
}
div[class*='col-'][class*='-10'] .video-cover iframe {
  min-height: 450px;
}
div[class*='col-'][class*='-8'] .video-cover iframe {
  min-height: 400px;
}
div[class*='col-'][class*='-6'] .video-cover iframe {
  min-height: 350px;
}
@media all and (max-width: 1200px) {
  div[class*='col-'][class*='-6'] .video-cover iframe {
    min-height: 300px;
  }
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='-6'] .video-cover iframe {
    min-height: 220px;
  }
}
@media all and (max-width: 767px) {
  div[class*='col-'] .video-cover iframe {
    min-height: 220px !important;
  }
}
.modal-container video {
  max-width: 100%;
}
/**! 26. Colors **/
body {
  background: #ffffff;
}
.color--primary {
  color: #4a90e2 !important;
}
a {
  color: #4a90e2;
}
.color--primary-1 {
  color: #31639c !important;
}
.color--primary-2 {
  color: #465773 !important;
}
.color--white {
  color: #fff;
}
.color--dark {
  color: #252525;
}
.color--success {
  color: #4ebf56;
}
.color--error {
  color: #e23636;
}
.bg--dark {
  background: #252525;
}
.bg--dark:not(.nav-bar):not(.bar) {
  color: #ffffff;
}
.bg--dark:not(.nav-bar):not(.bar) h1,
.bg--dark:not(.nav-bar):not(.bar) h2,
.bg--dark:not(.nav-bar):not(.bar) h3,
.bg--dark:not(.nav-bar):not(.bar) h4,
.bg--dark:not(.nav-bar):not(.bar) h5,
.bg--dark:not(.nav-bar):not(.bar) h6,
.bg--dark:not(.nav-bar):not(.bar) i,
.bg--dark:not(.nav-bar):not(.bar) span:not(.btn__text),
.bg--dark:not(.nav-bar):not(.bar) p {
  color: #ffffff;
}
.bg--dark:not(.nav-bar):not(.bar) a:not(.btn) {
  color: #fff;
}
.bg--site {
  background: #ffffff;
}
.bg--secondary {
  background: #fafafa;
}
.bg--primary {
  background: #4a90e2;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn) {
  color: #fff;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.bg--primary .color--primary {
  color: #fff !important;
}
.bg--white {
  background: #fff;
}
.bg--white p,
.bg--white span,
.bg--white ul,
.bg--white a:not(.btn) {
  color: #666666;
}
.bg--white h1,
.bg--white h2,
.bg--white h3,
.bg--white h4,
.bg--white h5,
.bg--white h6,
.bg--white i {
  color: #252525;
}
.bg--error {
  background: #e23636;
}
.bg--success {
  background: #4ebf56;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white span,
.imagebg:not(.image--light) .bg--white ul,
.imagebg:not(.image--light) .bg--white a:not(.btn) {
  color: #666666;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6,
.imagebg:not(.image--light) .bg--white i {
  color: #252525;
}
.imagebg:not(.image--light) .bg--secondary {
  background: rgba(250, 250, 250, 0.2);
}
.bg--primary-1 {
  background: #31639c;
}
.bg--primary-1 p,
.bg--primary-1 span,
.bg--primary-1 ul,
.bg--primary-1 a:not(.btn) {
  color: #fff;
}
.bg--primary-1 h1,
.bg--primary-1 h2,
.bg--primary-1 h3,
.bg--primary-1 h4,
.bg--primary-1 h5,
.bg--primary-1 h6,
.bg--primary-1 i {
  color: #fff;
}
.bg--primary-2 {
  background: #465773;
}
.bg--primary-2 p,
.bg--primary-2 span,
.bg--primary-2 ul,
.bg--primary-2 a:not(.btn) {
  color: #fff;
}
.bg--primary-2 h1,
.bg--primary-2 h2,
.bg--primary-2 h3,
.bg--primary-2 h4,
.bg--primary-2 h5,
.bg--primary-2 h6,
.bg--primary-2 i {
  color: #fff;
}
.image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.color--facebook {
  color: #3b5998;
}
.color--twitter {
  color: #00aced;
}
.color--googleplus {
  color: #dd4b39;
}
.color--instagram {
  color: #125688;
}
.color--pinterest {
  color: #cb2027;
}
.color--dribbble {
  color: #ea4c89;
}
.color--behance {
  color: #053eff;
}
.bg--facebook {
  background: #3b5998;
  color: #fff;
}
.bg--twitter {
  background: #00aced;
  color: #fff;
}
.bg--googleplus {
  background: #dd4b39;
  color: #fff;
}
.bg--instagram {
  background: #125688;
  color: #fff;
}
.bg--pinterest {
  background: #cb2027;
  color: #fff;
}
.bg--dribbble {
  background: #ea4c89;
  color: #fff;
}
.bg--behance {
  background: #053eff;
  color: #fff;
}
/**! 27. Image Blocks **/
.imageblock {
  position: relative;
  padding: 0;
}
.imageblock > .container,
.imageblock > div[class*='col-']:not(.imageblock__content) {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
  float: none;
  overflow: hidden;
}
.imageblock.imageblock--lg > .container,
.imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
  padding-top: 9.28571429em;
  padding-bottom: 9.28571429em;
  float: none;
  overflow: hidden;
}
.imageblock .imageblock__content {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 0;
}
.imageblock .imageblock__content .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.imageblock .imageblock__content .slider .slides > li {
  padding: 0;
  min-height: 100%;
  position: absolute !important;
}
.imageblock.allow-overflow .imageblock__content {
  overflow: visible;
}
@media all and (max-height: 728px) {
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 3.71428571em;
    padding-bottom: 3.71428571em;
  }
}
@media all and (max-width: 767px) {
  .imageblock[class*='space-'] {
    padding-bottom: 0;
    padding-top: 0;
  }
  .imageblock .imageblock__content {
    position: relative;
    min-height: 18.57142857em;
  }
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
    float: none;
    overflow: hidden;
  }
  .imageblock.imageblock--lg > .container,
  .imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
    float: none;
    overflow: hidden;
  }
}
/**! 28. MailChimp & Campaign Monitor **/
form[action*='createsend.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='createsend.com'].form--active * {
  opacity: 1;
}
form[action*='createsend.com'] .input-checkbox + br {
  display: none;
}
form[action*='createsend.com'].no-labels label {
  display: none;
}
form[action*='createsend.com'] br {
  display: none;
}
form[action*='createsend.com'] p > label:first-child {
  margin-bottom: 0.92857143em;
}
form[action*='list-manage.com'] h2 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  font-weight: 300;
  font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
  margin-bottom: 1.36842105263158em;
  font-weight: 400;
}
form[action*='list-manage.com'] h2.inline-block + .h4.inline-block:not(.typed-text) {
  margin-left: 0.68421052631579em;
}
form[action*='list-manage.com'] .input-group ul {
  overflow: hidden;
}
form[action*='list-manage.com'] .input-group ul li {
  float: left;
}
form[action*='list-manage.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='list-manage.com'].form--active * {
  opacity: 1;
}
form[action*='list-manage.com'].no-labels label {
  display: none;
}
form[action*='list-manage.com'] .small-meta {
  font-size: 0.5em;
}
/**! 29. Twitter **/
.twitter-feed .user {
  display: none;
}
.twitter-feed .interact {
  display: none;
}
.twitter-feed .timePosted {
  font-size: .87em;
}
/**! 30. Transitions **/
[class*='transition--'] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
}
[class*='transition--'].transition--active {
  opacity: 1;
}
.transition--scale {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
.transition--scale.transition--active {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}
.transition--slide {
  transform: translate3d(200px, 0, 0);
  -webkit-transform: translate3d(200px, 0, 0);
  transform: translate3d(30vw, 0, 0);
  -webkit-transform: translate3d(30vw, 0, 0);
}
.transition--slide.transition--active {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
/**! 31. Switchable Sections **/
.switchable {
  position: relative;
}
.switchable div[class*='col-']:first-child {
  float: left;
  right: auto;
}
.switchable div[class*='col-']:first-child:not([class*='pull']):not([class*='push']) {
  left: 0;
}
.switchable div[class*='col-']:last-child {
  float: right;
  left: auto;
}
.switchable div[class*='col-']:last-child:not([class*='pull']):not([class*='push']) {
  right: 0;
}
.switchable.switchable--switch div[class*='col-']:first-child {
  float: right;
  right: 0;
  left: auto;
}
.switchable.switchable--switch div[class*='col-']:first-child:not([class*='pull']):not([class*='push']) {
  left: auto;
}
.switchable.switchable--switch div[class*='col-']:last-child {
  float: left;
  left: 0;
  right: auto;
}
.switchable .switchable__text {
  margin-top: 3.71428571em;
}
.switchable > div[class*='col-'] {
  padding: 0;
}
/**! 32. Typed Effect **/
.typed-text {
  display: inline-block;
}
.typed-text.typed-text--cursor:after {
  content: '|';
  font-size: 1.2em;
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
  position: relative;
  right: 6px;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/**! 33. Gradient BG **/
[data-gradient-bg] {
  position: relative;
  background: #252525;
}
[data-gradient-bg] > canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
[data-gradient-bg] > canvas + .background-image-holder {
  opacity: .2 !important;
}
/**! 34. Bars **/
.bar {
  padding: 1.85714286em 0;
}
.bar .logo {
  margin: 0;
  position: relative;
  top: 4px;
}
.bar .menu-horizontal {
  position: relative;
  top: 6px;
}
.bar:not([class*='visible-']) + .bar {
  margin-top: 0.92857143em;
}
.bar.bar--xs {
  padding: 0.46428571em 0;
}
.bar.bar--sm {
  padding: 0.92857143em 0;
}
.bar.bar--lg {
  padding: 2.78571429em 0;
}
.bar.bar--lg .logo {
  top: 0;
}
.bar.bar--xlg {
  padding: 4.64285714em 0;
}
.bar.bar--xlg .logo {
  top: 0;
}
.bar.bg--dark .logo-dark {
  display: none;
}
.bar:not(.bg--dark):not(.bar--transparent) .logo-light {
  display: none;
}
@media all and (max-width: 767px) {
  .bar.bar--mobile-sticky[data-scroll-class*='fixed'].pos-fixed {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
  }
  .bar.bar--mobile-sticky[data-scroll-class*='fixed'] + .bar.pos-fixed {
    top: 3.71428571em;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
  }
}
@media all and (min-width: 768px) {
  .bar__module:not(:only-child) {
    display: inline-block;
  }
  .bar__module:not(:last-child) {
    margin-right: 0.92857143em;
  }
  .bar--transparent:not(.bar--dark) {
    background: none;
  }
  .bar--transparent:not(.bar--dark) .logo-dark {
    display: none;
  }
  .bar--transparent:not(.bar--dark) .logo-light {
    display: inline-block;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .menu-horizontal > li > a,
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .menu-horizontal > li > span {
    color: #fff;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']) {
    border-color: rgba(255, 255, 255, 0.3);
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']) .btn__text {
    color: #fff;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']):hover {
    border-color: rgba(255, 255, 255, 0.7);
  }
  .bar--absolute {
    position: absolute;
  }
  .bar--absolute,
  .pos-fixed {
    z-index: 99;
    width: 100%;
  }
  .bar.pos-fixed {
    position: fixed;
    top: 0;
    animation: fadeInDown 0.3s ease-out forwards;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  }
  .bar.pos-fixed:not([class*='bg-']) {
    background: #ffffff;
  }
  .bar.pos-fixed:not([class*='bg-']) .logo-dark {
    display: inline-block;
  }
  .bar.pos-fixed:not([class*='bg-']) .logo-light {
    display: none;
  }
  .bar.pos-fixed.bg--dark {
    background: #252525;
  }
}
@media all and (max-width: 767px) {
  .bar__module {
    margin-bottom: 0.92857143em;
  }
  .bar__module + .bar__module {
    margin-top: 1.85714286em;
  }
  .bar__module .btn {
    display: block;
  }
  .bar__module .btn + .btn {
    margin-left: 0 !important;
  }
  .bar__module .btn:not(:last-child) {
    margin-bottom: 0.92857143em;
  }
  .bar + nav.bar {
    padding-top: 0;
  }
}
/**! 35. Navigation InPage **/
.page-navigator {
  position: fixed;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 1.85714286em;
  z-index: 10;
}
.page-navigator ul {
  display: inline-block;
  padding: 0.92857143em;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1.85714286em;
  transition: all .2s ease;
}
.page-navigator ul:hover {
  background: rgba(0, 0, 0, 0.6);
}
.page-navigator ul li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
@media all and (max-width: 767px) {
  .page-navigator {
    right: 0;
  }
  .page-navigator ul {
    border-radius: 1.85714286em 0 0 1.85714286em;
    padding: 1.85714286em 0.92857143em;
  }
}
.page-navigator li a {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  transition: all .2s ease;
  display: block;
  position: relative;
}
.page-navigator li a:not(:hover) {
  opacity: .5;
}
.page-navigator li a.inner-link--active {
  opacity: 1;
  animation: bulge .5s ease;
  -webkit-animation: bulge .5s ease;
}
@keyframes bulge {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes bulge {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.page-navigator li a[data-title]:before {
  content: attr(data-title);
  position: absolute;
  right: 12px;
  top: -14px;
  background: #222;
  color: #fff;
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-block;
  transition: all .2s ease;
  white-space: nowrap;
}
.page-navigator li a[data-title]:not(:hover):before {
  opacity: 0;
  transform: translateX(-20px);
}
/**! 36. Helper Classes **/
.clearfix {
  overflow: hidden;
}
.clearfix-after:after {
  content: "";
  display: table;
  clear: both;
}
.allow-overflow {
  overflow: visible;
}
.container .row--gapless {
  padding-left: 15px;
  padding-right: 15px;
}
.container .row--gapless > div[class*='col-'] {
  padding: 0;
}
@media all and (max-width: 767px) {
  .text-left-xs {
    text-align: left;
  }
}
@media all and (max-width: 991px) {
  .text-left-sm {
    text-align: left;
  }
}
section > .row--gapless {
  padding-left: 0;
  padding-right: 0;
}
section > .row--gapless > div[class*='col-'] {
  padding: 0;
}
div.right {
  float: right;
}
div.left {
  float: left;
}
section.text-right > .container:last-child > .row:only-child > div[class*='col-']:only-child {
  float: right;
}
/**! 37. Spacing **/
section,
footer {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
}
section.space--xxs,
footer.space--xxs {
  padding-top: 1.85714286em;
  padding-bottom: 1.85714286em;
}
section.space--xs,
footer.space--xs {
  padding-top: 3.71428571em;
  padding-bottom: 3.71428571em;
}
section.space--sm,
footer.space--sm {
  padding-top: 4.95238095em;
  padding-bottom: 4.95238095em;
}
section.space--md,
footer.space--md {
  padding-top: 11.14285714em;
  padding-bottom: 11.14285714em;
}
section.space--lg,
footer.space--lg {
  padding-top: 14.85714286em;
  padding-bottom: 14.85714286em;
}
section.space--xlg,
footer.space--xlg {
  padding-top: 29.71428571em;
  padding-bottom: 29.71428571em;
}
section.space--0,
footer.space--0 {
  padding: 0;
}
section.section--even,
footer.section--even {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
}
section.space-bottom--sm,
footer.space-bottom--sm {
  padding-bottom: 4.95238095em;
}
@media all and (max-width: 767px) {
  section,
  footer,
  section.section--even {
    padding: 5.57142857em 0;
  }
  section.space--lg,
  footer.space--lg,
  section.section--even.space--lg,
  section.space--md,
  footer.space--md,
  section.section--even.space--md {
    padding: 5.57142857em 0;
  }
  section.space--xlg,
  footer.space--xlg,
  section.section--even.space--xlg {
    padding: 8.35714286em 0;
  }
}
div[class*='col-'] > div[class*='col-']:first-child {
  padding-left: 0;
}
div[class*='col-'] > div[class*='col-']:last-child {
  padding-right: 0;
}
@media all and (max-width: 767px) {
  .col-xs-6:nth-child(odd) {
    padding-right: 7.5px;
  }
  .col-xs-6:nth-child(even) {
    padding-left: 7.5px;
  }
}
@media all and (min-width: 768px) {
  .mt--1 {
    margin-top: 1.85714286em;
  }
  .mt--2 {
    margin-top: 3.71428571em;
  }
  .mt--3 {
    margin-top: 5.57142857em;
  }
  .mb--1 {
    margin-bottom: 1.85714286em;
  }
  .mb--2 {
    margin-bottom: 3.71428571em;
  }
  .mb--3 {
    margin-bottom: 5.57142857em;
  }
}
@media all and (max-width: 990px) {
  .mt--1,
  .mt--2 {
    margin-top: 1.85714286em;
  }
  .mt--3 {
    margin-top: 2.78571429em;
  }
}
.unpad {
  padding: 0;
}
.unpad--bottom {
  padding-bottom: 0;
}
.unpad--top {
  padding-top: 0;
}
section.unpad--bottom {
  padding-bottom: 0;
}
section.unpad {
  padding: 0;
}
section.unpad--top {
  padding-top: 0;
}
.unmarg--bottom {
  margin-bottom: 0;
}
.unmarg {
  margin: 0;
}
.unmarg--top {
  margin-top: 0;
}
/**! 38. Boxed Layout **/
@media all and (min-width: 1280px) {
  body.boxed-layout {
    padding: 3.71428571em 0;
    background: #ededed;
  }
  body.boxed-layout section:not([class*='bg-']):not(.imagebg),
  body.boxed-layout footer:not([class*='bg-']):not(.imagebg),
  body.boxed-layout nav:not([class*='bg-']):not(.bar--transparent):not(.bar--absolute),
  body.boxed-layout .tabs-container:not([class*='bg-']):not(.imagebg) {
    background: #ffffff;
  }
  body.boxed-layout .nav-container,
  body.boxed-layout .main-container,
  body.boxed-layout > section,
  body.boxed-layout nav {
    max-width: 1280px;
    margin: 0 auto;
  }
}
/**! 39. Wizard **/
.wizard__body {
  list-style: none;
}
.wizard__step:not(.active) {
  display: none;
}
.wizard__step .wizard__title {
  display: none;
}
.wizard__controls {
  overflow: hidden;
}
.wizard__controls .wizard-prev {
  float: left;
}
.wizard__controls .wizard-next {
  float: right;
}
.wizard__controls .wizard-prev:hover,
.wizard__controls .wizard-next:hover {
  transform: none !important;
}
.wizard__controls .wizard-prev.inactive,
.wizard__controls .wizard-next.inactive {
  pointer-events: none;
  opacity: .3;
  cursor: not-allowed;
}
/**! 40. Alerts **/
.alert {
  overflow: hidden;
  border: 1px solid #e6e6e6;
  padding: 0.92857143em;
}
.alert .alert__body,
.alert .alert__close {
  display: inline-block;
  user-select: none;
}
.alert .alert__body {
  float: left;
}
.alert .alert__close {
  float: right;
  cursor: pointer;
}
.alert.bg--error {
  background: #fce8e8;
  border-color: #e23636;
}
.alert.bg--error .alert__close {
  color: #e23636;
}
.alert.bg--success {
  background: #e4f5e5;
  border-color: #4ebf56;
}
.alert.bg--success .alert__close {
  color: #4ebf56;
}
.alert.bg--primary {
  background: #fafcfe;
  border-color: #4a90e2;
}
.alert.bg--primary .alert__body > span {
  color: #666666;
}
.alert.bg--primary .alert__close {
  color: #4a90e2;
}
.alert.alert--dismissed {
  display: none;
}
/**! 41. Progress - Horizontal **/
.progress-horizontal:after {
  content: "";
  display: table;
  clear: both;
}
.progress-horizontal .progress-horizontal__bar {
  position: relative;
  overflow: hidden;
}
.progress-horizontal .progress-horizontal__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
/**! 42. Theme Overrides **/
/*! -- Stack Customizers -- */
.box-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.box-shadow-shallow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}
.box-shadow-wide {
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.border--round {
  border-radius: 6px;
}
.border--round:before {
  border-radius: 6px;
}
.border--round .background-image-holder {
  border-radius: 6px;
}
.border--round [data-scrim-top]:before,
.border--round [data-scrim-bottom]:before,
.border--round [data-overlay]:before {
  border-radius: 6px;
}
.imageblock.border--round .background-image-holder {
  border-radius: 6px 0 0 6px;
}
@media all and (max-width: 767px) {
  .imageblock.border--round .background-image-holder {
    border-radius: 6px 6px 0 0;
  }
}
.theme--square .border--round,
.theme--square .btn {
  border-radius: 0px;
}
.theme--bordered {
  border: 0.92857143em solid #252525;
}
.main-container.transition--fade:not(.transition--active) {
  cursor: wait;
}
@media all and (min-width: 1280px) {
  body.boxed-layout > section.bar-3:first-of-type {
    border-radius: 6px 6px 0 0;
  }
  body.boxed-layout .main-container > footer:last-child {
    border-radius: 0 0 6px 6px;
  }
}
body.boxed-layout .modal-container section:not([class*='bg-']) {
  background: none;
}
/*! -- Stack Helpers -- */
@media all and (max-width: 767px) {
  .block--xs {
    margin-top: 0.92857143em;
  }
}
.container .container {
  max-width: 100%;
}
.switchable-toggle {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
.back-to-top {
  position: fixed;
  width: 3.71428571em;
  height: 3.71428571em;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  right: 1.85714286em;
  bottom: 3.71428571em;
  padding-top: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  z-index: 99;
  border: 1px solid #ececec;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
}
.back-to-top i {
  color: #252525;
}
.back-to-top:not(.active) {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  -webkit-transform: translate3d(0, 20px, 0);
  pointer-events: none;
}
.back-to-top.active:hover {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
.disable-scroll-bars {
  -ms-overflow-style: none;
}
.disable-scroll-bars::-webkit-scrollbar {
  display: none;
}
/*! -- Stack Animations -- */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    -webkit-transform: translate3d(0, -50px, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    -webkit-transform: translate3d(0, -50px, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    -webkit-transform: translate3d(50px, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    -webkit-transform: translate3d(50px, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    -webkit-transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    -webkit-transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
@keyframes kenBurns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@-webkit-keyframes kenBurns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/*! -- Stack Sections -- */
/* section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom):not(.space--xxs) + section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.border--bottom):not(.space--xxs) {
  padding-top: 0;
} */
section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom) + footer:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom) {
  padding-top: 0;
}
/*section:not(.imagebg):not([class*='bg-']) + section.bg--secondary {
  border-top: 1px solid #ebebeb;
}
section.bg--secondary:not(.imagebg) + section:not(.imagebg):not([class*='bg-']) {
  border-top: 1px solid #ebebeb;
}
section.bg--secondary:not(.unpad):not(.imageblock):not(.unpad--bottom):not(.border--bottom) + section.bg--secondary {
  padding-top: 0;
} */
section.bg--secondary + footer:not(.bg--dark):not(.bg--secondary) {
  border-top: 1px solid #ebebeb;
}
/* section.bg--dark + section.bg--dark {
  padding-top: 0;
} */
section.bg--dark:last-of-type + footer.bg--dark {
  background: #1b1b1b;
}
section.border--bottom:not([data-gradient-bg]) {
  border-bottom: 1px solid #ececec;
}
section.unpad {
  overflow: hidden;
}
section:not([class*='bg--']) + footer.bg--secondary {
  border-top: 1px solid #ebebeb;
}
section.text-center div[class*='col-']:first-child:last-child {
  margin: 0 auto;
  float: none;
}
.section--overlap {
  z-index: 2;
  position: relative;
}
/*! -- Stack Typography -- */
@media all and (max-width: 1024px) {
  html {
    font-size: 80%;
  }
}
h1,
.h1 {
  letter-spacing: -0.01em;
}
h1:not(:last-child),
.h1:not(:last-child) {
  margin-bottom: 0.59090909090909em;
}
@media all and (min-width: 768px) {
  h1.h1--large,
  .h1.h1--large {
    font-weight: 200;
    font-size: 4.428571428571429em;
    line-height: 1.048387096774194em;
  }
  h1.h1--large:not(:last-child),
  .h1.h1--large:not(:last-child) {
    margin-bottom: 0.419354838709677em;
  }
  h1.h1--large.type--uppercase,
  .h1.h1--large.type--uppercase {
    letter-spacing: 10px;
    margin-right: -10px;
  }
  h1.h1--large + p.lead,
  .h1.h1--large + p.lead {
    margin-top: 2.052631578947368em;
  }
}
h2,
.h2 {
  margin-bottom: 0.78787878787879em;
}
h3,
.h3 {
  margin-bottom: 1.04em;
}
h3 strong,
.h3 strong {
  font-weight: 400;
}
blockquote {
  font-family: 'Merriweather', serif;
  font-style: italic;
  font-weight: 300;
}
blockquote:not(:last-child) {
  margin-bottom: 1.04em;
}
blockquote > p {
  font-size: 1em !important;
}
h4,
.h4 {
  margin-bottom: 1.36842105263158em;
  font-weight: 400;
}
h4.inline-block + .h4.inline-block:not(.typed-text),
.h4.inline-block + .h4.inline-block:not(.typed-text) {
  margin-left: 0.68421052631579em;
}
h5,
.h5 {
  font-weight: 600;
}
h5:not(:last-child),
.h5:not(:last-child) {
  margin-bottom: 1.85714286em;
}
h6,
.h6 {
  font-weight: 700;
}
h6:not(:last-child),
.h6:not(:last-child) {
  margin-bottom: 2.16666666666667em;
}
h6.type--uppercase,
.h6.type--uppercase {
  letter-spacing: 1px;
  margin-right: -1px;
}
span.h1:not(.inline-block),
span.h2:not(.inline-block),
span.h3:not(.inline-block),
span.h4:not(.inline-block),
span.h5:not(.inline-block),
span.h6:not(.inline-block) {
  display: block;
}
b {
  font-weight: 600;
}
hr {
  border-color: #ECECEC;
}
.bg--dark hr {
  border-color: #585858;
}
[class*='bg-']:not(.bg--white):not(.bg--secondary) p,
[class*='imagebg']:not(.image--light) p {
  opacity: .9;
}
.lead {
  font-weight: 400;
  color: #808080;
}
.lead:not(:last-child) {
  margin-bottom: 1.36842105263158em;
}
.lead + .btn:last-child {
  margin-top: 0.92857143em;
}
p:last-child {
  margin-bottom: 0;
}
p:not(.pre) p strong {
  color: #252525;
}
pre {
  padding: 0.92857143em;
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 6px;
  line-height: 20px;
  max-height: 500px;
}
.bg--secondary > pre {
  background: #f5f5f5;
  border-color: #ddd;
}
.text-block {
  margin-bottom: 1.85714286em;
}
.text-block h2,
.text-block .h2 {
  margin-bottom: 0.3939393939394em;
}
.text-block h5,
.text-block .h5 {
  margin: 0;
}
.text-block h4:not(:last-child),
.text-block .h4:not(:last-child) {
  margin-bottom: 0.3421052631579em;
}
.text-block h3,
.text-block .h3 {
  margin-bottom: 0.52em;
}
@media all and (min-width: 768px) {
  div[class*='col-'] .text-block + .text-block {
    margin-top: 3.71428571em;
  }
}
.heading-block {
  margin-bottom: 3.71428571em;
}
.heading-block h1,
.heading-block h2,
.heading-block h3,
.heading-block h4,
.heading-block h5,
.heading-block h6,
.heading-block .h1,
.heading-block .h2,
.heading-block .h3,
.heading-block .h4,
.heading-block .h5,
.heading-block .h6 {
  margin-bottom: 0;
}
/*! -- Stack Colours -- */
.bg--dark .bg--secondary {
  background: #343434;
}
/*! -- Stack Links -- */
a {
  color: #4a90e2;
  font-weight: 700;
}
a:hover {
  color: #2275d7;
  text-decoration: underline;
}
a.block {
  font-weight: normal;
  text-decoration: none;
  color: #666666;
}
p a,
span a,
label a {
  font-size: 1em;
  text-decoration: underline;
  font-weight: 400;
  line-height: 1.85714286em;
}
p + a:not(.btn) {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
/*! -- Stack Tables -- */
table {
  width: 100%;
  border-collapse: separate;
}
table th,
table td {
  padding: 0.92857143em;
}
table th {
  background: #fafafa;
  color: #252525;
}
table tr:not(:last-of-type) {
  border-bottom: 1px solid #ececec;
}
table.border--round {
  border-radius: 6px;
  border: 1px solid #ececec;
}
.table--alternate-column th:nth-child(odd) {
  background: none;
}
.table--alternate-column td:nth-child(even) {
  background: #fafafa;
}
.table--alternate-row tbody tr:nth-child(even) {
  background: #fafafa;
}
.bg--dark table.border--round {
  border-radius: 6px;
  border: 1px solid #3f3f3f;
}
.bg--dark table th {
  background: #3f3f3f;
  color: #fff;
}
.bg--dark .table--alternate-row tbody tr:nth-child(even) {
  background: #323232;
}
/*! -- Stack Lists -- */
ul:not([class*='menu']) li > a {
  font-weight: normal;
}
ul:not([class*='menu']) li > a:hover {
  text-decoration: none;
}
ol {
  list-style-position: outside;
  list-style-type: decimal;
}
ol li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
ol.lead li:not(:last-child) {
  margin-bottom: 1.26315789473684em;
}
.list-inline {
  margin-left: 0;
  display: inline-block;
}
.list-inline li {
  display: inline-block;
  padding: 0;
}
.list-inline li:not(:last-child) {
  margin-right: 1.85714286em;
}
.list-inline:not(:last-child) {
  margin-right: 1.85714286em;
}
.list-inline--images img {
  max-height: 2.78571429em;
}
@media all and (min-width: 768px) {
  .list-inline--images li:not(:last-child) {
    margin-right: 5.57142857em;
  }
}
@media all and (max-width: 767px) {
  .list-inline--images li:not(:last-child) {
    margin-bottom: 1.85714286em;
  }
}
.list--loose > li:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.list--hover li {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.list--hover li:not(:hover) {
  opacity: .6;
}
.social-list a {
  color: #252525;
}
.imagebg:not(.image--light) .social-list a {
  color: #fff;
}
.results-list > li > a:first-child {
  display: flex;
  align-items: center;
  margin-bottom: 0.92857143em;
}
.results-list > li > a:first-child span {
  display: inline-block;
  margin-left: 0.46428571em;
}
.results-list > li > a:first-child h4 {
  display: inline-block;
  margin-bottom: 0;
}
.results-list > li > a:first-child:hover h4,
.results-list > li > a:first-child:hover span {
  text-decoration: underline;
}
.results-list > li:not(:last-child) {
  margin-bottom: 1.85714286em;
  padding-bottom: 1.85714286em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 767px) {
  .list-inline:not(.social-list):not(.list-inline--images) {
    display: block;
  }
  .list-inline:not(.social-list):not(.list-inline--images) li {
    display: block;
    margin: 0;
  }
  .list-inline:not(.social-list):not(.list-inline--images) li:not(:last-child) {
    margin-bottom: 0.46428571em;
  }
}
@media all and (max-width: 767px) {
  .list-inline {
    min-width: 100%;
  }
}
.row--list span.h6 {
  margin-bottom: 0;
}
.row--list span.h3:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .row--list > li {
    margin-bottom: 0.92857143em;
  }
}
/*! -- Stack Rules -- */
hr:first-child {
  margin-top: 0;
}
hr.short {
  width: 2.78571429em;
  border-color: #4a90e2;
}
hr[data-title] {
  margin: 2.78571429em 0;
  text-align: center;
}
hr[data-title]:before {
  content: attr(data-title);
  background: #ffffff;
  position: relative;
  bottom: 14px;
  padding: 0.92857143em;
  font-style: italic;
}
.bg--dark hr:not(.short),
.imagebg hr:not(.short),
.bg--primary hr:not(.short) {
  opacity: .3;
}
/*! -- Stack Buttons -- */
.btn {
  position: relative;
}
.btn:not([class*='primary']) {
  border-color: #d3d3d3;
}
.btn:not([class*='primary']):hover {
  border-color: #252525;
}
.btn.type--uppercase {
  letter-spacing: .5px;
}
.btn.type--uppercase .btn__text {
  letter-spacing: .5px;
  margin-right: -0.5px;
}
.btn .label {
  top: -0.92857143em;
  right: -3.71428571em;
}
.btn.btn--lg .btn__text {
  font-weight: 600;
}
.btn.btn--lg.type--uppercase .btn__text {
  letter-spacing: 1px;
}
.btn + p.type--fine-print,
.btn + span.type--fine-print {
  margin-top: 1.08333333333334em;
}
.btn.block {
  margin-left: 0;
}
.btn.block + .btn.block {
  margin-top: 0.92857143em;
}
.btn:hover {
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
}
.btn.btn--sm + .btn--sm {
  margin-left: 0.92857143em;
}
p > .btn {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .btn:not(:last-child) {
    margin-bottom: 1.85714286em;
  }
}
.btn--icon {
  padding-left: 5.57142857em;
}
.btn--icon i:not(.edit-button-i) {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  border-radius: 6px 0 0 6px;
  font-size: 16px;
  line-height: 41px;
}
.btn--icon i.socicon {
  line-height: 42px;
}
.btn--cart {
  width: 3.71428571em;
  height: 3.71428571em;
  border: 2px solid #252525;
  display: block;
  text-align: center;
  border-radius: 6px;
  opacity: .4;
  padding: 0;
}
.btn--cart:hover {
  opacity: 1;
}
.btn--cart .btn__text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.142857142857143em;
}
.imagebg:not(.image--light) .btn--icon:not([class*='bg']):not([class*='primary']) i {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
@media all and (min-width: 768px) {
  .btn-group .btn {
    margin-bottom: 0 !important;
    margin-left: 0;
  }
  .btn-group .btn + .btn {
    margin-left: 0;
  }
  .btn-group .btn:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .btn-group .btn:first-child {
    border-radius: 6px 0 0 6px;
  }
  .btn-group .btn:last-child {
    border-radius: 0 6px 6px 0;
  }
  .btn-group .btn:first-child:nth-last-child(2),
  .btn-group .btn:last-child:nth-child(2) {
    width: 50%;
  }
}
.btn-group {
  border: none;
  padding: 0;
}
@media all and (max-width: 767px) {
  .btn-group .btn {
    display: block;
  }
}
.bg--primary div:not([class*='feature']) .btn--primary {
  background: #fff;
  border-color: #fff;
}
.bg--primary div:not([class*='feature']) .btn--primary .btn__text {
  color: #4a90e2;
}
.bg--primary > .btn--primary {
  background: #fff;
  border-color: #fff;
}
.bg--primary > .btn--primary .btn__text {
  color: #4a90e2;
}
.bg--dark .btn:not(.btn--primary) {
  border-color: #3f3f3f;
}
.bg--dark .btn:not(.btn--primary):hover {
  border-color: #656565;
}
.bg--dark .btn .btn__text {
  color: #fff;
}
.imagebg:not(.image--light) .btn:not([class*='primary']) {
  border-color: rgba(255, 255, 255, 0.3);
}
.imagebg:not(.image--light) .btn:not([class*='primary']):hover {
  border-color: #fff;
}
.imagebg.image--light .btn:not([class*='primary']) {
  border-color: #252525;
  background: rgba(255, 255, 255, 0.3);
}
.imagebg.image--light .btn:not([class*='primary']):hover {
  background: rgba(255, 255, 255, 0.5);
}
h1 + .btn,
.h1 + .btn {
  margin-top: 1.85714286em;
}
h2 + .btn,
.h2 + .btn {
  margin-top: 0.92857143em;
}
/*! -- Stack Images -- */
img:last-child {
  margin-bottom: 0;
}
img.flag {
  max-height: 1.85714286em;
}
img.image--sm:not(:last-child) {
  margin-bottom: 0.92857143em;
}
img.promo.border--round {
  border: 1px solid #ececec;
}
p.lead img {
  max-height: 1.68421053em;
}
.imagebg h1,
.imagebg h2,
.imagebg h3,
.imagebg h4,
.imagebg h5,
.imagebg h6 {
  position: relative;
}
.imagebg:not(.image--light) span {
  color: #fff;
}
.imagebg.border--round {
  overflow: hidden;
}
section.parallax .row .background-image-holder {
  transform: none !important;
  top: 0 !important;
}
.triptych.border--round img {
  position: relative;
  border-radius: 6px;
  width: 33.333333%;
  float: left;
  margin: 0;
}
.triptych.border--round img:nth-child(2) {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  z-index: 2;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.gallery > div[class*='col-'] {
  margin-bottom: 30px;
}
.gallery-1 {
  overflow: hidden;
  position: relative;
}
.gallery-1 .gallery__image:not(:last-child) {
  margin-bottom: 30px;
}
.gallery-1 > div[class*='col-']:first-child > .gallery__image {
  height: 800px;
}
.gallery-1 > div[class*='col-']:last-child > .gallery__image {
  height: calc(385px);
}
.gallery__image {
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .gallery-1 .gallery__image {
    max-height: 300px;
    margin-bottom: 15px;
  }
  .gallery-1 .gallery__image:not(:last-child) {
    margin-bottom: 15px;
  }
}
.section--ken-burns {
  overflow: hidden;
}
.section--ken-burns > .background-image-holder,
.section--ken-burns > img:only-child {
  animation: kenBurns 15s ease alternate infinite;
  -webkit-animation: kenBurns 15s ease alternate infinite;
}
/*! -- Stack Titles -- */
.breadcrumbs {
  list-style: none;
}
.breadcrumbs li {
  font-size: 0.85714285714286em;
  display: inline-block;
}
.breadcrumbs li:not(:last-child) {
  margin-right: 1.08333333333334em;
}
.breadcrumbs li:not(:last-child):after {
  content: '\00bb';
  margin-left: 1.08333333333334em;
}
.breadcrumbs li a {
  font-weight: normal;
}
.imagebg .breadcrumbs,
.bg--primary .breadcrumbs {
  color: #fff;
}
.imagebg .breadcrumbs a,
.bg--primary .breadcrumbs a {
  color: #fff;
  font-weight: 600;
}
.elements-title {
  border-top: none !important;
}
.elements-title + .tabs-container:not( :nth-last-child(2)),
.elements-title + section:not( :nth-last-child(2)) {
  margin-bottom: 9.28571429em;
}
.elements-title + section:not(.imagebg):not([class*='bg-']):not(.unpad) {
  padding-top: 1.85714286em;
}
/*! -- Stack Labels -- */
.label {
  display: inline-block;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: .5px;
  color: #fff;
  text-transform: uppercase;
  height: 26px;
  min-width: 65px;
  padding: 0 10px;
  text-align: center;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 1.23809524em;
  right: 1.23809524em;
}
.label:not([class*='bg--']) {
  background: #31639c;
}
.label.label--inline {
  position: relative;
  top: 0;
  right: 0;
}
.label.label--inline:not(:last-child) {
  margin-right: 0.92857143em;
}
.label.label--inline + span,
.label.label--inline + p {
  position: relative;
  top: 1px;
}
.label.switchable-toggle {
  left: 50%;
  right: auto;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}
.bg--primary-1 .label:not([class*='bg--']) {
  background: #465773;
}
/*! -- Stack Bars -- */
.menu-horizontal > li,
.menu-vertical > li {
  font-family: 'Open Sans', 'Roboto', 'Helvetica', Sans-Serif;
}
.bar .logo {
  max-height: 1.85714286em;
  max-width: none;
}
.menu-horizontal > li > a,
.menu-horizontal > li > .modal-instance > a {
  font-weight: 500;
}
.menu-horizontal > li a:hover {
  text-decoration: none;
}
.hamburger-toggle i {
  color: #252525;
}
@media all and (min-width: 990px) {
  .menu-horizontal li:not(:last-child) {
    margin-right: 1.23809524em;
  }
  .bar__module:not(:only-child) .menu-horizontal {
    top: 0;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .bar-2 .menu-horizontal > li,
  .bar-1 .menu-horizontal > li {
    display: inline-block;
  }
  .bar-2 .menu-horizontal > li:not(:first-child),
  .bar-1 .menu-horizontal > li:not(:first-child) {
    margin-left: 0.92857143em;
  }
  .bar-2 .bar__module,
  .bar-1 .bar__module {
    margin-bottom: 1.85714286em;
  }
  .bar-2 .row div[class*='col-']:last-child .bar__module:last-child,
  .bar-1 .row div[class*='col-']:last-child .bar__module:last-child {
    margin-bottom: 0;
  }
}
.bar-1 .menu-horizontal > li > .dropdown__trigger,
.bar-1 .menu-horizontal > li > a {
  font-size: 0.857142857142857em;
  line-height: 2.166666666666667em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}
@media all and (min-width: 1024px) {
  .bar-1 .bar__module + .bar__module {
    margin-left: 1.85714286em;
  }
}
@media all and (min-width: 991px) {
  .bar-2 .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.bar-3 {
  font-size: 0.85714286em;
}
.bar-3 .menu-horizontal {
  top: 0;
}
@media all and (max-width: 990px) {
  .bar-3 .menu-horizontal li {
    display: inline-block;
  }
  .bar-3 .menu-horizontal li:not(:last-child) {
    margin-right: 0.92857143em;
  }
}
@media all and (min-width: 990px) {
  .bar-toggle .col-md-1 .logo {
    top: 10px;
  }
}
@media all and (max-width: 767px) {
  .bar-4 .logo-light {
    display: none;
  }
  .bar-4 .logo-dark {
    margin-bottom: 1.85714286em;
  }
  .bar.bg--dark {
    background: #252525;
  }
  .bar.bg--dark .hamburger-toggle i {
    color: #fff;
  }
}
/*! -- Stack Utilties -- */
.hidden {
  display: none !important;
  visibility: hidden !important;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
@media all and (min-width: 768px) and (max-width: 990px) {
  .text-left-sm {
    text-align: left;
  }
  .text-right-sm {
    text-align: right;
  }
  .text-center-sm {
    text-align: center;
  }
}
@media all and (max-width: 767px) {
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
  .text-center-xs {
    text-align: center;
  }
}
/*! -- Stack Alerts -- */
.alert {
  border-radius: 6px;
}
.alert:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.alert .alert__close {
  font-size: 1.35714286em;
}
/*! -- Stack Menu Toggle -- */
.menu-toggle {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  display: inline-block;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.menu-toggle i {
  font-size: 1.78571429em;
  position: relative;
  top: 13px;
  color: #252525;
}
.menu-toggle:hover {
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
}
/*! -- Stack Nav Utility -- */
.nav-utility {
  padding: 0.92857143em 0;
}
.nav-utility .nav-utility__module {
  font-size: 0.85714285714286em;
}
.nav-utility .nav-utility__module a {
  color: #666666;
  font-weight: normal;
}
.nav-utility .nav-utility__module a i {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: inline-block;
}
.nav-utility .nav-utility__module a:hover i {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
.nav-utility .nav-utility__module.right:not(:last-child) {
  margin-left: 2.16666666666667em;
}
.utility-toggle {
  display: inline-block;
  cursor: pointer;
  width: 3px;
  height: 3px;
  position: relative;
  bottom: 8px;
  border-radius: 50%;
  background: #252525;
}
.utility-toggle:before,
.utility-toggle:after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  background: #252525;
}
.utility-toggle:before {
  top: 6px;
}
.utility-toggle:after {
  top: 12px;
}
/*! -- Stack Nav Stacked -- */
.bar-stacked .logo {
  margin-bottom: 3.71428571em;
}
/*! -- Stack Nav Side Menu -- */
.notification.side-menu {
  z-index: 9999;
  background: #ffffff;
  padding-top: 1.85714286em;
  margin: 0;
  height: 100%;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  width: 27.85714286em;
}
.notification.side-menu .side-menu__module {
  overflow: hidden;
  padding: 2.78571429em;
}
.notification.side-menu .side-menu__module + hr:not(:last-child) {
  margin: 0;
}
.notification.side-menu .menu-vertical li a {
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
.notification.side-menu .menu-vertical li a:hover {
  text-decoration: none;
}
.notification.side-menu .btn:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.notification.side-menu .btn + ul.list--loose {
  margin-top: 0.92857143em;
}
.notification.side-menu .notification-close-cross {
  top: 0.92857143em;
  right: 2.78571429em;
  margin-top: 8px;
}
.notification.side-menu .social-list:not(:first-child) {
  margin-top: 1.85714286em;
}
.menu-toggle.pos-fixed {
  position: fixed;
  right: 5.57142857em;
  animation: fadeInDown .3s ease forwards;
}
@media all and (min-width: 768px) {
  .side-menu .side-menu__module span.type--fine-print {
    position: relative;
    top: 8px;
  }
}
@media all and (max-width: 767px) {
  .side-menu {
    width: 100%;
  }
  .side-menu .side-menu__module .float-right,
  .side-menu .side-menu__module .float-left {
    display: block;
  }
}
/*! -- Stack Nav Sidebar Column -- */
.nav-container.nav-container--sidebar + .main-container {
  width: calc(100vw - 20.428571428571463em);
  float: right;
}
.nav-sidebar-column {
  position: fixed;
  z-index: 3;
  left: 0;
  width: 20.42857143em;
  border-right: 1px solid #ececec;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 2.78571429em 1.85714286em;
}
.nav-sidebar-column:not([class*='bg-']) {
  background: #ffffff;
}
.nav-sidebar-column.bg--dark {
  border-right: 1px solid #3f3f3f;
}
.nav-sidebar-column::-webkit-scrollbar {
  display: none;
}
.nav-sidebar-column .logo {
  max-height: 2.32142857em;
  margin-bottom: 0.92857143em;
}
.nav-sidebar-column .text-block > p {
  margin-bottom: 0.92857143em;
}
.nav-sidebar-column .menu-vertical li.dropdown:not(:hover):after {
  opacity: .35;
}
.nav-sidebar-column .menu-vertical a:hover {
  text-decoration: none;
}
.nav-sidebar-column .dropdown .dropdown__container,
.nav-sidebar-column .dropdown .dropdown__content {
  left: 0 !important;
  position: relative;
  pointer-events: all;
}
.nav-sidebar-column .dropdown .dropdown__container:before {
  height: 0;
}
.nav-sidebar-column .dropdown .dropdown__content {
  transform: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-top: 0.46428571em;
  padding-right: 0;
  padding-left: 0.46428571em;
  background: none !important;
}
.nav-sidebar-column .dropdown .menu-vertical li.dropdown:after {
  right: 1.85714286em;
}
.nav-sidebar-column .dropdown:not(.dropdown--active) .dropdown__container {
  display: none;
}
.nav-sidebar-column .dropdown.dropdown--hover:hover > .dropdown__container {
  display: block;
}
.nav-sidebar-column .social-list {
  margin-bottom: 0.92857143em;
}
body.dropdowns--hover .nav-sidebar-column .dropdown:hover > .dropdown__container {
  display: block;
}
.nav-sidebar-column-toggle {
  transition: all .3s ease;
  left: 0;
  width: 3.71428571em;
  height: 3.71428571em;
  background: #ffffff;
  text-align: center;
  position: fixed;
  z-index: 4;
  cursor: pointer;
  border-radius: 0 0 6px 0;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
}
.nav-sidebar-column-toggle > i {
  transition: all .3s ease;
  font-size: 1.78571429em;
  position: relative;
  top: 11px;
}
.nav-sidebar-column-toggle:not(.toggled-class) > i {
  opacity: .5;
}
.nav-sidebar-column-toggle.toggled-class i:before {
  content: '\e80b';
}
@media all and (max-width: 990px) {
  .nav-sidebar-column {
    transition: all .3s ease;
    left: -20.42857143em;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0);
  }
  .nav-sidebar-column.active {
    left: 0;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .nav-sidebar-column-toggle.toggled-class {
    left: 20.42857143em;
  }
  .nav-container.nav-container--sidebar + .main-container {
    width: 100%;
    float: none;
  }
}
.nav-container.nav-container--right .nav-sidebar-column {
  right: 0;
  left: auto;
}
.nav-container.nav-container--right + .main-container {
  float: left;
}
.nav-container.nav-container--right .nav-sidebar-column-toggle {
  left: auto;
  right: 0;
  border-radius: 0 0 0 6px;
  box-shadow: -2px 1px 4px rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 990px) {
  .nav-container.nav-container--right .nav-sidebar-column {
    right: -20.42857143em;
    left: auto;
  }
  .nav-container.nav-container--right .nav-sidebar-column.active {
    left: auto;
    right: 0;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .nav-container.nav-container--right .nav-sidebar-column-toggle.toggled-class {
    left: auto;
    right: 20.42857143em;
  }
}
/*! -- Stack Nav Fullscreen -- */
.menu-fullscreen {
  color: #fff;
}
.menu-fullscreen .pos-absolute {
  width: 100%;
}
.menu-fullscreen a {
  color: #fff;
}
.menu-fullscreen a:hover {
  text-decoration: none;
}
.menu-fullscreen .social-list {
  margin-right: 0;
}
.menu-fullscreen:before {
  background: rgba(0, 0, 0, 0.9);
}
.menu-fullscreen .modal-content .modal-close-cross {
  right: 3.71428571em;
  top: 1em;
}
@media all and (max-width: 767px) {
  .menu-fullscreen .pos-bottom {
    position: relative;
  }
}
/*! -- Stack Dropdowns -- */
.dropdown > .dropdown__trigger:after {
  font-family: "stack-interface";
  display: inline-block;
  font-size: 8px;
  position: relative;
  bottom: 2px;
}
.dropdown > .dropdown__trigger .image--xxs {
  margin-right: 0.46428571em;
}
.dropdown .dropdown__content {
  border-radius: 6px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  padding: 1.23809524em 1.85714286em;
}
.dropdown .dropdown__content h5:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.dropdown .dropdown__content .background-image-holder {
  border-radius: 6px 0 0 6px;
}
.dropdown .dropdown__content > .pos-absolute[class*='col-'] {
  left: 0;
  top: 0;
  height: 100%;
  position: absolute !important;
}
.dropdown .dropdown__content > .pos-absolute[class*='col-'][data-overlay]:before {
  border-radius: 6px 0 0 6px;
}
.menu-horizontal > .dropdown > .dropdown__trigger:after {
  content: '\e80a';
  margin-left: 4px;
}
.menu-vertical > .dropdown > .dropdown__trigger {
  display: inline-block;
  width: 100%;
}
@media all and (max-width: 767px) {
  .dropdown [class*='col-'] + [class*='col-'] {
    margin-top: 0.92857143em;
  }
  .dropdown .dropdown__content:not([class*='bg-']) {
    border: none;
    box-shadow: none;
  }
}
@media all and (max-width: 767px) {
  .dropdown__container {
    left: 0 !important;
  }
}
.menu-vertical li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.menu-vertical li:not(:hover):not(.dropdown--active) {
  opacity: .75;
}
.menu-vertical li:not(:last-child) {
  margin-bottom: 0.30952381em;
}
.menu-vertical li.dropdown {
  position: relative;
}
.menu-vertical li.dropdown:after {
  content: '\e80c';
  font-family: 'stack-interface';
  right: 0;
  top: 0;
  position: absolute;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: inline-block;
  transform: translate3d(-10px, 0, 0);
  -webkit-transform: translate3d(-10px, 0, 0);
}
.menu-vertical li.dropdown:hover:after {
  transform: translate3d(-5px, 0, 0);
  -webkit-transform: translate3d(-5px, 0, 0);
}
.menu-vertical li.separate {
  border-top: 1px solid #e6e6e6;
  margin-top: 0.92857143em;
  padding-top: 0.92857143em;
}
.menu-vertical li.separate.dropdown:after {
  top: .92857143em;
}
.menu-vertical + h5 {
  margin-top: 1.85714286em;
}
.dropdown__container > .container {
  width: 100vw;
  max-width: 1140px;
}
@media all and (max-width: 990px) {
  .menu-vertical .dropdown.dropdown--active:after {
    opacity: 0;
  }
}
@media all and (min-width: 991px) {
  .dropdown .dropdown__container:before {
    height: 18px;
  }
}
/*! -- Stack Twitter Feeds -- */
.tweets-feed .interact {
  display: none;
}
.tweets-feed .user a:hover {
  text-decoration: none;
}
.tweets-feed .user img {
  border-radius: 50%;
}
.tweets-feed-1 {
  border-radius: 6px;
  border: 1px solid #ececec;
  overflow: hidden;
}
.tweets-feed-1 li {
  overflow: hidden;
  padding: 1.85714286em;
  padding-bottom: 0;
}
.tweets-feed-1 li:not(:last-child) {
  border-bottom: 1px solid #ececec;
}
.tweets-feed-1 .user {
  width: 20%;
  float: left;
  margin-bottom: 0.92857143em;
}
.tweets-feed-1 .user img {
  max-height: 3.71428571em;
  margin-right: 0.46428571em;
}
.tweets-feed-1 .user [data-scribe="element:name"],
.tweets-feed-1 .user [data-scribe="element:screen_name"] {
  display: none;
}
.tweets-feed-1 .tweet,
.tweets-feed-1 .timePosted {
  width: 80%;
  float: right;
}
.tweets-feed-1 .tweet {
  margin-bottom: 0.46428571em;
}
.tweets-feed-1 .timePosted {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.tweets-feed-1:not(:last-child) + .btn {
  margin-top: 2.78571429em;
}
.imagebg .tweets-feed-1,
.bg--dark .tweets-feed-1 {
  border-color: rgba(255, 255, 255, 0.1);
}
.imagebg .tweets-feed-1 li:not(:last-child),
.bg--dark .tweets-feed-1 li:not(:last-child) {
  border-color: rgba(255, 255, 255, 0.1);
}
.tweets-feed-2 .user {
  display: none;
}
.tweets-feed-2 .timePosted {
  display: none;
}
.tweets-feed-2 li {
  overflow: hidden;
}
.tweets-feed-2 li:before {
  font-family: 'socicon';
  content: "\e08d";
}
.tweets-feed-2 li .tweet {
  width: 85%;
  float: right;
}
.tweets-feed.slider .user img {
  display: inline-block;
  clear: both;
  margin-bottom: 0.92857143em;
}
.tweets-feed.slider .user [data-scribe="element:name"] {
  display: none;
}
.tweets-feed.slider .user [data-scribe="element:screen_name"] {
  display: block;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
  font-weight: normal;
}
.tweets-feed.slider .tweet {
  margin-top: 1.36842105263158em;
  margin-bottom: 0.68421052631579em;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
.tweets-feed.slider .timePosted {
  margin: 0;
}
.tweets-feed.slider:not(:last-child) + .btn {
  margin-top: 2.78571429em;
}
/*! -- Stack Instagram -- */
.instafeed a {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.instafeed a:hover {
  opacity: .75;
}
.instafeed:not(.instafeed--gapless) li {
  padding: 0.46428571em;
}
.instafeed.instafeed--gapless li {
  margin-bottom: -1px;
}
.instafeed + .btn {
  margin-top: 2.78571429em;
}
.instafeed[data-grid="1"][data-amount="8"] li {
  display: inline-block;
  width: 12.5%;
}
.instagram {
  position: relative;
}
.instagram.unpad .btn {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  margin: 0;
}
/*! -- Stack Cards -- */
.card__top {
  position: relative;
  overflow: hidden;
}
.card__action a {
  color: #666666;
  text-decoration: none;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.card__action a i {
  color: #252525;
  font-size: 1em;
}
.card__action a span {
  position: relative;
  bottom: 2px;
}
.card__action a:not(:hover) {
  opacity: .5;
}
.card p a {
  text-decoration: none;
}
.masonry__item .card.boxed {
  margin-bottom: 0;
}
.card-1 .card__avatar {
  margin-bottom: 0.92857143em;
}
.card-1 .card__avatar img {
  max-height: 2.78571429em;
  margin-right: 0.46428571em;
  margin-bottom: 0;
}
.card-1 .card__avatar,
.card-1 .card__meta {
  display: inline-block;
}
.card-1 .card__meta {
  float: right;
}
.card-1 .card__body {
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.92857143em;
  margin-bottom: 0.92857143em;
}
.card-1 .card__body img {
  margin-bottom: 0.92857143em;
}
.card-1 .card__body h4 {
  margin-bottom: 0.342105263157895em;
}
.bg--dark .card-1 .card__body {
  border-color: #3f3f3f;
}
@media all and (max-width: 767px) {
  .card .list-inline:not(.social-list) li {
    display: inline-block;
  }
  .card .list-inline:not(.social-list) li:not(:first-child) {
    margin-left: 0.92857143em;
  }
}
.card-2 .card__top img {
  border-radius: 6px 6px 0 0;
}
.card-2 .card__body {
  border-bottom: 1px solid #ececec;
  padding-bottom: 1.85714286em;
}
.card-2 .card__body h4 {
  margin: 0;
}
.card-2 .card__body p {
  margin-top: 0.92857143em;
}
.card-2 .card__bottom {
  overflow: hidden;
}
.card-2 .card__bottom > div {
  display: inline-block;
  width: 50%;
  float: left;
}
.card-2 .card__bottom .h6 {
  margin-bottom: 0.541666666666667em;
}
.card-2 .card__body,
.card-2 .card__bottom {
  padding: 1.85714286em;
  border: 1px solid #ececec;
}
.card-2 .card__body {
  border-bottom: none;
  border-top: none;
}
.card-2 .card__bottom {
  border-radius: 0 0 6px 6px;
  padding: 0.92857143em 1.85714286em;
}
.card-2 .card__action i {
  font-size: 1.78571429em;
}
.bg--dark .card-2 .card__body,
.bg--dark .card-2 .card__bottom {
  border-color: #3f3f3f;
}
/*! -- Stack Checkmarks & Crosses -- */
.checkmark {
  display: inline-block;
  position: relative;
  width: 1.23809524em;
  height: 1.23809524em;
  border-radius: 50%;
  text-align: center;
}
.checkmark:not([class*='bg-']) {
  background: #4a90e2;
}
.checkmark:before {
  content: '\2713';
  color: #fff;
  font-size: 11px;
  position: absolute;
  width: 100%;
  left: 0;
  top: -4px;
}
.checkmark.checkmark--cross:before {
  content: '\2717';
}
.checkmark:not(:last-child) {
  margin-right: 13px;
}
/*! -- Stack Icons -- */
.icon:not(.block) {
  display: inline-block;
}
.icon.icon--xs {
  font-size: 1.14285714285714em;
}
.text-block .icon + h4 {
  margin-top: 0.6842105263158em;
}
.imagebg .icon {
  color: #fff;
}
p .material-icons {
  font-size: 1em;
}
p .material-icons:first-child {
  margin-right: 0.30952381em;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.icon-circle {
  position: relative;
  display: inline-block;
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  border: 1px solid #ececec;
  background: #fafafa;
  text-align: center;
}
.icon-circle i {
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
}
/*! -- Stack Tooltips -- */
.tooltip {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 1.23809524em;
  height: 1.23809524em;
  border-radius: 50%;
  background: #252525;
  text-align: center;
  cursor: pointer;
}
.tooltip .tooltip__anchor {
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  position: absolute;
  top: -5px;
  left: 6px;
}
.tooltip .tooltip__text {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  text-align: left;
  z-index: 10;
  position: absolute;
  width: 280px;
  background: #fafafa;
  border: 1px solid #ececec;
  padding: 0.61904762em;
  left: 1.48571429em;
  top: -0.92857143em;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  line-height: 20px;
}
.tooltip:hover .tooltip__text {
  opacity: 1;
}
.tooltip:last-child {
  margin-left: 0.92857143em;
}
[data-tooltip] {
  position: relative;
  overflow: visible;
}
[data-tooltip]:after {
  transition: all .2s ease;
  content: attr(data-tooltip);
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  background: #252525;
  color: #fff;
  border: 1px solid #e7e7e7;
  padding: 2px 12px;
  pointer-events: none;
  white-space: nowrap;
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 2px;
  z-index: 2;
  font-family: 'Open Sans';
}
[data-tooltip]:not(:hover):after {
  opacity: 0;
}
/*! -- Stack Boxes -- */
.boxed {
  border-radius: 6px;
}
.boxed .background-image-holder {
  border-radius: 6px;
}
.boxed:before {
  border-radius: 6px;
}
.boxed:not(:last-child) {
  margin-bottom: 30px;
}
.boxed:not([class*='bg-']) {
  background: #ffffff;
}
.boxed.bg--secondary .boxed {
  background: #fafafa;
}
@media all and (min-width: 768px) {
  .switchable .imagebg .boxed {
    padding-left: 5.57142857em;
  }
}
.bg--dark .boxed:not([class*='bg-']),
.imagebg:not(.image--light) .boxed:not([class*='bg-']) {
  background: none;
  color: #fff;
}
.bg--dark .boxed:not([class*='bg-']).boxed--border,
.imagebg:not(.image--light) .boxed:not([class*='bg-']).boxed--border {
  border-color: #3f3f3f;
}
.bg--dark .boxed[class*='bg-'].boxed--border,
.imagebg:not(.image--light) .boxed[class*='bg-'].boxed--border {
  border: none;
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none) {
  background: rgba(20, 20, 20, 0.3);
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none).boxed--border {
  border: none;
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none) .boxed {
  background: none;
}
.bg--primary .boxed:not(.imagebg) {
  color: #666666;
}
.bg--primary .boxed:not(.imagebg) p {
  color: #666666;
}
.bg--primary .boxed:not(.imagebg) h1,
.bg--primary .boxed:not(.imagebg) h2,
.bg--primary .boxed:not(.imagebg) h3,
.bg--primary .boxed:not(.imagebg) h4,
.bg--primary .boxed:not(.imagebg) h5,
.bg--primary .boxed:not(.imagebg) h6,
.bg--primary .boxed:not(.imagebg) .h1,
.bg--primary .boxed:not(.imagebg) .h2,
.bg--primary .boxed:not(.imagebg) .h3,
.bg--primary .boxed:not(.imagebg) .h4,
.bg--primary .boxed:not(.imagebg) .h5,
.bg--primary .boxed:not(.imagebg) .h6,
.bg--primary .boxed:not(.imagebg) i {
  color: #252525;
}
.bg--primary .boxed:not(.imagebg) a {
  color: #4a90e2;
}
.bg--primary .boxed:not(.imagebg) .color--primary {
  color: #4a90e2 !important;
}
@media all and (min-width: 768px) {
  .boxed.boxed--emphasis {
    transform: scale(1.075);
    -webkit-transform: scale(1.075);
  }
}
/*! -- Stack Features Small -- */
.feature:not(.boxed) {
  margin-bottom: 60px;
}
.feature.feature--featured:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #4a90e2;
  left: 0;
}
.feature h5 {
  margin: 0;
}
.feature h4 {
  margin-bottom: 0.342105263157895em;
}
.feature h4 + p {
  max-width: 22.28571429em;
}
.feature i + h5 {
  margin-top: 1.85714286em;
}
.feature i + h4 {
  margin-top: 0.68421052631579em;
}
.feature i.icon--lg + h4 {
  margin-top: 1.36842105263158em;
}
.feature i.icon--lg + .h5 {
  margin-top: 0.92857143em;
}
.feature img + .boxed {
  border-radius: 0 0 6px 6px;
  border-top: none;
}
a.feature {
  display: block;
  text-decoration: none;
  font-weight: normal;
  color: #666666;
}
a.feature img:first-child:not(:last-child) {
  margin-bottom: 0.92857143em;
}
@media all and (max-width: 767px) {
  a.feature {
    margin-bottom: 0.92857143em !important;
  }
}
.imagebg:not(.image--light) .feature.bg--white a:not(.btn) {
  color: #4a90e2;
}
.imagebg:not(.image--light) .feature.bg--white .label {
  color: #fff;
}
@media all and (max-width: 767px) {
  .feature .feature__body form .row {
    margin-left: 0;
    margin-right: 0;
  }
}
section.text-center .feature-6 h4 + p {
  margin: 0 auto;
}
@media all and (min-width: 768px) {
  .row div[class*='col-']:nth-child(1):nth-last-child(3) .feature,
  .row div[class*='col-']:nth-child(2):nth-last-child(2) .feature,
  .row div[class*='col-']:nth-child(3):last-child .feature {
    margin-bottom: 0;
  }
}
a.block > .feature {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
a.block:hover > .feature {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.feature-1 {
  min-height: 11.14285714em;
}
.feature-1 p {
  margin: 0;
}
.feature-1 p.lead {
  min-height: 5.05263157894737em;
}
.feature-1 > img:first-child {
  border-radius: 6px 6px 0 0;
}
.feature-1 > a.block img {
  border-radius: 6px 6px 0 0;
}
.feature-1 > a.block + .feature__body {
  border-radius: 0 0 6px 6px;
}
.feature-1[class*='box-shadow'] {
  border-radius: 6px;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
}
.feature-1[class*='box-shadow']:hover {
  transform: translate3d(0, -10px, 0);
  -webkit-transform: translate3d(0, -10px, 0);
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.hover-shadow {
  border-radius: 6px;
  backface-visibility: hidden;
  overflow: hidden;
  border: 1px solid #ececec;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
}
.hover-shadow:hover {
  transform: translate3d(0, -10px, 0);
  -webkit-transform: translate3d(0, -10px, 0);
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.bg--dark .hover-shadow {
  border-color: #222;
}
.bg--dark .hover-shadow:hover {
  box-shadow: 0 23px 40px #000000;
}
.feature-2 {
  overflow: hidden;
}
.feature-2 .feature__body {
  width: 75%;
  float: right;
}
.feature-2 h5 {
  margin-bottom: 0.46428571em;
}
.feature-2 p:last-child {
  margin: 0;
}
.feature-2 i {
  width: 25%;
  float: left;
}
.feature-large .feature-2 + .feature-2:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 990px) {
  .feature-2 .feature__body,
  .feature-2 i {
    width: 100%;
    float: none;
  }
  .feature-2 p:first-of-type {
    margin-top: 0.92857143em;
  }
}
.feature-3 i {
  margin-bottom: 0.16666666666667em;
}
.feature-3 p {
  min-height: 7.42857143em;
}
.feature-4 .btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0;
  padding: 0.92857143em;
}
.feature-4 .btn:hover {
  transform: none;
}
.feature-4 p {
  min-height: 9.28571429em;
}
.feature-4 p:last-of-type {
  margin-bottom: 3.71428571em;
}
.feature-5 i {
  width: 25%;
  float: left;
}
.feature-5 .feature__body {
  width: 75%;
  float: right;
}
.feature-5 p {
  min-height: 5.57142857em;
}
.feature-5 p:last-of-type {
  margin-bottom: 0.92857143em;
}
.feature-5:not([class*='bg-']) {
  color: #252525;
}
.feature-6 p {
  min-height: 9.28571429em;
}
.feature-6 p:last-child {
  margin: 0;
}
.feature-7 {
  height: 13em;
  margin-bottom: 30px;
}
.feature-7[data-overlay]:before {
  border-radius: 6px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.feature-7[data-overlay]:hover:before {
  opacity: .75;
}
.feature-7 .background-image-holder {
  border-radius: 6px;
}
.feature-7 h3 {
  margin: 0;
}
.row--gapless .feature-7 {
  margin: 0;
  border-radius: 0;
}
.row--gapless .feature-7 .background-image-holder {
  border-radius: 0;
}
.row--gapless .feature-7:before {
  border-radius: 0;
}
@media all and (max-width: 767px) {
  .feature-7 .pos-vertical-center {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
  }
}
.feature-8 {
  padding: 2.78571429em 0 3.71428571em 0;
}
.feature-8:not(.boxed) {
  margin-bottom: 0;
}
.feature-8 .feature__body {
  max-width: 70%;
  margin: 0 auto;
}
.feature-8 p {
  min-height: 5.57142857em;
}
@media all and (max-width: 990px) {
  .feature-8 .feature__body {
    max-width: 85%;
  }
}
@media all and (max-width: 767px) {
  .feature p {
    min-height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .feature.boxed {
    margin-bottom: 15px;
  }
  .feature:not(.boxed) {
    margin-bottom: 30px;
  }
  .feature.feature-8 {
    margin-bottom: 0;
  }
}
.feature-8 img {
  max-height: 14.85714286em;
}
.feature-9 h4 {
  margin: 0;
}
.feature-9:not(.boxed) {
  margin-bottom: 30px;
}
@media all and (min-width: 768px) {
  .features-small-14 .text-block {
    margin-bottom: 3.71428571em;
  }
  .features-small-14 h4 {
    min-height: 2.736842105263158em;
  }
}
/*! -- Stack Features Large -- */
@media all and (min-width: 768px) {
  .staggered div[class*='col-']:nth-child(2):last-child {
    margin-top: 13em;
  }
  .staggered div[class*='col-']:nth-child(2):last-child:not(:last-child) {
    margin-bottom: 9.28571429em;
  }
  .staggered div[class*='col-'] .feature:not(:last-child) {
    margin-bottom: 9.28571429em;
  }
}
.feature-large h4:first-child {
  margin-bottom: 0.68421052631579em;
}
.feature-large .feature:not(.boxed) {
  margin-bottom: 30px;
}
.feature-large .feature-3.text-center p {
  margin: 0 auto;
}
.feature-large .lead + .feature-large__group {
  margin-top: 3.71428571em;
}
.feature-large .feature-large__group {
  overflow: hidden;
}
.feature-large-1 .lead {
  margin-bottom: 2.73684210526316em;
}
@media all and (min-width: 768px) {
  .feature-large-2 div[class*='col-']:first-child {
    margin-top: 5.57142857em;
  }
  .feature-large-2 div[class*='col-']:last-child {
    margin-top: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .feature-large-2 img {
    margin: 1.85714286em 0;
  }
}
.feature-large-7.switchable .boxed div[class*='col-']:only-child {
  float: none;
}
.feature-large-13 p.lead + .text-block {
  margin-top: 3.71428571em;
}
.feature-large-13 div[class*='col-'] .text-block + .text-block {
  margin-top: 2.78571429em;
}
@media all and (min-width: 1200px) {
  .feature-large-13:not(.text-center) .text-block p {
    max-width: 26em;
  }
}
.feature-large-14 h5 {
  margin-bottom: 0.46428571em;
}
.feature-large-14 .boxed {
  margin-bottom: 0;
}
.feature-large-14 .boxed.boxed--border img {
  border-radius: 6px;
}
@media all and (min-width: 768px) {
  .feature-large-14.switchable .switchable__text {
    margin-top: 5.57142857em;
  }
}
/*! -- Stack Accordions -- */
.accordion .accordion__title {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  opacity: .5;
  margin-bottom: 0.92857143em;
}
.accordion .accordion__title:hover {
  opacity: 1;
}
.accordion .accordion__title:before {
  font-family: "stack-interface";
  content: '\e80d';
  margin-right: 6px;
  color: #252525;
}
.accordion .accordion__title span {
  display: inline-block;
}
.accordion li.active {
  margin-bottom: 0.92857143em;
}
.accordion li.active .accordion__title {
  opacity: 1;
}
.accordion li.active .accordion__title:before {
  content: '\e80a';
}
.accordion li.active .accordion__content {
  animation: fadeUp .5s ease forwards;
  -webkit-animation: fadeUp .5s ease forwards;
}
.accordion-1 li.active .accordion__title {
  border-color: #4a90e2;
}
.accordion-1 li.active .accordion__title span {
  color: #fff;
}
.accordion-1 li.active .accordion__title:before {
  color: #fff;
}
.accordion-1 li .accordion__title {
  border-radius: 6px;
  border-bottom: 1px solid #ECECEC;
}
.accordion-2 li .accordion__title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ECECEC;
  padding-left: 0;
}
.accordion-2 li.active .accordion__title {
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.bg--dark .accordion-2 li .accordion__title,
.imagebg:not(.image--light) .accordion-2 li .accordion__title,
.bg--primary .accordion-2 li .accordion__title,
.bg--dark .accordion-1 li .accordion__title,
.imagebg:not(.image--light) .accordion-1 li .accordion__title,
.bg--primary .accordion-1 li .accordion__title {
  border-color: #4b4b4b;
}
.bg--dark .accordion-2 li .accordion__title:before,
.imagebg:not(.image--light) .accordion-2 li .accordion__title:before,
.bg--primary .accordion-2 li .accordion__title:before,
.bg--dark .accordion-1 li .accordion__title:before,
.imagebg:not(.image--light) .accordion-1 li .accordion__title:before,
.bg--primary .accordion-1 li .accordion__title:before {
  color: #fff;
}
.imagebg:not(.image--light) .accordion li .accordion__title,
.bg--primary .accordion li .accordion__title {
  border-color: rgba(255, 255, 255, 0.3);
}
/*! -- Stack Tabs -- */
.tabs-container.tabs--vertical {
  overflow: hidden;
}
.tabs-container.tabs--vertical .tabs {
  width: 25%;
  float: left;
}
.tabs-container.tabs--vertical .tabs li {
  display: block;
}
.tabs-container.tabs--vertical .tabs li:not(:last-child) {
  border-right: none;
  border-bottom: 1px solid #ECECEC;
}
.tabs-container.tabs--vertical .tabs-content {
  width: 70%;
  float: right;
  margin-top: 0;
}
.tabs-container.tabs--vertical .tabs-content > li:not(.active) .tab__content {
  animation: fadeOutUp .5s ease forwards;
  -webkit-animation: fadeOutUp .5s ease forwards;
}
.tabs-container.tabs--vertical .tabs-content > li.active .tab__content {
  animation: fadeInUp .5s ease forwards;
  -webkit-animation: fadeInUp .5s ease forwards;
  backface-visibility: hidden;
}
.tabs-container.tabs--vertical.tabs--icons .tabs {
  width: 15%;
  text-align: center;
}
.tabs-container.tabs--vertical.tabs--icons .tabs-content {
  width: 80%;
}
@media all and (max-width: 767px) {
  .tabs-container.tabs--vertical .tabs,
  .tabs-container.tabs--vertical .tabs-content {
    width: 100%;
    float: none;
  }
  .tabs-container.tabs--vertical .tabs {
    margin-bottom: 1.85714286em;
  }
  .tabs-container.tabs--vertical .tabs-content > li:not(.active) .tab__content {
    animation: fadeOutRight .5s ease forwards;
    -webkit-animation: fadeOutRight .5s ease forwards;
  }
  .tabs-container.tabs--vertical .tabs-content > li.active .tab__content {
    animation: fadeInLeft .5s ease forwards;
    -webkit-animation: fadeInLeft .5s ease forwards;
    backface-visibility: hidden;
  }
}
.tabs {
  display: inline-block;
  border: 1px solid #ECECEC;
  border-radius: 6px;
  margin-bottom: 1.85714286em;
}
.tabs li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  padding: 0.92857143em 1.85714286em;
}
.tabs li:not(:last-child) {
  border-right: 1px solid #ECECEC;
}
.tabs li.active {
  opacity: 1;
}
.tabs li.active .tab__title {
  color: #4a90e2;
}
.tabs li.active .tab__title span {
  color: #4a90e2;
}
.tabs li:not(.active) {
  opacity: .5;
}
.tabs li:hover {
  opacity: 1;
  cursor: pointer;
}
.tabs .tab__title {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.tabs .tab__title:not(.btn) {
  padding-right: 0;
  padding-left: 0;
}
.tabs .tab__title i + span {
  margin-top: 0.46428571em;
  display: inline-block;
  margin-bottom: 0;
}
.bg--primary .tabs {
  border-color: #fff;
}
.bg--primary .tabs li.active .tab__title {
  color: #fff;
}
.bg--primary .tabs li.active .tab__title span {
  color: #fff;
}
.bg--primary .tabs li:not(:last-child) {
  border-color: #fff;
}
@media all and (min-width: 768px) {
  .tabs.tabs--spaced {
    margin-bottom: 3.71428571em;
  }
}
.tabs-content {
  position: relative;
}
.tabs-content > li:not(.active) .tab__content {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  opacity: 0;
  pointer-events: none;
  animation: fadeOutRight .5s ease forwards;
  -webkit-animation: fadeOutRight .5s ease forwards;
}
.tabs-content > li.active {
  animation: fadeInLeft .5s ease forwards;
  -webkit-animation: fadeInLeft .5s ease forwards;
  backface-visibility: hidden;
}
@media all and (max-width: 767px) {
  .tabs li {
    display: block;
  }
  .tabs li:not(:last-child) {
    border-bottom: 1px solid #ECECEC;
    border-right: none;
  }
}
.tabs--folder .tabs {
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
  border-bottom: none;
}
.tabs--folder .tabs > li {
  border-top: 3px solid rgba(0, 0, 0, 0);
}
.tabs--folder .tabs > li.active {
  border-top: 3px solid #4a90e2;
}
.tabs--folder .tabs-content {
  overflow: hidden;
  position: relative;
  bottom: 8px;
  padding: 1.85714286em;
  border: 1px solid #ECECEC;
  border-radius: 0 0 6px 6px;
}
.tabs--folder .tabs-content > li:not(.active) .tab__content {
  top: 1.85714286em;
  left: 1.85714286em;
}
.tabs--folder .tab__title .h5 {
  margin: 0;
  display: inline-block;
}
.tabs--folder .tab__title .label {
  position: relative;
  color: #fff !important;
  top: -2px;
  right: 0;
  margin-left: 0.46428571em;
  min-width: 0;
}
/*! -- Stack Switchable Sections -- */
@media all and (min-width: 768px) {
  .switchable .container > .row {
    flex-flow: row-reverse;
  }
  .switchable.switchable--switch .container > .row {
    flex-flow: row;
  }
}
.switchable .switchable__text {
  margin-top: 1.85714286em;
}
.switchable [class*='col-']:first-child .switchable__text {
  margin-bottom: 2.78571429em;
}
.section--overlap .row {
  position: relative;
  margin-top: -13em;
}
@media all and (max-width: 990px) {
  .switchable .switchable__text {
    margin-top: 0;
  }
}
@media all and (max-width: 767px) {
  .switchable .switchable__text {
    margin-top: 1.85714286em;
  }
  .switchable .imageblock__content[class*='col-'] {
    float: none !important;
  }
  .switchable .row > div[class*='col-'] {
    float: none !important;
  }
  .switchable .row > div[class*='col-']:last-child:not(:only-child) {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack CTA -- */
.cta .btn:first-child:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.cta.boxed:last-child {
  margin-bottom: 0;
}
@media all and (min-width: 768px) {
  .cta--horizontal h4,
  .cta--horizontal .lead,
  .cta--horizontal .btn {
    margin: 0;
  }
  .cta--horizontal h4,
  .cta--horizontal .lead {
    position: relative;
    top: 4px;
  }
  .bg--dark .cta-1 > div[class*='col-']:last-child,
  .imagebg .cta-1 > div[class*='col-']:last-child {
    border-left: none;
  }
}
@media all and (min-width: 1200px) {
  .cta-1.boxed--border {
    padding: 0;
  }
  .cta-1 > div[class*='col-'] {
    padding: 1.23809524em 15px;
  }
  .cta-1 > div[class*='col-']:last-child {
    border-left: 1px solid #ECECEC;
  }
}
@media all and (max-width: 990px) {
  .cta-1 {
    text-align: center;
  }
  .cta-1 > div[class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
  .cta-1 > div[class*='col-']:not(:last-child) {
    margin-bottom: 0.92857143em;
  }
  .cta-1 > div[class*='col-']:last-child {
    margin-top: 1.85714286em;
  }
}
@media all and (max-width: 767px) {
  .cta .lead {
    margin: 1.36842105263158em 0;
  }
}
.cta-2 {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.cta-2 h2 {
  margin: 1.57575757575758em 0;
}
.cta-2.bg--primary:hover {
  background: #5798e4;
}
.cta-2:hover {
  background: #ffffff;
}
@media all and (min-width: 768px) {
  .cta-3 .switchable__text {
    margin-top: 5.57142857em;
  }
}
/*! -- Stack Videos -- */
.video-play-icon:before {
  border-color: transparent transparent transparent #252525;
  margin-top: -3px;
}
.modal-instance .video-play-icon + span {
  position: relative;
  display: inline-block;
  margin-left: 0.92857143em;
  white-space: nowrap;
  bottom: 22px;
}
.modal-instance .video-play-icon.video-play-icon--xs + span {
  bottom: 8px;
  margin-left: 0.46428571em;
}
.video-cover {
  background: #252525;
}
.video-cover.border--round {
  overflow: hidden;
}
.video-cover.border--round iframe {
  border-radius: 6px;
}
.video-cover:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.video-cover + span.h4 + span {
  display: inline-block;
  margin-left: 0.92857143em;
}
@media all and (min-width: 1025px) {
  .video-cover .video-play-icon {
    transition: 0.2s ease-out;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
  }
  .video-cover .video-play-icon:hover {
    box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
    transform: translate3d(-50%, -52%, 0);
    -webkit-transform: translate3d(-50%, -52%, 0);
  }
}
.imagebg .video-cover .video-play-icon {
  z-index: 10;
}
@media all and (min-width: 768px) {
  h2 + .video-cover {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  .video-1 .video-play-icon {
    margin-right: 1.85714286em;
  }
  .video-1 h2 {
    display: inline-block;
    position: relative;
    bottom: 14px;
  }
  div[class*='col-sm-6'] .video-1 h2 {
    bottom: 0;
    display: block;
  }
  div[class*='col-sm-6'] .video-1 .video-play-icon {
    margin-bottom: 0.46428571em;
  }
}
.video-1 h2 {
  margin: 0;
}
.imageblock__content .modal-instance {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
/*! -- Stack Modals -- */
.modal-container .modal-content:not(.height--natural) {
  height: auto;
  width: auto;
}
.modal-instance:not(.block) {
  display: inline-block;
}
.modal-instance.block > .btn {
  display: block;
}
@media all and (min-width: 768px) {
  .modal-instance + span:last-child {
    display: inline-block;
    margin-left: 0.92857143em;
  }
}
.modal-container .modal-content {
  max-height: 95%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}
.modal-container .modal-content .feature:only-child {
  margin-bottom: 0;
}
.modal-container .modal-content .feature:only-child .boxed {
  margin-bottom: 0;
}
.modal-container .modal-content .imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content .container {
  width: 100vw;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content > .modal-close-cross {
    top: 3em;
    background: #222;
    width: 2em;
    height: 2em;
    text-align: center;
    border-radius: 50%;
    color: #fff;
  }
  .modal-container:not(.modal-active) .modal-content {
    display: none;
  }
}
.modal-content {
  -ms-overflow-style: none;
}
.modal-content::-webkit-scrollbar {
  display: none;
}
/*! -- Stack Notifications -- */
.notification {
  margin: 1.85714286em;
  box-shadow: none;
}
.notification:not([class*='bg--']) {
  background: none;
}
.notification > .boxed {
  margin: 0;
}
.notification > .feature,
.notification .feature__body {
  margin-bottom: 0;
}
.search-box {
  width: 100%;
  margin: 0;
  padding: 1.85714286em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.search-box.notification--reveal {
  z-index: 9999;
}
.search-box .notification-close-cross {
  top: 2.32142857em;
  right: 1.85714286em;
}
.notification-close-cross.notification-close-cross--circle {
  background: #222;
  width: 2em;
  height: 2em;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}
/*! -- Stack Loader -- */
.loader {
  border: 3px solid #4a90e2;
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  position: fixed;
  z-index: 10;
  top: 50%;
  width: 30px;
  animation: pulsate 0.7s ease-out infinite;
}
.loader.loader--fade {
  opacity: 0;
  animation: none;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
/*! -- Stack Lightboxes -- */
.lb-close {
  position: relative;
  top: 10px;
}
.lb-close:before {
  content: '\00D7';
  color: #fff;
  font-size: 2.5em;
  font-weight: 400;
}
.lb-close:hover {
  text-decoration: none;
}
.lb-prev:after,
.lb-next:after {
  font-family: 'stack-interface';
  position: fixed;
}
.lb-prev:after {
  content: '\e801';
  left: 1.85714286em;
}
.lb-next:after {
  content: '\e802';
  right: 1.85714286em;
}
.lb-data {
  overflow: hidden;
  min-height: 30px;
}
a[data-lightbox] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
a[data-lightbox]:hover {
  opacity: .8;
  cursor: zoom-in;
}
/*! -- Stack Covers -- */
.cover .typed-headline {
  margin-bottom: 3.71428571em;
}
.cover .typed-headline .type--bold {
  font-weight: 400;
}
.cover .triptych {
  margin-top: 3.71428571em;
}
.cover .btn + .modal-instance {
  margin-top: 1.85714286em;
}
.cover h3 + form,
.cover .h3 + form {
  margin-top: 2.78571429em;
}
.cover .lead + .boxed {
  margin-top: 3.71428571em;
}
.cover.unpad--bottom {
  overflow: hidden;
}
.cover .modal-instance.block:not(:first-child):not(:last-child) {
  margin: 2.78571429em 0;
}
.cover .pos-bottom {
  margin-bottom: 1.85714286em;
}
@media all and (min-width: 768px) {
  .cover .row .row:last-child {
    margin-top: 5.57142857em;
  }
}
@media all and (max-width: 767px) {
  .cover .pos-bottom.pos-absolute {
    position: relative;
    margin-top: 1.85714286em;
  }
}
@media all and (max-width: 990px) {
  .cover .mt--3 {
    margin-top: 0;
  }
}
@media all and (min-width: 768px) {
  .cover .triptych {
    margin-top: 7.42857143em;
  }
}
.cover-fullscreen {
  min-height: 100vh;
}
.cover-fullscreen.slider[data-paging="true"] .slides .flickity-slider > li {
  height: 100vh;
}
.cover-fullscreen.slider[data-paging="true"] .pos-bottom {
  bottom: 5.57142857em;
  width: 100%;
}
.cover-fullscreen.slider[data-paging="true"] h4 {
  margin: 0;
}
.cover-slider {
  overflow: hidden;
}
.cover-slider .slider {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.cover-slider .slider:nth-child(1) {
  transform: translateY(-30%);
}
.cover-slider .slider:nth-child(2) {
  transform: translateY(-25%);
}
.cover-slider .slider:nth-child(3) {
  transform: translateY(-20%);
}
.cover-slider .slider .slide {
  opacity: .05;
}
.cover-slider .typed-headline {
  margin: 0;
}
.cover-slider .typed-headline + .btn {
  margin-top: 0.92857143em;
}
.cover-slider .slider-container {
  transform: rotateZ(-30deg) translateY(-10%);
  -webkit-transform: rotateZ(-30deg) translateY(-10%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
@media all and (min-width: 768px) {
  .cover-slider + .cta-4 {
    position: absolute;
    background: none;
    color: #fff;
    border-bottom: none !important;
    width: 100%;
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
  }
  .cover-slider + .cta-4 a {
    color: #fff;
    font-weight: 600;
  }
}
.cover-features.space--lg {
  padding-bottom: 3.71428571em;
}
.cover-features .row:nth-child(2) {
  margin-top: 11.14285714em;
}
@media all and (max-width: 767px) {
  .cover-features.space--lg {
    padding-bottom: 1.85714286em;
  }
  .cover-features .row:nth-child(2) {
    margin-top: 3.71428571em;
  }
}
/*! -- Stack Processes -- */
.process-1 {
  padding: 3.71428571em 0;
  position: relative;
  overflow: hidden;
}
.process-1 .process__item {
  width: 50%;
}
.process-1 .process__item:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.process-1:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  background: #4a90e2;
}
.process__item {
  position: relative;
}
.process__item:after {
  content: '';
  width: 1.85714286em;
  height: 1.85714286em;
  background: #fff;
  border: 2px solid #4a90e2;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-left: 1px;
}
@media all and (min-width: 768px) {
  .process-1 .process__item:nth-child(odd) {
    text-align: right;
    padding-right: 3.71428571em;
  }
  .process-1 .process__item:nth-child(odd):after {
    right: -0.92857143em;
  }
  .process-1 .process__item:nth-child(even) {
    margin-left: 50%;
    padding-left: 3.71428571em;
  }
  .process-1 .process__item:nth-child(even):after {
    left: -0.92857143em;
  }
}
@media all and (max-width: 767px) {
  .process-1:before,
  .process-2:before {
    left: 0.92857143em;
  }
  .process-1 .process__item,
  .process-2 .process__item {
    width: 100%;
    padding-left: 3.71428571em;
  }
  .process-1 .process__item:after,
  .process-2 .process__item:after {
    left: 0;
  }
}
.bg--dark .process-1:before,
.imagebg .process-1:before,
.bg--dark .process-2:before,
.imagebg .process-2:before {
  background: #fff;
}
.bg--dark .process-1 .process__item:after,
.imagebg .process-1 .process__item:after,
.bg--dark .process-2 .process__item:after,
.imagebg .process-2 .process__item:after {
  background: #fff;
}
.bg--dark .process-1 .process__item:after,
.bg--dark .process-2 .process__item:after {
  border-color: #252525;
}
.process-2 {
  position: relative;
}
.process-2 .process__item {
  padding-top: 3.71428571em;
}
.process-2 .process__item:after {
  display: inline-block;
  text-align: center;
}
.process-2:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0.92857143em;
  left: 0;
  background: #4a90e2;
}
@media all and (max-width: 767px) {
  .process-2:before {
    height: 100%;
    width: 2px;
    left: 1.85714286em;
    margin-left: 2px;
  }
  .process-2 .process__item:after {
    top: 3.71428571em;
  }
}
.process-3 {
  list-style: none;
  position: relative;
}
.process-3 li {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.process-3 li .process__number {
  float: left;
  width: 15%;
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  border: 2px solid #4a90e2;
  background: #fff;
  text-align: center;
}
.process-3 li .process__number span {
  font-size: 1.35714286em;
  font-weight: bold;
  color: #4a90e2;
  position: relative;
  top: 12px;
}
.process-3 li .process__body {
  width: 80%;
  float: right;
}
.process-3 li .process__body h4 {
  margin-bottom: 0.342105263157895em;
}
.process-3 li:not(:last-child) {
  margin-bottom: 3.71428571em;
}
.process-3:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  left: 1.85714286em;
  top: 0;
  border-left: 1px dashed #ddd;
}
.bg--dark .process-3 li .process__number {
  background: #252525;
}
.bg--dark .process-3:before {
  border-color: #aaa;
}
.process-stepper > li {
  transition: all .3s ease;
  display: inline-block;
  float: left;
  text-align: center;
  position: relative;
}
.process-stepper > li:before {
  transition: all .3s ease;
  content: '';
  width: 0.92857143em;
  height: 0.92857143em;
  background: #ccc;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.92857143em;
}
.process-stepper > li:after {
  transition: all .3s ease;
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  background: #ccc;
  top: 5px;
  left: 0;
}
.process-stepper > li:first-child:after,
.process-stepper > li:last-child:after {
  width: 50%;
}
.process-stepper > li:first-child:after {
  left: 50%;
}
.process-stepper > li.active:before,
.process-stepper > li.active:after {
  background: #4a90e2;
}
.process-stepper > li > span {
  cursor: default;
}
.process-stepper[data-process-steps="6"] > li {
  width: 16.666666666666667%;
}
.process-stepper[data-process-steps="5"] > li {
  width: 20%;
}
.process-stepper[data-process-steps="4"] > li {
  width: 25%;
}
.process-stepper[data-process-steps="3"] > li {
  width: 33.33333333%;
}
.process-stepper[data-process-steps="2"] > li {
  width: 50%;
}
.bg--dark .process-stepper li:not(.active):before,
.bg--dark .process-stepper li:not(.active):after {
  background: #585858;
}
/*! -- Stack Typed Text -- */
span + .typed-text.h2 {
  margin-left: 4px;
}
span + .typed-text.h1 {
  margin-left: 6px;
}
@media all and (max-width: 767px) {
  .typed-text {
    display: block;
  }
  .typed-headline {
    margin-bottom: 1.85714286em;
  }
  .typed-headline h1,
  .typed-headline .h1 {
    margin-bottom: 0;
  }
}
/*! -- Stack Forms -- */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
form:before,
form:after {
  content: '';
}
.bg--dark input,
.imagebg:not(.image--light) input,
.bg--dark textarea,
.imagebg:not(.image--light) textarea,
.bg--dark select,
.imagebg:not(.image--light) select,
.bg--dark .input-select:after,
.imagebg:not(.image--light) .input-select:after {
  color: #666666;
}
.bg--dark label,
.imagebg:not(.image--light) label {
  color: #fff;
}
.bg--dark form.attempted-submit input.field-error {
  background: #D84D4D;
  color: #fff;
}
form {
  position: relative;
}
form > div[class*='col-']:not(:last-child),
form > .row > div[class*='col-']:not(:last-child) {
  margin-bottom: 0.92857143em;
}
form .boxed:last-child {
  margin: 0;
}
form.form--clearfix {
  margin-left: -15px;
}
@media all and (min-width: 768px) {
  div[class*='col-'] > form div[class*='col-']:last-child:nth-child(2) {
    padding-right: 0;
  }
  div[class*='col-'] > form div[class*='col-']:first-child:nth-last-child(2) {
    padding-left: 0;
  }
  form.form--horizontal > div[class*='col-'] {
    margin: 0;
  }
}
@media all and (max-width: 767px) {
  .row form > .row {
    margin-left: 0;
    margin-right: 0;
  }
}
h2 + form,
.h2 + form {
  margin-top: 2.78571429em;
}
h3 + form,
.h3 + form,
.lead + form {
  margin-top: 2.78571429em;
}
.cover .lead + form {
  margin-top: 3.71428571em;
}
form + span.type--fine-print {
  margin-top: 1.08333333333334em;
  display: inline-block;
}
.form--inline > span.h4 {
  color: #666666;
}
.form--inline input,
.form--inline [class*='input-'] {
  display: inline-block;
  max-width: 200px;
}
.form--inline > span:not(:last-child),
.form--inline input:not(:last-child),
.form--inline [class*='input-']:not(:last-child) {
  margin-right: 0.92857143em;
}
.form--inline button {
  max-width: 200px;
}
.bg--dark .form--inline > span.h4,
.imagebg:not(.image--light) .form--inline > span.h4 {
  color: #fff;
}
button,
input[type="submit"] {
  height: 3.25000000000001em;
}
button.btn,
input[type="submit"].btn {
  font-size: 0.85714286em;
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;
}
button.btn.btn--primary,
input[type="submit"].btn.btn--primary {
  color: #fff;
}
button.btn.type--uppercase,
input[type="submit"].btn.type--uppercase {
  letter-spacing: .5px;
  margin-right: -0.5px;
}
button.checkmark.checkmark--cross,
input[type="submit"].checkmark.checkmark--cross {
  width: 1.85714286em;
  height: 1.85714286em;
  border: none;
  background: #e23636;
}
button.checkmark.checkmark--cross:before,
input[type="submit"].checkmark.checkmark--cross:before {
  content: '\00d7';
  font-size: 18px;
  top: -1px;
}
button[type="submit"].btn--loading:after,
input[type="submit"][type="submit"].btn--loading:after {
  background: #4a90e2;
}
.bg--primary button.btn {
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff;
}
.bg--primary button.btn:hover {
  border-color: #fff;
}
.bg--primary button.btn.btn--primary {
  color: #4a90e2;
  border-color: #4a90e2;
}
.bg--primary button.btn.btn--primary-1 {
  border-color: #31639c;
}
input,
select {
  height: 2.78571429em;
}
input.field-error {
  border-color: #EBA8A8;
}
input[type] + input[type],
input[type] + .input-checkbox,
input[type] + button,
input[type] + .input-select {
  margin-top: 0.92857143em;
}
input,
[class*='input-'] label,
select,
textarea {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
input:not([class*='col-']),
select:not([class*='col-']),
.input-select:not([class*='col-']),
textarea:not([class*='col-']),
button[type="submit"]:not([class*='col-']) {
  width: 100%;
}
input[type],
select,
textarea {
  padding-left: 0.92857143em;
}
input[type]:focus,
select:focus,
textarea:focus {
  border-color: #76abe9;
}
input[type="image"] {
  border: none;
  padding: none;
  width: auto;
}
label {
  font-size: .85714286em;
}
.bg--primary label,
.bg--primary-1 label {
  color: #fff;
}
.input-icon i {
  top: 8px;
  left: 8px;
}
.input-icon input {
  padding-left: 2.78571429em;
}
.input-icon.input-icon--right i {
  left: auto;
  right: 8px;
}
.input-icon.input-icon--right input {
  padding-right: 2.78571429em;
  padding-left: .92857143em;
}
.text-center .input-checkbox label,
.text-center .input-radio label {
  margin: 0 auto;
}
.input-checkbox label,
.input-radio label {
  font-size: 1em;
}
.input-checkbox {
  margin-top: 0.46428571em;
}
.input-checkbox.field-error label {
  border-color: #EBA8A8;
}
.input-checkbox label {
  background: none;
  border: 1px solid #d3d3d3;
  text-align: center;
  position: relative;
}
.input-checkbox label:not(:last-child) {
  margin-right: 0.46428571em;
}
.input-checkbox label:hover {
  border-color: #4a90e2;
}
.input-checkbox label:before {
  content: '';
  left: 0;
  border-radius: 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #4a90e2;
  opacity: 0;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.input-checkbox input:checked + label {
  border-color: #4a90e2;
  background: #4a90e2;
}
.input-checkbox input:checked + label:after {
  content: 'L';
  transform: rotateY(180deg) rotateZ(-45deg);
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: -2px;
  font-weight: 700;
}
.input-checkbox input:checked + label:before {
  animation: pulse .45s ease forwards;
  -webkit-animation: pulse .45s ease forwards;
}
.input-checkbox + span {
  display: inline-block;
  position: relative;
  bottom: 10px;
  margin-left: 0.92857143em;
  font-size: 0.85714286em;
  white-space: nowrap;
}
.input-checkbox + button[type] {
  margin-top: 0.92857143em;
}
.input-checkbox + span + button[type] {
  margin-top: 0.92857143em;
}
.bg--dark .input-checkbox:not(.checked) label {
  border-color: #a5a5a5;
}
.bg--dark .input-checkbox + span {
  opacity: .75;
}
.input-checkbox.input-checkbox--switch label {
  width: 3.71428571em;
  border-radius: 60px;
}
.input-checkbox.input-checkbox--switch label:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-color: #d3d3d3;
  opacity: 1;
  left: 2px;
  top: 2px;
}
.input-checkbox.input-checkbox--switch label:hover:before {
  border-color: #4a90e2;
}
.input-checkbox.input-checkbox--switch input:checked + label {
  background: none;
}
.input-checkbox.input-checkbox--switch input:checked + label:before {
  animation: none !important;
  background: #4a90e2;
  border-color: #4a90e2;
  transform: translateX(1.85714286em);
}
.input-checkbox.input-checkbox--switch input:checked + label:after {
  display: none;
}
.input-radio.field-error label {
  border-color: #EBA8A8;
}
.input-radio .input__label {
  font-size: .85714286em;
  display: inline-block;
}
.input-radio .input__label + label {
  margin-top: 0.92857143em;
}
.input-radio label {
  position: relative;
  background: none;
  border: 1px solid #d3d3d3;
}
.input-radio label:hover {
  border-color: #4a90e2;
}
.input-radio label:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #4a90e2;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
  left: 0;
}
.input-radio input:checked + label {
  border-color: #4a90e2;
  background-color: #4a90e2;
}
.input-radio input:checked + label:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 7px;
  top: 7px;
  background-color: #fff;
  border-radius: 50%;
}
.input-radio input:checked + label:before {
  animation: pulse .4s ease forwards;
}
.input-radio--innerlabel label {
  transition: all .3s ease;
  height: 2.78571429em;
  line-height: 2.50714286em;
  padding: 0 0.92857143em;
  border: 1px solid #ececec;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.input-radio--innerlabel:hover {
  border-color: #4a90e2;
}
.input-radio--innerlabel input:checked + label {
  border-color: #4a90e2;
  background: #4a90e2;
  color: #fff;
}
.input-radio--innerlabel input:checked + label:after {
  display: none;
}
.input-radio--innerlabel input:checked + label:before {
  animation: none;
}
@media all and (max-width: 1024px) {
  .input-radio input:checked + label:after {
    top: 6px;
    left: 6px;
  }
}
.bg--primary .input-radio--innerlabel {
  border-color: #76abe9;
}
.bg--primary .input-radio--innerlabel:hover {
  border-color: #fff;
}
.bg--primary .input-radio--innerlabel.checked {
  background: #fff;
  border-color: #fff;
}
.bg--primary .input-radio--innerlabel.checked label {
  color: #4a90e2;
}
.bg--primary-1 .input-radio--innerlabel {
  border-color: #3e7cc2;
}
.bg--primary-1 .input-radio--innerlabel:hover {
  border-color: #fff;
}
.bg--primary-1 .input-radio--innerlabel.checked {
  background: #fff;
  border-color: #fff;
}
.bg--primary-1 .input-radio--innerlabel.checked label {
  color: #31639c;
}
.input-select {
  position: relative;
}
.input-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
.input-select select::-ms-expand {
  display: none;
}
.input-select:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.input-select:after {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  font-size: 30px;
  content: '\2263';
  pointer-events: none;
  padding: 0 13px;
  border-left: 1px solid #ececec;
  line-height: 31px;
}
.input-select.input-select--borderless:after {
  border-left: none;
}
.input-select:focus:after {
  border-color: #4a90e2;
}
.input-select select:focus {
  border-color: #4a90e2;
}
.input-number {
  position: relative;
}
.input-number > input[type="number"] {
  padding-left: .46428571em;
  -moz-appearance: textfield;
  width: 100%;
  text-align: center;
}
.input-number > input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
.input-number .input-number__controls {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  padding: 0 0.92857143em;
}
.input-number .input-number__controls > span {
  position: absolute;
  display: block;
  width: 10%;
  min-width: 3.71428571em;
  height: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  padding-top: 6px;
  transition: all .3s ease;
}
.input-number .input-number__controls > span:hover {
  color: #4a90e2;
}
.input-number .input-number__controls .input-number__increase {
  right: 0;
  border-left: 1px solid #ececec;
}
.input-number .input-number__controls .input-number__decrease {
  left: 0;
  border-right: 1px solid #ececec;
}
input[type="image"] {
  padding: 0;
  width: auto;
  border: none;
}
/*! -- Stack Subscribe Forms -- */
.subscribe-form-1 h3 {
  margin-bottom: 0.52em;
}
.subscribe-form-1.boxed form {
  margin: 1.85714286em;
}
@media all and (min-width: 991px) {
  .subscribe-form-2 p {
    top: 2px;
    position: relative;
  }
  .subscribe-form-2 input {
    margin: 0;
  }
  .subscribe-form-2 form [class*='col-'] {
    margin: 0 !important;
  }
}
@media all and (max-width: 990px) {
  .subscribe-form-2 {
    text-align: center;
  }
  .subscribe-form-2 form {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack Elaborate Forms -- */
@media all and (max-width: 767px) {
  .elaborate-form-1 .pos-vertical-center {
    padding-left: 15px;
    padding-right: 15px;
  }
  .elaborate-form-1 form .col-xs-6:nth-child(even) {
    padding-left: 15px;
  }
  .elaborate-form-1 form .col-xs-6:nth-child(odd) {
    padding-right: 15px;
  }
  .elaborate-form-1 .row form > .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
/* Stack Boxed Forms */
.boxed-form h4 {
  margin-bottom: 0.684210526315789em;
}
.boxed-form:last-child {
  margin-bottom: 0;
}
/* Stack Datepicker Form */
.datepicker {
  cursor: pointer;
}
.datepicker.datepicker--fluid + .picker {
  max-width: none;
}
.picker {
  transition: all .3s ease;
  z-index: 10;
  max-width: 100%;
  -webkit-transition: all .3s ease;
  margin-top: 0.46428571em;
  position: absolute;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
  padding: 0.92857143em;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.picker .picker__header {
  text-align: center;
  margin-bottom: 0.92857143em;
}
.picker .picker__header > div {
  font-weight: 600;
  display: inline-block;
  color: #252525;
}
.picker .picker__header > div:not(:last-child) {
  margin-right: 0.46428571em;
}
.picker .picker__header .picker__nav--prev,
.picker .picker__header .picker__nav--next {
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.picker .picker__header .picker__nav--prev:after,
.picker .picker__header .picker__nav--next:after {
  font-family: 'stack-interface';
}
.picker .picker__header .picker__nav--next {
  float: right;
}
.picker .picker__header .picker__nav--next:after {
  content: '\e80c';
}
.picker .picker__header .picker__nav--prev {
  float: left;
}
.picker .picker__header .picker__nav--prev:after {
  content: '\e80b';
}
.picker .picker__day {
  transition: all .3s ease;
  cursor: pointer;
}
.picker .picker__day.picker__day--highlighted {
  color: #4a90e2;
  font-weight: 600;
}
.picker .picker__day:hover {
  color: #4a90e2;
}
.picker:not(.picker--opened) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale(0);
}
.picker .picker__holder {
  outline: none !important;
  border: none !important;
}
.picker table td {
  padding: 0.46428571em;
  padding-top: 0;
}
.picker .picker__footer {
  text-align: center;
}
.picker .picker__button--today {
  float: left;
}
.picker .picker__button--close {
  float: right;
}
.picker button {
  transition: all .3s ease;
  height: auto;
  border: none;
  display: inline-block;
}
.picker button:not(:hover) {
  opacity: .5;
}
/* Stack Horizontal Forms */
.form-horizontal {
  display: flex;
}
.form-horizontal > div[class*='col-'],
.form-horizontal > div[class*='col-']:not(:last-child) {
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.form-horizontal .picker {
  top: 100%;
}
@media all and (max-width: 990px) {
  .form-horizontal {
    display: block;
  }
  label {
    margin-top: 0.46428571em;
  }
  button {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack Sliders -- */
.flickity-prev-next-button {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  border-radius: 0;
  background: #252525;
  width: 2.78571429em;
  height: 5.57142857em;
}
.flickity-prev-next-button:not(:hover):not(:disabled) {
  opacity: .5;
}
.flickity-prev-next-button:hover {
  background: #252525;
}
.flickity-prev-next-button.previous {
  left: 0;
}
.flickity-prev-next-button.next {
  right: 0;
}
.flickity-prev-next-button:before {
  color: #fff;
}
.slider.slider--inline-arrows .flickity-prev-next-button {
  background: none;
}
.slider.slider--inline-arrows .flickity-prev-next-button:before {
  font-size: 1.78571429em;
  color: #252525;
  content: '\e802';
}
.slider.slider--inline-arrows .flickity-prev-next-button.previous:before {
  content: '\e801';
}
.slider.slider--columns .previous {
  left: 15px;
}
.slider.slider--columns .next {
  right: 15px;
}
.slider.border--round .slides li {
  border-radius: 6px;
  overflow: hidden;
}
.slider.boxed--border .slides li {
  border: 1px solid #ececec;
}
.slider .slides li .feature:not(.feature-9) {
  margin-bottom: 0;
}
.slide .boxed:last-child {
  margin-bottom: 0;
}
.slide:not([class*='col-']) > img:only-child {
  width: 100%;
}
.bg--dark .slider.slider--inline-arrows .flickity-prev-next-button:before,
.bg--primary .slider.slider--inline-arrows .flickity-prev-next-button:before,
.imagebg:not(.image--light) .slider.slider--inline-arrows .flickity-prev-next-button:before {
  color: #fff;
}
@media all and (max-width: 990px) {
  .flickity-prev-next-button {
    display: none;
  }
}
@media all and (min-width: 768px) {
  h2 + .slider,
  .h2 + .slider {
    margin-top: 3.71428571em;
  }
}
.slider.slider--ken-burns:not(.parallax) .slide.is-selected > .background-image-holder,
.slider.slider--ken-burns:not(.parallax) .slide.is-selected > img:only-child {
  animation: kenBurns 15s ease alternate infinite;
  -webkit-animation: kenBurns 15s ease alternate infinite;
}
.radial {
  margin-left: auto;
  margin-right: auto;
}
.radial .radial__label {
  transition: opacity .3s ease;
  -webkit-transition: opacity .3s ease;
}
.radial:not(.radial--active) .radial__label {
  opacity: 0;
}
.radial:not(:last-child) {
  margin-bottom: 1.85714286em;
}
@media all and (max-width: 767px) {
  .radial {
    margin-bottom: 1.85714286em;
  }
}
/*! -- Stack Pricing -- */
.pricing {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  position: relative;
}
.pricing h3:first-child {
  margin: 0;
}
.pricing .label {
  top: 2.78571429em;
  right: 2.78571429em;
}
.pricing .h2 {
  margin-bottom: 0.3939393939394em;
}
.pricing ul li:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.pricing ul li .checkmark + span {
  position: relative;
  bottom: 3px;
}
.pricing:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.pricing .h1 {
  font-size: 4.21428571428571em;
  line-height: 1.10169491525424em;
  margin-bottom: 0.22033898305085em;
}
.pricing .h1 .pricing__dollar {
  font-size: 0.51020408163265em;
  position: relative;
  bottom: 20px;
}
.pricing .feature-6 p {
  min-height: 5.57142857em;
}
.pricing-1 .btn:last-child {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0 0 6px 6px;
  padding: 0.92857143em 0;
  margin: 0;
}
.pricing-1 .btn:last-child:hover {
  transform: none !important;
}
.pricing-1 ul {
  margin-bottom: 5.57142857em;
}
.pricing-1 p {
  min-height: 5.57142857em;
}
.pricing-1 p:first-of-type {
  margin-top: 1.85714286em;
}
.pricing-1 p:last-of-type {
  margin-bottom: 5.57142857em;
}
.pricing-1 img + p:first-of-type {
  margin-top: 0;
}
@media all and (min-width: 991px) {
  .pricing-2 ul:first-child {
    margin-top: 4.64285714em;
  }
}
@media all and (max-width: 990px) {
  .pricing-2 {
    text-align: center;
  }
  .pricing-2 ul:first-child {
    margin-top: 1.85714286em;
  }
}
.pricing-3 {
  border: 1px solid #ececec;
  border-radius: 6px;
}
.pricing-3 .pricing__head {
  margin: 0;
  border-bottom: 1px solid #ececec;
}
.pricing-3 .pricing__head + .btn {
  display: block;
  border-radius: 0;
  padding: 0.92857143em 0;
}
.pricing-3 ul {
  border-radius: 0 0 6px 6px;
}
.pricing-3 ul li {
  padding: 0.92857143em 0;
}
.pricing-3 ul li:not(:last-child) {
  border-bottom: 1px solid #ececec;
  margin: 0;
}
.pricing-3 ul:last-child {
  margin: 0;
}
@media all and (max-width: 990px) {
  .pricing-3 {
    margin-bottom: 1.85714286em;
  }
}
@media all and (min-width: 768px) {
  .pricing-section-1 .pricing:not(.boxed--emphasis) {
    left: 0.92857143em;
  }
  .pricing-section-1 .pricing.boxed--emphasis {
    right: 0.92857143em;
  }
}
@media all and (min-width: 990px) {
  .pricing-section-2 div[class*='col-'] {
    padding: 0;
  }
  .pricing-section-2 div[class*='col-']:not(:first-child):not(:last-child) .pricing__head {
    border-radius: 0;
  }
  .pricing-section-2 div[class*='col-']:first-child .pricing {
    border-radius: 6px 0 0 6px;
  }
  .pricing-section-2 div[class*='col-']:first-child .pricing .pricing__head {
    border-radius: 6px 0 0 0;
  }
  .pricing-section-2 div[class*='col-']:last-child .pricing {
    border-radius: 0 6px 6px 6px;
    border-right: 1px solid #ececec;
  }
  .pricing-section-2 div[class*='col-']:last-child .pricing .pricing__head {
    border-radius: 0 6px 0 0;
  }
  .pricing-section-2 .pricing {
    border: 1px solid #ececec;
    border-radius: 0 0 0 6px;
    border-right: none;
  }
  .pricing-section-2 .pricing:hover {
    box-shadow: none;
  }
  .pricing-section-2 .pricing ul {
    margin: 0;
  }
}
.pricing-section-2.bg--dark .pricing,
.pricing-section-2.bg--dark .pricing .pricing__head,
.pricing-section-2.bg--dark .pricing ul li:not(:last-child),
.pricing-section-2.bg--dark div[class*='col-']:last-child .pricing {
  border-color: #3f3f3f;
}
.pricing-section-2.imagebg .pricing,
.pricing-section-2.imagebg .pricing .pricing__head,
.pricing-section-2.imagebg .pricing ul li:not(:last-child),
.pricing-section-2.imagebg div[class*='col-']:last-child .pricing {
  border-color: rgba(255, 255, 255, 0.2);
}
/*! -- Stack Progress Horizontal -- */
.progress-horizontal:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.progress-horizontal.progress-horizontal--lg .progress-horizontal__bar {
  height: 1.85714286em;
}
.progress-horizontal.progress-horizontal--sm .progress-horizontal__bar {
  height: 0.46428571em;
}
.progress-horizontal__bar {
  height: 0.92857143em;
  border-radius: 100px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.progress-horizontal__bar:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.progress-horizontal__progress {
  background: #4a90e2;
  border-radius: 100px;
}
.imagebg .progress-horizontal__bar {
  background: rgba(20, 20, 20, 0.2);
  border: none;
}
/*! -- Stack Maps -- */
iframe[src*='google.com/maps'] {
  border: none;
}
.map-container {
  min-height: 400px;
}
.map-container:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.map-container.border--round {
  overflow: hidden;
  z-index: 2;
}
.map-container.border--round .gm-style {
  border-radius: 6px;
}
.map-container iframe {
  left: 0;
}
@media all and (min-width: 768px) {
  .background-image-holder .map-container,
  .imageblock__content .map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .map-container + div[class*='col-'] {
    margin-top: 3.71428571em;
  }
}
/*! -- Stack Testimonials -- */
.testimonial {
  position: relative;
  overflow: hidden;
}
.testimonial h5:nth-last-child(2) {
  margin: 0;
}
.testimonial .testimonial__image {
  margin-bottom: 1.85714286em;
}
@media all and (min-width: 1025px) {
  .testimonial-1 .switchable__text {
    margin-top: 7.42857143em;
  }
}
.testimonial-2 {
  float: none;
  width: 100%;
}
.testimonial-2 .testimonial__image img {
  max-height: 3.71428571em;
  float: left;
  margin-right: 1.85714286em;
}
.testimonial-2 .boxed {
  width: 100%;
}
.bg--secondary .testimonial-2 .boxed {
  background: #fff;
}
@media all and (max-width: 990px) {
  .testimonial-2 p {
    min-height: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .testimonial-2 p {
    min-height: 0;
  }
}
@media all and (min-width: 768px) {
  .lead + .testimonial-2 {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  .testimonials-1 .list-inline {
    position: relative;
    top: 0.92857143em;
  }
  .testimonials-1 .list-inline li:not(:last-child) {
    margin-right: 3.71428571em;
  }
}
@media all and (max-width: 767px) {
  .testimonials-1 .list-inline {
    margin-top: 1.85714286em;
  }
  .testimonials-1 .list-inline li {
    margin-bottom: 1.85714286em;
  }
}
/*! -- Stack Masonry -- */
.masonry__item:not(:empty) {
  margin-bottom: 30px;
  transform-style: preserve-3d;
}
.masonry__item:not(:empty) article {
  margin: 0;
}
.masonry__item:not(:empty) article .boxed {
  margin: 0;
}
.masonry__item:empty {
  height: 0;
  min-height: 0;
}
.masonry hr + .masonry__container,
.masonry hr + .row {
  margin-top: 4.64285714em;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) {
  display: inline-block;
  cursor: pointer;
  height: 3.71428571em;
  position: relative;
  z-index: 9;
  min-width: 143px;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters {
  cursor: pointer;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  z-index: 2;
  display: inline-block;
  position: absolute;
  left: 0;
  text-align: left;
  padding: 0.92857143em 1.85714286em;
  border: 1px solid #ececec;
  background: #ffffff;
  border-radius: 6px;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul {
  margin: 0;
  cursor: pointer;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: block;
  color: #666666;
  white-space: nowrap;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li.active {
  color: #4a90e2;
  cursor: pointer;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li.active:after {
  content: '\25bc';
  font-size: 8px;
  margin-left: 6px;
  position: relative;
  bottom: 2px;
  display: inline-block;
  transform: scaleX(1.4) scaleY(0.9);
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li:hover {
  color: #4a90e2;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul:hover li.active:after {
  opacity: 0;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):not(:hover) .masonry__filters ul {
  max-height: 1.85714286em;
  overflow: hidden;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):not(:hover) .masonry__filters ul li:not(.active) {
  display: none;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):hover .masonry__filters {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.masonry-filter-holder:not(.masonry-filters--horizontal):hover .masonry__filters ul li.active {
  cursor: default;
}
.masonry-filters--horizontal {
  display: inline-block;
}
.masonry-filters--horizontal .masonry__filters {
  display: inline-block;
  position: relative;
  margin-left: 0.92857143em;
}
.masonry-filters--horizontal .masonry__filters ul li {
  transition: all .3s ease;
  cursor: pointer;
  display: inline-block;
}
.masonry-filters--horizontal .masonry__filters ul li:not(:last-child) {
  margin-right: 1.85714286em;
}
.masonry-filters--horizontal .masonry__filters ul li.active {
  color: #4a90e2;
}
.masonry-filters--horizontal .masonry__filters ul li:not(.active) {
  opacity: .5;
}
.masonry-filters--horizontal .masonry__filters ul li:hover {
  opacity: 1;
}
.masonry-filter-holder + .masonry__container {
  margin-top: 3.71428571em;
}
.bg--dark .masonry-filter-holder .masonry__filters {
  background: #2a2a2a;
  border-color: #3f3f3f;
}
.bg--dark .masonry-filter-holder .masonry__filters li {
  color: #fff;
}
.masonry.masonry-demos .masonry__item:not(:empty) {
  margin-bottom: 3.71428571em;
}
.masonry.masonry-demos .masonry__item h5 {
  margin-bottom: 0;
}
.masonry.masonry-demos .masonry__item:not([style]) .hover-item {
  min-height: 450px;
  background: #323232;
  border: 1px solid #3f3f3f;
}
.masonry.masonry-demos .hover-item {
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
  border-radius: 6px;
  overflow: hidden;
}
.masonry.masonry-demos .hover-item:hover {
  transform: translateY(-10px);
}
.masonry.masonry-demos .masonry__container {
  margin-top: 3.71428571em;
}
/*! -- Stack Blog -- */
article .article__title {
  margin-bottom: 3.71428571em;
}
article .article__title > a {
  display: block;
}
article .article__title > a:hover {
  text-decoration: none;
  opacity: .75;
}
article .article__title h2 {
  margin-bottom: 0.3939393939394em;
}
article .article__title h1 {
  margin-bottom: 0.29545454545455em;
}
article .article__body img,
article .article__body iframe,
article .article__body .video-cover {
  border-radius: 6px;
}
article .article__body img:not(:last-child),
article .article__body iframe:not(:last-child),
article .article__body .video-cover:not(:last-child) {
  margin-bottom: 1.85714286em;
}
article .article__body > a:first-child > img {
  margin-bottom: 1.85714286em;
}
article .article__body p,
article .article__body ul {
  font-size: 1.35714286em;
  line-height: 1.68421053em;
  font-weight: 400;
  color: #808080;
}
article .article__body p:not(:last-child),
article .article__body ul:not(:last-child) {
  margin-bottom: 1.36842105263158em;
}
article .article__body p + .btn:last-child,
article .article__body ul + .btn:last-child {
  margin-top: 0.92857143em;
}
article .article__body p + h5,
article .article__body ul + h5 {
  margin-top: 2.78571429em;
}
article .article__body blockquote {
  text-align: center;
}
article .article__body blockquote:not(:last-child) {
  margin-top: 2.08em;
  margin-bottom: 2.08em;
}
article .article__share {
  margin-top: 5.57142857em;
}
article.imagebg {
  padding: 1.85714286em;
  overflow: hidden;
}
article.imagebg:before {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
article.imagebg:hover:before {
  opacity: .6;
}
article.imagebg .article__title {
  margin: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
  max-width: 90%;
}
article.imagebg .article__title span {
  font-size: 0.85714286em;
}
article.imagebg .label {
  z-index: 2;
  top: 1.85714286em;
  right: 1.85714286em;
}
article.feature-1 h5 {
  min-height: 3.71428571em;
}
article.feature-1 a img {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
article.feature-1 a:hover img {
  opacity: .85;
}
figure[class*='col-'] {
  padding: 0 !important;
}
figure.pull-right {
  margin-left: 2.78571429em;
}
figure.pull-left {
  margin-right: 2.78571429em;
}
figure img:not(:last-child) {
  margin-bottom: 0.46428571em !important;
}
figure figcaption {
  font-size: 0.85714286em;
  font-style: italic;
}
section.unpad > article .imagebg + div {
  padding: 5.57142857em 0;
}
.article__author {
  padding-bottom: 1.85714286em;
}
.article__author img {
  max-height: 3.71428571em;
  margin-bottom: 0.46428571em;
}
.slides--gapless article.imagebg {
  height: 27.85714286em;
}
.masonry-blog-magazine article,
.masonry-blog-magazine .masonry__promo {
  height: 338px;
}
.masonry-blog-magazine .height--tall {
  height: calc(706px);
}
@media all and (min-width: 991px) {
  .masonry__promo.boxed--lg {
    padding: 7.42857143em;
  }
}
@media all and (max-width: 990px) {
  .masonry-blog-magazine .height--tall {
    height: 338px;
  }
  .masonry__promo .pos-vertical-center {
    top: 0;
    transform: none;
  }
}
@media all and (max-width: 1200px) {
  .masonry__promo.boxed--lg {
    padding: 3.71428571em;
  }
}
.pagination {
  text-align: center;
  margin-top: 1.85714286em;
}
.pagination:after {
  content: "";
  display: table;
  clear: both;
}
.pagination a {
  font-weight: normal;
}
.pagination div[class*='col-'] {
  padding: 0;
}
.pagination ol {
  margin-bottom: 0;
  display: inline-block;
}
.pagination ol li {
  display: inline-block;
  margin: 0;
  font-weight: 700;
  line-height: 23px;
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 50%;
  border: 1px solid #ececec;
}
.pagination ol li > a {
  font-weight: 700;
}
.pagination ol li:not(:last-child) {
  margin-right: 0.46428571em;
}
.pagination ol li.pagination__current {
  cursor: default;
  background: #ececec;
  color: #aaa;
}
.pagination__prev,
.pagination__next {
  display: inline-block;
}
.pagination__next {
  float: right;
}
.pagination__prev {
  float: left;
}
.blog-article-wide:not(:last-child) {
  border-bottom: 1px solid #ececec;
  margin-bottom: 3.71428571em;
}
.masonry-blog-list hr + .masonry__container {
  margin-top: 0;
}
.masonry-blog-list article {
  border-bottom: 1px solid #ececec;
  padding-top: 2.78571429em;
  margin-bottom: 5.57142857em;
}
.masonry-blog-list article .article__body {
  padding-bottom: 5.57142857em;
}
.masonry-blog-magazine .masonry__item a {
  font-weight: normal;
}
.masonry-blog-magazine .masonry__item a:hover {
  text-decoration: none;
}
.masonry-filter-container {
  margin-bottom: 1.85714286em;
}
.masonry-filter-container > span {
  position: relative;
}
.masonry-filter-container > span + .masonry-filter-holder {
  margin-left: 0.92857143em;
}
.comments__list li {
  padding: 1.85714286em;
  border: 1px solid #ececec;
  border-bottom: none;
}
.comments__list li:last-child {
  border-bottom: 1px solid #ececec;
}
.comments__list li:nth-child(odd) {
  background: #fcfcfc;
}
.comments__list li:nth-child(even) {
  background: #f9f9f9;
}
.comments__list li:first-child {
  border-radius: 6px 6px 0 0;
}
.comments__list li:last-child {
  border-radius: 0 0 6px 6px;
}
.bg--secondary .comments__list li:nth-child(even) {
  background: #fff;
}
@media all and (min-width: 768px) {
  .comments__list li {
    padding: 2.78571429em;
  }
}
.comment {
  overflow: hidden;
}
.comment:not(:last-child) {
  border-bottom: 1px solid #ececec;
  padding-bottom: 1.85714286em;
}
.comment .comment__avatar {
  width: 15%;
  float: left;
}
.comment .comment__avatar img {
  max-height: 3.71428571em;
}
.comment .comment__meta {
  font-size: 0.85714286em;
}
.comment .comment__meta a {
  display: inline-block;
}
.comment .comment__meta a:not(:first-child) {
  margin-left: 1.08333333333334em;
}
.comment .comment__meta + p {
  margin-top: 0.92857143em;
}
.comment .comment__body {
  width: 85%;
  float: right;
}
.comment .comment__body h5.type--fine-print {
  margin: 0;
}
.comment + .comment {
  margin-top: 1.85714286em;
  padding-left: 15%;
}
@media all and (min-width: 768px) {
  .comments + .comments-form {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  h3 + .related-blog-list {
    margin-top: 3.71428571em;
  }
}
.related-blog-list li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.related-blog-list article img {
  border-radius: 6px;
}
.related-blog-list article .article__title {
  margin-top: 0.92857143em;
  margin-bottom: 0;
}
.related-blog-list article .article__title .h4 {
  margin-bottom: 0.68421052631579em;
}
@media all and (min-width: 991px) {
  .sidebar {
    margin-top: 7.42857143em;
    position: relative;
    bottom: 18px;
  }
}
.sidebar__widget > h5:first-child {
  margin-bottom: 0.92857143em;
}
.sidebar__widget:not(:last-child) {
  margin-bottom: 3.71428571em;
}
.tag-cloud li {
  display: inline-block;
}
.tag-cloud li:not(:last-child) {
  margin-right: 0.46428571em;
}
.tag-cloud li:not(:last-child):after {
  content: ',';
}
.conversation__head {
  border-radius: 6px 6px 0 0;
}
.conversation__head.boxed:not(:last-child) {
  margin: 0;
}
.conversation__head h4 {
  margin: 0;
}
.conversation__head .conversation__avatar {
  margin-top: 0.92857143em;
}
.conversation__head .conversation__avatar img {
  max-height: 1.85714286em;
  display: inline-block;
  margin-right: 0.46428571em;
  margin-bottom: 0;
}
@media all and (min-width: 768px) {
  .conversation__avatar {
    position: absolute;
    top: 1.85714286em;
    right: 2.78571429em;
  }
}
.conversation__reply {
  border-top: none !important;
  border-bottom: none;
  border-radius: 0;
}
.conversation__reply.boxed:not(:last-child) {
  margin-bottom: 0;
}
.conversation__reply + .conversation__comments .comments__list li:first-child {
  border-radius: 0;
  border-top: none;
}
/*! -- Stack Portfolio -- */
.project-thumb > a {
  display: block;
}
.project-thumb:not(.hover-element) > a {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  margin-bottom: 1.85714286em;
}
.project-thumb:not(.hover-element) > a:hover {
  opacity: .85;
}
.project-thumb.hover-element {
  margin: 0;
}
.project-thumb.hover-element > a {
  height: 24.14285714em;
  text-decoration: none;
  font-weight: normal;
}
.project-thumb.hover-element .background-image-holder {
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
}
.project-thumb.hover-element .hover-element__reveal .project-thumb__title {
  padding: 1.85714286em;
  z-index: 2;
  position: relative;
  color: #fff;
}
.project-thumb.hover-element .hover-element__reveal .project-thumb__title h5 {
  margin: 0;
}
.project-thumb.hover-element.hover--active {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.project-thumb.hover-element.hover--active:hover {
  opacity: .85;
}
.project-thumb.hover-element:not(.hover--active)[data-scrim-top] .hover-element__reveal {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  transform: translate3d(0, -100px, 0);
  -webkit-transform: translate3d(0, -100px, 0);
}
.project-thumb.hover-element:not(.hover--active)[data-scrim-top]:hover .hover-element__reveal {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.project-thumb.hover-element [data-scrim-top]:before {
  height: 100%;
}
.project-thumb h4 {
  margin-bottom: 0;
}
.project-thumb.height-40 {
  min-height: 350px;
}
@media all and (max-width: 767px) {
  .project-thumb.height-40 {
    height: 350px;
    min-width: 100%;
  }
}
@media all and (min-width: 768px) {
  .col-xs-12:not(.col-sm-6) .project-thumb.hover-element > a {
    height: 46.42857143em;
  }
}
.project-thumb.border--round .background-image-holder {
  border-radius: 6px;
}
.project-thumb.border--round > a {
  border-radius: 6px;
  overflow: hidden;
}
.project-thumb.border--round:before {
  border-radius: 6px;
  overflow: hidden;
}
.col-md-4 .project-thumb > a:not(.imagebg) {
  margin-bottom: 0.92857143em;
}
/*! -- Stack Shop -- */
.cart-form button[type="submit"] {
  margin-top: 1.85714286em;
}
.cart-total > div {
  overflow: hidden;
}
@media all and (min-width: 768px) {
  .cart-total {
    margin-top: 3.71428571em;
  }
  .cart-customer-details {
    margin-top: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .cart-total {
    margin-top: 1.85714286em;
  }
}
.cart-customer-details div[class*='col-'] {
  margin-top: 0.46428571em;
}
.cart-customer-details .input-checkbox {
  margin: 1.85714286em 0;
}
.cart-customer-details .input-checkbox + span {
  bottom: 38px;
}
.product {
  margin-bottom: 0.92857143em;
}
.product img {
  margin-bottom: 0.92857143em;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  backface-visibility: hidden;
}
.product a:hover img {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
.product .label + img {
  margin-top: 2.78571429em;
}
.product h5 {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 0.46428571em;
}
.product .h4 {
  margin-bottom: 0;
}
.product .h4:first-child {
  clear: left;
}
.product .product__controls {
  overflow: hidden;
  margin-bottom: 0.92857143em;
}
.product .product__controls label,
.product .product__controls button {
  position: relative;
  top: 6px;
}
.product.product--tile {
  padding: 1.85714286em;
  border-radius: 6px;
}
@media all and (min-width: 1200px) {
  .product .btn--cart {
    position: absolute;
    right: 1.85714286em;
    bottom: 1.85714286em;
  }
}
.col-sm-6:not(.col-md-3) .product {
  text-align: center;
}
.col-md-3 .product--tile h5 {
  display: block;
}
.masonry--tiles {
  padding: 10px;
}
.masonry--tiles .masonry__item:not(:empty) {
  padding: 0 6px;
  margin-bottom: 12px;
}
.masonry--tiles .masonry__item:not(:empty) .product--tile {
  margin: 0;
}
.masonry--tiles .masonry__item:not(:empty) .product--tile .label {
  top: 1.85714286em;
  right: 2.78571429em;
}
/*! -- Stack Wizard -- */
.wizard {
  transition: opacity .3s ease;
  opacity: 0;
}
.wizard.active {
  opacity: 1;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background: #4a90e2;
  transition: all .3s ease;
  padding: 0.46428571em 1.85714286em;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: none;
  color: #252525;
}
.wizard > .steps a {
  position: relative;
}
.wizard > .steps a:before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  background: #eee;
  display: block;
  bottom: 0;
  transition: all .2s ease;
}
.wizard > .steps a:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 7px;
  height: 2px;
  background: #eee;
  left: 0;
  transition: all .2s ease;
}
.wizard > .steps li:first-child a:after {
  width: 50%;
  left: 50%;
}
.wizard > .steps li:last-child a:after {
  width: 50%;
}
.wizard > .steps li.current a:after,
.wizard > .steps li.current a:before {
  background-color: #4a90e2;
}
.wizard > .steps a,
.wizard > .steps a:hover {
  padding-bottom: 2.78571429em;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active,
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: none;
  color: #252525;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  margin: 0;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.wizard > .steps > ul,
.wizard > .actions {
  text-align: center;
}
.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: none;
  display: inline-block;
}
.wizard > section {
  padding: 0;
}
.wizard > .content {
  background: #fafafa;
  margin-top: 1.85714286em;
  margin-bottom: 1.85714286em;
  border: 1px solid #ececec;
}
.wizard > .content > .body {
  padding: 1.85714286em;
  width: 100%;
  height: 100%;
}
.wizard > .steps .number {
  font-size: 1em;
}
@media all and (max-width: 767px) {
  .wizard > .steps .number {
    display: block;
  }
  .wizard > .content {
    min-height: 25em;
  }
  .wizard .pos-vertical-center {
    top: 50%;
    transform: translateY(-50%);
    -wekbit-transform: translateY(-50%);
  }
}
.wizard.bg--white {
  background: none !important;
}
.wizard.bg--white > .content {
  background: #fff;
}
.wizard > .content img {
  max-height: 240px;
}
@media all and (max-width: 767px) {
  .wizard > .content img {
    max-height: 120px;
    margin-bottom: 0.92857143em;
  }
}
/*! -- Stack Footers -- */
footer .type--fine-print:not(p) {
  opacity: .5;
}
footer a {
  font-weight: normal;
}
footer:not(.bg--primary):not(.bg--dark) a {
  color: #666666;
}
footer a.type--fine-print:not(:first-child) {
  margin-left: 1.85714286em;
}
footer .logo {
  max-height: 1.67142857em;
}
footer ul:not(.list-inline):not(.slides) > li {
  line-height: 2.32142857em !important;
}
footer.footer-2 .row:last-child {
  margin-top: 3.71428571em;
}
footer.footer-5 .row [class*='col-'] > div:nth-last-child(2) {
  margin: 0.92857143em;
}
footer.footer-5 .row [class*='col-'] > div:nth-last-child(2) .type--fine-print {
  opacity: 1;
}
footer.footer-6 .footer__lower {
  background: #fafafa;
  margin-top: 1.85714286em;
  padding: 1.85714286em 0;
}
footer.footer-6.bg--dark .footer__lower {
  background: #181818;
}
footer.footer-6.bg--secondary .footer__lower {
  background: #f5f5f5;
}
footer.footer-6.bg--primary .footer__lower {
  background: #3483de;
}
footer.footer-7 {
  padding: 1.85714286em 0;
}
@media all and (min-width: 768px) {
  footer .list-inline + .btn {
    position: relative;
    bottom: 4px;
  }
  footer .logo {
    margin: 0;
  }
  footer .logo:not(:last-child) {
    margin-right: 1.85714286em;
  }
  footer.footer-3 .logo + ul {
    position: relative;
    top: 5px;
  }
  footer.footer-3 .logo + ul a {
    color: #252525;
  }
  footer.footer-3 .social-list {
    position: relative;
    top: 9px;
  }
  footer.footer-3 .row:last-child {
    margin-top: 1.85714286em;
  }
  footer.footer-4 .logo + span {
    position: relative;
  }
  footer.footer-4 form {
    position: relative;
    bottom: 4px;
  }
  footer.footer-4 .row:last-child {
    margin-top: 1.85714286em;
  }
  footer.imagebg:not(.image--light) span,
  footer.imagebg:not(.image--light) p,
  footer.imagebg:not(.image--light) a {
    color: #fff;
  }
  .footer-1 .type--fine-print {
    position: relative;
    top: 2px;
  }
}
@media all and (max-width: 767px) {
  footer .social-list {
    margin: 1.85714286em 0;
  }
  .footer-4 form {
    margin: 1.85714286em 0;
  }
  .footer-4 .logo {
    margin: 0;
  }
  .footer-6 .footer__lower .social-list {
    margin-top: 1.85714286em;
  }
  .footer-7 .social-list {
    margin-top: 1.85714286em;
  }
  .footer-1 .social-list {
    margin-top: 1.85714286em;
  }
  .footer-1 .logo {
    display: block;
    margin-top: 1.85714286em;
    margin-bottom: 0.92857143em;
    margin-left: auto;
    margin-right: auto;
  }
}
