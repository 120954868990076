// Block 50
@import "../../common/breakpoints";

section {
  &.block-50 {
    @include media-breakpoint-md-down {
      .c1 {
        order: 1;
        margin-top: 50px;
      }
    }
  }
}
