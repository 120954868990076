//
// block 35
//

.block-35{
    .cta {
      .btn:first-child:not(:last-child){
        margin-bottom: 0;
      }
    }
}
  