//
// Section
//

// variables


// styles
&.section {

  // settings
  .settings {
    width: 100px;

    .buttons {
      align-items: end;

      .button {
        width: 50px;
        height: 50px;
        &.down,
        &.up{
          width: 35px;
          height: 35px;
        }
      }

      .s-edit {
        display: flex;
        margin: 20px -7.5px 20px 0;
        .button-slide{
          width: auto;
          a{
            position: static;
          }
        }
        .button-unhide{
          margin: 0 20px 0 0;  
        }
      }
    }
  }
}