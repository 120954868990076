//
// data-detail
//

@import "../../common/breakpoints";

.page {
  &.data-detail {

    //detail-poster
    .detail-poster{
        @include media-breakpoint-lg {
            height: 557px; // hardcode the height 372 x 557
            width: auto;
        }
    }

    // Recommended
    // Slider

    .page-product{
        .recommended{
            &.tiles{
                margin: 0;

                .slides{
                    line-height: 1.5;
                    border: 0;
                    margin-left: -6px;
                    li{
                        padding: 4px;
                        height: auto;
                        img{
                            margin-bottom: 4px;
                        }
                        a{
                            color: #212529;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
                &.slider--columns{
                    @include media-breakpoint-lg {
                        .previous{
                            left: -45px;
                        }
                        .next{
                            right: -45px;
                        }
                    }
                }

                &.no-slider{
                    .slides{
                        display: flex;
                        margin: 0 auto;
                    }
                }

            }
        }
    }

  }
}
