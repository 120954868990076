//
// Shop
//

.page {
  &.shop {
    .filters {
      .input-search {
        position: relative;
        z-index: 1;
        transition: all 0.3s ease-out;
        width: 100%;
        input{
          &::-webkit-input-placeholder{
            color: black;
          }
          &:focus{
            border-color: #ececec;
          }
        }
        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
        button {
          position: absolute;
          top: 1px;
          right: 1px;
          border: 0;
          margin: 0;
          background-color: transparent;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 37px;
          height: 37px;
          opacity: 0;
          transition: all 0.3s ease-out;
          cursor: pointer;
          margin: 3px;
          &:hover{
            background-color: #e5e5e5;
          }
          span{
            position: relative;
            z-index: 0;
            top:1px;
            left: 1px;
          }
        }
      }

      &.search-expand {
        .input-search {
          @media (min-width: 992px) {
            width: 324%;
          }
          button {
            opacity: 1;
          }
        }
      }
    }
  }
}
