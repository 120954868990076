//
// block 21
//

.block-21{
  
  .video-settings{
    &.with-title{
      margin-right: 24px;
    }
    .video-play-icon{
      margin-right: 0 !important;
    }
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }
  
}
  