.metr_v702 .bgi-no-repeat {
	background-repeat: no-repeat;
}

.metr_v702 .text-inverse-danger {
	color: #ffffff !important;
}

.metr_v702 .text-muted {
	color: #B5B5C3 !important;
}

.metr_v702 .font-weight-bold {
	font-weight: 500 !important;
}

.metr_v702 .font-weight-bolder {
	font-weight: 600 !important;
}

.metr_v702 h2,
.metr_v702 .h2 {
	font-size: 1.75rem;
}

@media (max-width: 1200px) {
	.metr_v702 h2,
	.metr_v702 .h2 {
		font-size: calc(1.3rem + 0.6vw);
	}
}

.metr_v702 .font-size-h5 {
	font-size: 1.25rem !important;
}

.metr_v702 .font-size-sm {
	font-size: 0.925rem;
}

.metr_v702 .mt-5,
.metr_v702 .my-5 {
	margin-top: 1.25rem !important;
}

.metr_v702 .pb-40,
.metr_v702 .py-40 {
	padding-bottom: 10rem !important;
}

.metr_v702 .p-20 {
	padding: 5rem !important;
}

.metr_v702 .pb-5,
.metr_v702 .py-5 {
	padding-bottom: 1.25rem !important;
}

.metr_v702.flex-root {
	-webkit-box-flex: 1;
	flex: 1;
	-ms-flex: 1 0 0px;
}

.metr_v702 .flex-column-auto {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
}

.metr_v702 .flex-column-fluid {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
}

.metr_v702 .flex-row-auto {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.metr_v702 .flex-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.metr_v702 .pt-10,
.metr_v702 .py-10 {
	padding-top: 2.5rem !important; 
}

@media (min-width: 992px) {
	.metr_v702 .pt-lg-40,
	.metr_v702 .py-lg-40 {
		padding-top: 10rem !important;
	}
	.metr_v702 .pt-lg-50,
	.metr_v702 .py-lg-50 {
		padding-top: 12.5rem !important;
	}
}

.metr_v702 .mb-10,
.metr_v702 .my-10 {
	margin-bottom: 2.5rem !important;
}

.metr_v702 .pb-13,
.metr_v702 .py-13 {
	padding-bottom: 3.25rem !important;
}

.metr_v702 .font-size-h4 {
	font-size: 1.35rem !important;
}

.metr_v702 .max-h-100px {
	max-height: 100px !important;
}

.metr_v702 .text-dark {
	color: #181C32 !important;
}

.metr_v702 .text-primary {
	color: #3699FF !important;
}

.metr_v702 a.text-primary:hover,
.metr_v702 a.text-primary:focus {
	color: #0073e9 !important;
}

.metr_v702 .bgi-position-x-center {
	background-position-x: center;
}

.metr_v702 .bgi-position-y-bottom {
	background-position-y: bottom;
}

.metr_v702 .flex-row-fluid {
	-webkit-box-flex: 1;
	flex: 1 auto;
	-ms-flex: 1 0 0px;
	min-width: 0;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	.flex-row-fluid {
		min-width: none;
	}
}

.metr_v702 .pl-25,
.metr_v702 .px-25 {
	padding-left: 3.25rem !important;
}

.metr_v702 .pr-25,
.metr_v702 .px-25 {
	padding-right: 3.25rem !important;
}

@media (min-width: 768px) {
	.metr_v702 .pl-25,
	.metr_v702 .px-25 {
		padding-left: 6.25rem !important;
	}
	.metr_v702 .pr-25,
	.metr_v702 .px-25 {
		padding-right: 6.25rem !important;
	}
}

@media (min-width: 992px) {
	.metr_v702 .font-size-h1-lg {
		font-size: 2rem !important;
		line-height: 1.2;
	}
}

.metr_v702 .form-group {
	margin-bottom: 1.75rem;
}

.metr_v702 .form-group label {
	font-size: 1rem;
	font-weight: 400;
}

.metr_v702 .font-size-h6 {
	font-size: 1.175rem !important;
}

.metr_v702 .pt-7,
.metr_v702 .py-7 {
	padding-top: 1.75rem !important;
}

.metr_v702 .pb-7,
.metr_v702 .py-7 {
	padding-bottom: 1.75rem !important;
}

.metr_v702 .pr-6,
.metr_v702 .px-6 {
	padding-right: 1.5rem !important;
}

.metr_v702 .pl-6,
.metr_v702 .px-6 {
	padding-left: 1.5rem !important;
}

.metr_v702 .form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 1.3rem + 2px);
	padding: 0.65rem 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #3F4254;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 1px solid #E4E6EF;
	border-radius: 0.42rem;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.metr_v702 .form-control {
		-webkit-transition: none;
		transition: none;
	}
}

.metr_v702 .form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}

.metr_v702 .form-control:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #3F4254;
}

.metr_v702 .form-control:focus {
	color: #3F4254;
	background-color: #ffffff;
	border-color: #69b3ff;
	outline: 0;
}

.metr_v702 .form-control::-webkit-input-placeholder {
	color: #B5B5C3;
	opacity: 1;
	font-size: 1.175rem !important
}

.metr_v702 .form-control::-moz-placeholder {
	color: #B5B5C3;
	opacity: 1;
	font-size: 1.175rem !important
}

.metr_v702 .form-control:-ms-input-placeholder {
	color: #B5B5C3;
	opacity: 1;
	font-size: 1.175rem !important
}

.metr_v702 .form-control::-ms-input-placeholder {
	color: #B5B5C3;
	opacity: 1;
	font-size: 1.175rem !important
}

.metr_v702 .form-control::placeholder {
	color: #B5B5C3;
	opacity: 1;
	font-size: 1.175rem !important
}

.metr_v702 .form-control:disabled,
.metr_v702 .form-control[readonly] {
	background-color: #F3F6F9;
	opacity: 1;
}

.metr_v702 .form-control.form-control-solid {
	background-color: #F3F6F9 !important;
	border-color: #F3F6F9 !important;
	color: #3F4254 !important;
	-webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.metr_v702 .form-control.form-control-solid::-moz-placeholder {
	color: #B5B5C3 !important;
	opacity: 1;
}

.metr_v702 .form-control.form-control-solid:-ms-input-placeholder {
	color: #B5B5C3 !important;
}

.metr_v702 .form-control.form-control-solid::-webkit-input-placeholder {
	color: #B5B5C3 !important;
}

.metr_v702 .form-control.form-control-solid:active,
.metr_v702 .form-control.form-control-solid.active,
.metr_v702 .form-control.form-control-solid:focus,
.metr_v702 .form-control.form-control-solid.focus {
	background-color: #EBEDF3 !important;
	border-color: #EBEDF3 !important;
	color: #3F4254 !important;
	-webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
	transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.metr_v702 .rounded-lg {
	border-radius: 0.85rem !important;
}

.metr_v702 .mt-n5,
.metr_v702 .my-n5 {
	margin-top: -1.25rem !important;
}

.metr_v702 .pt-5,
.metr_v702 .py-5 {
	padding-top: 1.25rem !important;
}

@media (min-width: 992px) {
	.metr_v702 .pb-lg-0,
	.metr_v702 .py-lg-0 {
		padding-bottom: 0 !important;
	}
}

.metr_v702 .pl-8,
.metr_v702 .px-8 {
	padding-left: 2rem !important;
}

.metr_v702 .pr-8,
.metr_v702 .px-8 {
	padding-right: 2rem !important;
}

.metr_v702 .pt-4,
.metr_v702 .py-4 {
	padding-top: 1rem !important;
}

.metr_v702 .pb-4,
.metr_v702 .py-4 {
	padding-bottom: 1rem !important;
}

@media (min-width: 992px) {
	.metr_v702 .pt-lg-0,
	.metr_v702 .py-lg-0 {
		padding-top: 0 !important;
	}
}

.metr_v702 form>div[class*='col-']:not(:last-child),
.metr_v702 form>.row>div[class*='col-']:not(:last-child) {
	margin-bottom: 0em;
}


/* Login CSS */
.login.login-1 .login-aside .aside-img-div{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 450px;
	z-index: -2;
  }
.login.login-1 .login-aside .aside-img {
	max-width: 60%;
	height: auto;
	margin: 0 auto;
}
@media (min-width: 992px) {
	.login.login-1 .login-aside,
	.login.login-1 .login-aside .aside-img-div{
		width: 100%;
		max-width: 600px;
	}
	.login.login-1 .login-content {
		width: 100%;
		max-width: 500px;
	}
	.login.login-1 .login-content .login-form {
		width: 100%;
		max-width: 450px;
	}
}

@media (min-width: 992px) and (max-width: 1399.98px) {
	.login.login-1 .login-aside,
	.login.login-1 .login-aside .aside-img-div{
		width: 100%;
		max-width: 450px;
	}
}

@media (max-width: 991.98px) {
	.login.login-1 .login-content .login-form {
		width: 100%;
		max-width: 400px;
	}
}

@media (max-width: 575.98px) {
	.login.login-1 .aside-img {
		min-height: 300px !important;
		background-size: 400px;
	}
	.login.login-1 .login-content .login-form {
		width: 100%;
		max-width: 100%;
	}
}

@media (max-width: 991.98px) {
	.metr_v702 .btn-m-block {
		float: none !important;
	}
	.metr_v702 .btn-m-block .btn.btn-primary {
		display: block !important;
		width: 100%;
		margin-left: 0 !important;
	}
}

.metr_v702 {
	/* starts : custom checkbox css */
	/* end : custom checkbox css */
}

.metr_v702 .kt-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 10px;
	text-align: left;
	cursor: pointer;
	font-size: 1rem;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.metr_v702 .kt-checkbox.kt-checkbox--disabled {
	opacity: 0.8;
	cursor: not-allowed;
}

.metr_v702 .kt-checkbox>input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.metr_v702 .kt-checkbox>span {
	border-radius: 3px;
	background: none;
	position: absolute;
	top: 1px;
	left: 0;
	height: 18px;
	width: 18px;
}

.metr_v702 .kt-checkbox>span:after {
	content: "";
	position: absolute;
	display: none;
	top: 50%;
	left: 50%;
	margin-left: -2px;
	margin-top: -6px;
	width: 5px;
	height: 10px;
	border-width: 0 2px 2px 0 !important;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.metr_v702 .kt-checkbox>input:checked~span {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background: none;
}

.metr_v702 .kt-checkbox>input:checked~span:after {
	display: block;
}

.metr_v702 .kt-checkbox:hover>input:not([disabled]):checked~span,
.metr_v702 .kt-checkbox>input:checked~span {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.metr_v702 .kt-checkbox>input:disabled~span {
	opacity: 0.6;
	pointer-events: none;
}

.metr_v702 .kt-checkbox.kt-checkbox--solid>span {
	border: 1px solid transparent;
}

.metr_v702 .kt-checkbox.kt-checkbox--solid:hover>input:not([disabled])~span,
.metr_v702 .kt-checkbox.kt-checkbox--solid>input:focus~span {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.metr_v702 .kt-checkbox.kt-checkbox--square>span {
	border-radius: 0;
}

.metr_v702 .kt-checkbox.kt-checkbox--bold>span {
	border-width: 2px !important;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.metr_v702 .form-inline .kt-checkbox {
	margin-left: 15px;
	margin-right: 15px;
}

.metr_v702 .kt-checkbox.kt-checkbox--single {
	width: 18px;
	height: 18px;
}

.metr_v702 .kt-checkbox.kt-checkbox--single>span {
	top: 0px;
}

.metr_v702 th>.kt-checkbox.kt-checkbox--single,
.metr_v702 td>.kt-checkbox.kt-checkbox--single {
	right: -5px;
}

.metr_v702 .input-group .kt-checkbox {
	margin-bottom: 0 !important;
	padding-left: 0;
}

.metr_v702 .kt-checkbox>span {
	border: 1px solid #d1d7e2;
}

.metr_v702 .kt-checkbox>span:after {
	border: solid #bfc7d7;
}

.metr_v702 .kt-checkbox.c-checkbox {
	padding-left: 45px;
	padding-top: 5px;
}

.metr_v702 .kt-checkbox.c-checkbox>span {
	height: 28px;
	width: 28px;
	left: 5px;
	border-radius: 0.25rem;
}

.metr_v702 .kt-checkbox.c-checkbox>span {
	border-color: #333333;
}

.metr_v702 .kt-checkbox.c-checkbox>input:checked~span {
	background-color: #333333;
}

.metr_v702 .kt-checkbox.c-checkbox>input:checked~span:after {
	border-color: #fff;
}

.metr_v702 .choose-image {
	position: relative;
	z-index: 0;
}

.metr_v702 .choose-image::after {
	background-image: url(../../../../img/signup/choose-image.png);
	content: " ";
	z-index: 1;
}

.metr_v702 .choose-image .bg-text {
	background-color: white;
	/* Fallback color */
	background-color: rgba(255, 255, 255, 0.4);
	/* Black w/opacity/see-through */
	color: white;
	font-weight: bold;
	z-index: 2;
	width: 80%;
	padding: 20px;
	text-align: center;
}

.metr_v702 .choose-image::after,
.metr_v702 .choose-image .uploaded-image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.metr_v702 .choose-image .uploaded-image {
	z-index: 3;
}

.metr_v702 .choose-image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 2;
}

.metr_v702 .cursor-pointer {
	cursor: pointer;
}

.metr_v702 [class*=bg-]:not(.bg--white):not(.bg--secondary) p,
.metr_v702 [class*=imagebg]:not(.image--light) p {
	opacity: 1;
}


/* starts, login-1 page fixed login-aside */

.login-1 {}

@media (min-width: 992px) {
	.login-1 .login-aside {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow: auto;
	}
}

@media (min-width: 1025px) {
	.login-1 {
		padding-left: 600px;
	}
}

@media (max-width: 1399.98px) and (min-width: 992px) {
	.login-1 {
		padding-left: 450px;
	}
}

.login.login-1 .login-content.login-content-loading {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.login.login-1 .login-content.login-content-loading .login-form {
	max-height: 100vh;
}

/* starts, color-picker */
.login.login-1 .color-picker .color{
	position: relative;
	z-index: 0;
	display: inline-block;
	margin-top: 6px;
}
.login.login-1 .color-picker .color-box-value {
	margin-top: 6px;
}
.login.login-1 .color-picker .color div.selected::before {
	position: absolute;
	top: 13.5px;
	left: 13px;
	color: white;
	font-size: 1.5em;
}
/* starts, color-picker */
/* end, login-1 page fixed login-aside */

.metr_v702 .p-7 {
	padding: 1.75rem !important;
}

@media (min-width: 768px) {
	.metr_v702 .pt-38,
	.metr_v702 .py-38 {
		padding-top: 7rem !important;
	}
	.metr_v702 .pb-38,
	.metr_v702 .py-38 {
		padding-bottom: 7rem !important;
	}
}

.metr_v702 .mb-8,
.metr_v702 .my-8 {
	margin-bottom: 2rem !important;
}

.metr_v702 .mt-8,
.metr_v702 .my-8 {
	margin-top: 2rem !important;
}

.metr_v702 .c-separator hr {
	border-top: 3px dashed rgba(0, 0, 0, 0.1);
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.metr_v702 .btn {
	line-height: 1.5;
}

.metr_v702 .overflow-hidden {
	overflow: hidden !important;
}

