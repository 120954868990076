// modal-side-menu

// default
.modal-side-menu {
  .boxed:not(:last-child){
    margin-bottom: 20px;
  }
  //Collapsible
  .Collapsible {
    //variatiion Adding Sub Title
    &.v-sub-title {
      .Collapsible__trigger {
        padding: 14px 52px 16px 52px !important;
        background-position: 16px center !important;
        &.is-open{
          background-position: 16px center !important;
        }
        small {
          display: block;
          font-size: 12px;
        }
      }
    }
  }
}
