//
// block 20
//

.block-20 {
  &.editable-block {
    .modal-instance {
      &:hover {
        position: relative;
        z-index: 0;
        .edit-button{
          cursor: pointer;
          display: block;
        }
      }
    }
  }
}