//
// booking
//

@import "../../common/breakpoints";

// $week-calendar-days-count : 7;
// $week-calendar-day-size : 70px;
// $week-calendar-day-size-gap : 4px;

.page {
  &.booking {

    //appointment
    .appointment {
      //width: calc($week-calendar-days-count * ($week-calendar-day-size + ($week-calendar-day-size-gap*2)));
      width: 546px;
      max-width: 100%;
      text-align: center;

      @include media-breakpoint-md {
        padding: 42px 0;
      }

      h2 {
        font-weight: 700 !important;
      }

      .m-height {
        height: calc(100vh - 354px);
        @include media-breakpoint-md {
          height: calc(100vh - 446px);
        }
        overflow-y: auto;
        margin: 16px 0;
      }

      .slots {
        .slot{
          &.btn{
            width: 100%;
            padding: 12px;
            margin: 6px 0;
            height: auto;
            border-width: 1px;
            font-size: 14px;
            position: relative;
            z-index: 0;
            &.selected{
              background-color: var(--website-primary-color);
              color: #fff;
              *{
                color: #fff;
              }
              &::before {
                position: absolute;
                font-size: 19.5px;
                color: #fff;
                top: calc(50% - 9px);
                right: 10px;
                z-index: 1;
              }
            }
          }
        }
        &.service-resource{
          &.m-height {
            height: calc(100vh - 219px);
            @include media-breakpoint-md {
              height: calc(100vh - 331px);
            }
          }
          .slot{
            &.btn{
              display: flex;
              align-items: center;
              text-align: left;
              justify-content: space-between;
              img{
                width: 48px;
                height: 48px;
                object-fit: cover;
                border-radius: 50%;
                margin: 0;
              }
              .title{
                text-wrap:wrap;
                small{
                  display: block;
                  font-size: 14px;
                }
              }
              b{
                padding: 0 15px;
              }
            }
          }
        }
      }

      .btn{
        &.btn--primary{
          font-size: 14px;
          font-weight: 400;
          margin: 0;
          &.disabled{
            background-color: #F5F5F5;
            border-color: #F5F5F5;
            color: #999;
            opacity: 1;
            cursor: not-allowed;
          }
        }
      }

      .text-no-availability {
        color: #fa6160;
      }
      .text-availability {
        color: #37db70;
      }

    }
  }
}