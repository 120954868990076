//
// block 36
//
@import "../../common/breakpoints";

.block-36{
    .col-content {
      @include media-breakpoint-md {
        top: -52px;
        .switchable__text{
          display: flex;
          justify-content: center;
          margin: auto;
          align-items: center;
          height: 100%;
          flex-direction: column;
        }
      }
    }
}
  