.custom-file:not(.upload-my-font) {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 54px;
    margin: 0;
    opacity: 0;
    padding: 11px 0 0 9px;
    font-size: 12px;
    border: 0;
    background-color: transparent;
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    padding: 0 44px 0 14px;
    line-height: 54px;
    color: #495057;
    background-color: #fcfcfc;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap; 
    max-width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .custom-file-input:hover + .custom-file-label {
    border-color: rgba(0, 0, 0, 0.87);
  }

  .icon-upload {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    //border-left: 1px solid #ced4da;
    padding: 16px 10px;
    color: #c1c1c1;
  }

}

.validation-error {
  .custom-file {
    .custom-file-label {
      border-color: #fa6160;
    }
    .custom-file-label,
    .icon-upload {
      color: #fa6160;
    }
  }
  .MuiFormHelperText-root {
    color: #fa6160;
  }
}
