//
// Comopnents
// Form

.form-crm{
  .MuiInputBase-input{
    background-color: #fcfcfc !important;
    border-radius: 6px !important;
  }
  .MuiFormLabel-root{
    &.Mui-focused{
      color: inherit !important;
    }
    &.MuiInputLabel-outlined{
      &.MuiInputLabel-shrink{
        transform: translate(14px, -2px) scale(0.75);
      }
    }
  }
  fieldset{
    &.MuiOutlinedInput-notchedOutline{
      legend{
        background-color: #fcfcfc;
        visibility: visible;
        border-radius: 3px;
        span{
          visibility: hidden;
        }
      }
    }
  }
}