.css-metronic .kt-error-v6 {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.css-metronic .kt-error-v6 .kt-error_container {
  text-align: center;
}
.css-metronic .kt-error-v6 .kt-error_container .kt-error_subtitle > h1 {
  font-size: 10rem;
  margin-top: 12rem;
  font-weight: 700;
}
.css-metronic .kt-error-v6 .kt-error_container .kt-error_description {
  margin-top: 3rem;
  font-size: 2.3rem;
  font-weight: 500;
  line-height: 3rem;
}
@media (max-width: 768px) {
  .css-metronic .kt-error-v6 .kt-error_container .kt-error_subtitle > h1 {
    font-size: 5rem;
  }
  .css-metronic .kt-error-v6 .kt-error_container .kt-error_description {
    font-size: 1.7rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}