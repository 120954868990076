// variables
$card-style-border-radius : 18px;
$card-style-gap : 16px;
$card-style-section-default-bg-color : #fff;

@mixin card-style-border-radius {
    border-radius: $card-style-border-radius;
}

.main-container{
    &.website-style-card{
        background-color: var(--card-style-website-card-background-color);
        padding-top: $card-style-gap;
        padding-bottom: $card-style-gap*2;
        @include media-breakpoint-md {
            padding-top: $card-style-gap*4.2;
            padding-bottom: $card-style-gap*2;
        }
        section{

            // stars : Curve Borders for section
            &.section{
                position: relative;
                max-width: calc( 100% - #{$card-style-gap}*2 );
                margin:$card-style-gap auto;
                @include media-breakpoint-lg {
                    max-width: calc( 100% - #{$card-style-gap}*4 );
                    margin:$card-style-gap*2 auto;
                }
                @include card-style-border-radius;
                background-color: $card-style-section-default-bg-color;
            }
            &:first-child{
                &.section{
                    margin-top: 0;
                }
            }
            &:last-child{
                &.section{
                    margin-bottom: 0;
                }
            }
            // end : Curve Borders for section
            
            // stars : Curve Borders for inner childs
            .background-image-holder,
            &[data-gradient-bg] > canvas,
            &[data-overlay]::before,
            [data-overlay]::before,
            .yt-container,
            .map-container{
                @include card-style-border-radius;
            }

            .background-image-holder,
            .map-container{
                @include media-breakpoint-md-down {
                    &.align-left,
                    &.align-right{
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
                @include media-breakpoint-md {
                    &.align-left{
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    &.align-right{
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            .b-radius-0,
            .video-cover .background-image-holder{
                border-radius: 0;
            }
            // end : Curve Borders

            // stars : deep-cardify
            &.deep-cardify{
                padding: 0 !important;
                background-color: transparent !important;
                > {
                    .container{
                        max-width: 100%;
                        .dc-d-none{
                            display: none;
                        }
                        .dc-m-0{
                            margin: 0;
                        }
                        .dc-mt-1{
                            margin-top:$card-style-gap;
                            @include media-breakpoint-lg {
                                margin-top:$card-style-gap*2;
                            }
                        }
                        .dc-mb-1{
                            margin-bottom:$card-style-gap;
                            @include media-breakpoint-lg {
                                margin-bottom:$card-style-gap*2;
                            }
                        }
                        .row{
                            @include media-breakpoint-md {
                                margin-left:-$card-style-gap;
                                margin-right:-$card-style-gap;
                            }
                            @include media-breakpoint-lg {
                                margin-left:-$card-style-gap*2;
                                margin-right:-$card-style-gap*2;
                            }
                        }
                        .item{
                            padding: 0;
                            @include card-style-border-radius;
                            background-color: $card-style-section-default-bg-color;
                            @include media-breakpoint-md-down {
                                margin-top: $card-style-gap;
                                &:first-child{
                                    margin-top: 0;
                                }
                            }
                            @include media-breakpoint-md {
                                margin-left:$card-style-gap/2;
                                margin-right:$card-style-gap/2;
                            }
                            @include media-breakpoint-lg {
                                margin-left:$card-style-gap;
                                margin-right:$card-style-gap;
                            }
                            .feature{
                                &:not(.dc-skip){
                                    height: 100%;
                                }
                            }
                            .feature{
                                margin-bottom: 0;
                                &.dc-padding{
                                    padding: $card-style-gap;
                                    @include media-breakpoint-lg {
                                        padding: $card-style-gap*2;
                                    }
                                }
                                .img-container > img,
                                .tile-image,
                                .bg-img-rounded{
                                        border-radius: $card-style-border-radius $card-style-border-radius 0 0;
                                }
                                .tile-image{
                                    background-color: inherit;
                                }
                                .product-sale-price{
                                    margin: $card-style-gap $card-style-gap 0 0;
                                    @include media-breakpoint-lg {
                                        margin: $card-style-gap*2 $card-style-gap*2 0 0;
                                    }
                                }
                                &.boxed:not([class*='bg-']){
                                    background-color: transparent !important;
                                }
                                &.boxed--border{
                                    border: 0;
                                }
                                .feature__body{
                                    margin-bottom: 0;
                                    border-radius: 0 0 calc( #{$card-style-border-radius} - 1px ) calc( #{$card-style-border-radius} - 1px );
                                    &.boxed--border{
                                        border-width: 0;
                                    }
                                    &.boxed:not([class*='bg-']){
                                        background-color: transparent !important;
                                    }
                                    // block 18
                                    &.b-18{
                                        height: calc(100% - 234px);
                                    }
                                }
                            }
                        }
                        .dc-row-v2,// show two columns from tablet resolutions
                        .dc-row-lg-v2, // show four columns from laptop resolutions
                        .dc-row-v3{ // show three columns from tablet resolutions
                            margin-top:0 !important;
                            @include media-breakpoint-md {
                                margin-top:-$card-style-gap !important;
                                .item{
                                    margin-top:$card-style-gap;
                                    flex-basis: calc(50% - #{$card-style-gap});
                                }
                            }
                            @include media-breakpoint-lg {
                                margin-top:-$card-style-gap*2 !important;
                                .item{
                                    margin-top:$card-style-gap*2;
                                    flex-basis: calc(50% - #{$card-style-gap}*2);
                                }
                            }
                        }
                        .dc-row-lg-v2{ // show four columns from laptop resolutions
                            @include media-breakpoint-lg {
                                .item{
                                    flex-basis: calc(25% - #{$card-style-gap}*2);
                                }
                            }
                        }
                        .dc-row-v3{ // show three columns from tablet resolutions
                            @include media-breakpoint-md {
                                .item{
                                    flex-basis: calc(33.333% - #{$card-style-gap});
                                }
                            }
                            @include media-breakpoint-lg {
                                .item{
                                    flex-basis: calc(33.333% - #{$card-style-gap}*2);
                                }
                            }
                        }
                    }
                }
                &.product-blocks{
                    .tile-product{
                        a{
                            &.block{
                                border-width:0 0 1px 0;
                            }
                        }
                    }
                    .buttons .btn{
                        &:not(:hover){
                            background-color: transparent;
                        }
                    }
                }
            }
            // end : deep-cardify


        }
    }
}