
// imports
@import "../../common/breakpoints";

// CSS
.btn{
  border-width: 2px;
  line-height: 1.715em;
}

.btn--primary{
  background-color: var(--website-primary-color);
  border-color: var(--website-primary-color);
}

// Button Group
.button-group {
  display: inline-grid !important;
  @include media-breakpoint-md {
    display: grid !important;
    &.btn-single{
      display: inline-grid !important;
    }
  }
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  @include media-breakpoint-md {
    grid-gap: 24px;
    grid-auto-flow: column;
  }
  .btn {
    white-space: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.6;
    min-height: 38px;
    @include media-breakpoint-lg {
      min-height: 42px;
    }
    .label{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include media-breakpoint-md {
      &+.btn {
        margin-left: 0;
      }
    }
  }

}

// button
button{
  &.btn{
    &.cs{//custom stylings
      padding: .46428571em 2.78571429em;
    }
  }
}
