//
// Comopnents
// trial expires

.trial-expires {
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before{
    content: "";
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: var(--website-primary-color);
    opacity: 0.5;
  }
  h1{
    color: white;
    position: relative;
    z-index: 1001;
    text-align: center;
  }
}