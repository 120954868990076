// Add Content Block
$add-content-block-width: 600px;

.modal-container {
    &.modal-side-menu {
        .modal-content {
            &.add-content-block {
                width: $add-content-block-width !important;
                .categories {
                    transition: top 0.3s ease;
                    background-color: #f7f7f7;
                    top: -60px;
                }
                &.sticky {
                    .categories {
                        position: fixed;
                        top: 0;
                        right: 0;
                        width: $add-content-block-width;
                        z-index: 1;
                        padding: 8px 20px 8px 20px;
                    }
                }
                .name{
                    font-weight: 400;
                    font-size: 15px;
                    margin-bottom: 16px;
                }
                .image {
                    position: relative;
                    z-index: 0;
                    display: inline-block;
                    img {
                        border-radius: 10px;
                        margin: 0;
                    }
                    .button{
                        position: absolute;
                        top:0;
                        left:0;
                        right:0;
                        bottom:0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(0,0,0,0.2);
                        border-radius: 10px;
                        opacity: 0;
                        transition: all 0.15s ease;
                        button{
                            background-color: rgba($color: #fff, $alpha: 0.9);
                            border: 0;
                            padding: 4px 16px;
                            height: auto;
                            border-radius: 4px;
                            cursor: pointer;
                            box-shadow: 0px 0px 20px 9px rgba(0,0,0,0.1);
                        }
                    }
                    &:hover{
                        .button{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
