//
// Pages
// Product

@import "../../common/breakpoints";

.page {
  &.product {
    // Add to Cart
    @include media-breakpoint-md-down {
      .m-fixed-btn-addtocart {
        bottom: 0;
        z-index: 1;
        margin: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: static;
        &.affix {
          position: fixed;
          min-height: 63px;
          width: auto !important;
          //font-size: 16px;
          animation-name: animation-atc;
          animation-duration: 500ms;
          animation-timing-function: ease;
          animation-fill-mode: forwards;
          border-radius: 0 !important;
          &:hover {
            transform: none;
          }
          & + .link-share {
            margin-top: 62.3px;
          }
        }
      }
    }

    // slider-product-main
    .slider-product-main {
      &.slider {
        min-height: 232px;
        @media (min-width: 768px) {
          min-height: 301px;
        }
        @media (min-width: 992px) {
          min-height: 487px;
        }
      }
      .flickity-page-dots {
        @media (min-width: 768px) {
          display: none;
        }
        bottom: -21px;
        .dot {
          width: 10px;
          height: 10px;
          border-radius: 7.5px;
        }
      }
    }

    // slider-product-nav
    .slider-product-nav {
      @media (max-width: 767px) {
        display: none;
      }
      margin-top: -24px;
      &[data-children="1"] {
        display: none;
      }
      .slides {
        border-color: transparent;
        li {
          &.slide {
            height: 80px;
            width: 100px;
            margin-right: 10px;
            border: 3px solid white;
            &.is-selected {
              // border-color:#ececec !important;
            }
          }
        }
      }
    }
  }
}

/* The animation code */
@keyframes animation-atc {
  from {
    left: 40px;
    right: 40px;
  }
  to {
    left: 0;
    right: 0px;
  }
}
