// Block 41
@import "../../common/breakpoints";

// Variables

// b_v_01a
// b_v_01b
$b_v_01_max_width: 150vh;
$b_v_01_one_top: 18.56vh;
$b_v_01_one_right: -62.69vh;
$b_v_01_two_top: 50.55vh;
$b_v_01_two_right: 39.5vh;
$b_v_01_three_bottom: 0.32vh;
$b_v_01_three_left: -38.57vh;

// b_v_02a
// b_v_02b
$b_v_02_max_width: 100vh;
$b_v_02_top: -15vh;
$b_v_02_right: -30vh;
$b_v_02_two_bottom: -20vh;
$b_v_02_three_bottom: -40vh;
$b_v_02_three_right: 10vh;
$b_v_02_four_left: -40vh;
// Random Position 02
$b_v_02_rp_one_top: 8.53vh;
$b_v_02_rp_one_right: 36.3vh;
$b_v_02_rp_two_top: 72.47vh;
$b_v_02_rp_two_right: 56.09vh;
$b_v_02_rp_three_bottom: 51.27vh;
$b_v_02_rp_three_left: 2.35vh;
$b_v_02_rp_four_bottom: 54.59vh;
$b_v_02_rp_four_left: 73.22vh;
// Random Position 03
$b_v_03_rp_one_top: 52.06vh;
$b_v_03_rp_one_right: 5.07vh;
$b_v_03_rp_two_top: 19.55vh;
$b_v_03_rp_two_right: 99.5vh;
$b_v_03_rp_three_bottom: 46.08vh;
$b_v_03_rp_three_left: 64.46vh;
$b_v_03_rp_four_bottom: 17.15vh;
$b_v_03_rp_four_left: 88.46vh;
// Random Position 04
$b_v_04_rp_one_top: 18.56vh;
$b_v_04_rp_one_right: 18.56vh;
$b_v_04_rp_two_top: 50.55vh;
$b_v_04_rp_two_right: 39.5vh;
$b_v_04_rp_three_bottom: 0.32vh;
$b_v_04_rp_three_left: -38.57vh;
$b_v_04_rp_four_bottom: 51.27vh;
$b_v_04_rp_four_left: 2.35vh;
// Random Position 05
$b_v_05_rp_one_top: -43.12vh;
$b_v_05_rp_one_right: 71.32vh;
$b_v_05_rp_two_top: 36.04vh;
$b_v_05_rp_two_right: 68.56vh;
$b_v_05_rp_three_bottom: 18.13vh;
$b_v_05_rp_three_left: 63.03vh;
$b_v_05_rp_four_bottom: 56.21vh;
$b_v_05_rp_four_left: 44.12vh;

// b_v_03a
// b_v_03b
$b_v_03_size: 240px;
$b_v_03_left: 75%;
$b_v_03_top: 65%;
$b_v_03_two_size: 600px;
$b_v_03_two_left: -25%;
$b_v_03_two_top: -35%;
$b_v_03_three_size: 420px;
$b_v_03_three_left: 33%;
$b_v_03_three_top: -25%;
$b_v_03_four_size: 280px;
$b_v_03_four_left: 8%;
$b_v_03_four_bottom: -8%;

// b_v_04a
// b_v_04b
$b_v_04_size: 120px;

$b_v_04_two_size: 160px;
$b_v_03_two_left: $b_v_03_two_left/2;
$b_v_03_two_top: $b_v_03_two_top/2;

$b_v_04_three_size: 140px;
$b_v_03_three_left: $b_v_03_three_left/2;
$b_v_03_three_top: $b_v_03_three_top/2;

$b_v_04_four_size: 130px;
$b_v_04_five_size: 120px;
$b_v_04_six_size: 100px;

// mixins
@mixin blobs($value) {
  &.b_v_02a,
  &.b_v_02b,
  &.b_v_01a,
  &.b_v_01b {
    .blob {
      &.one,
      &.two,
      &.three,
      &.four,
      &.five {
        max-width: #{$b_v_02_max_width * $value};
        right: #{$b_v_02_right * $value};
        top: #{$b_v_02_top * $value};
      }
      &.two {
        top: auto;
        bottom: #{$b_v_02_two_bottom * $value};
      }
      &.three {
        top: auto;
        bottom: #{$b_v_02_three_bottom * $value};
        right: #{$b_v_02_three_right * $value};
      }
      &.four {
        right: auto;
        left: #{$b_v_02_four_left * $value};
      }
    }
  }
  &.b_v_02a,
  &.b_v_02b {
    // Random Position 02
    &.r_p_02 {
      .blob {
        &.one {
          top: #{$b_v_02_rp_one_top * $value};
          right: #{$b_v_02_rp_one_right * $value};
        }
        &.two {
          top: #{$b_v_02_rp_two_top * $value};
          right: #{$b_v_02_rp_two_right * $value};
        }
        &.three {
          top: auto;
          right: auto;
          bottom: #{$b_v_02_rp_three_bottom * $value};
          left: #{$b_v_02_rp_three_left * $value};
        }
        &.four {
          top: auto;
          right: auto;
          bottom: #{$b_v_02_rp_four_bottom * $value};
          left: #{$b_v_02_rp_four_left * $value};
        }
      }
    }
    // Random Position 03
    &.r_p_03 {
      .blob {
        &.one {
          top: #{$b_v_03_rp_one_top * $value};
          right: #{$b_v_03_rp_one_right * $value};
        }
        &.two {
          top: #{$b_v_03_rp_two_top * $value};
          right: #{$b_v_03_rp_two_right * $value};
        }
        &.three {
          top: auto;
          right: auto;
          bottom: #{$b_v_03_rp_three_bottom * $value};
          left: #{$b_v_03_rp_three_left * $value};
        }
        &.four {
          top: auto;
          right: auto;
          bottom: #{$b_v_03_rp_four_bottom * $value};
          left: #{$b_v_03_rp_four_left * $value};
        }
      }
    }
    // Random Position 04
    &.r_p_04 {
      .blob {
        &.one {
          top: #{$b_v_04_rp_one_top * $value};
          right: #{$b_v_04_rp_one_right * $value};
        }
        &.two {
          top: #{$b_v_04_rp_two_top * $value};
          right: #{$b_v_04_rp_two_right * $value};
        }
        &.three {
          top: auto;
          right: auto;
          bottom: #{$b_v_04_rp_three_bottom * $value};
          left: #{$b_v_04_rp_three_left * $value};
        }
        &.four {
          top: auto;
          right: auto;
          bottom: #{$b_v_04_rp_four_bottom * $value};
          left: #{$b_v_04_rp_four_left * $value};
        }
      }
    }
    // Random Position 05
    &.r_p_05 {
      .blob {
        &.one {
          top: #{$b_v_05_rp_one_top * $value};
          right: #{$b_v_05_rp_one_right * $value};
        }
        &.two {
          top: #{$b_v_05_rp_two_top * $value};
          right: #{$b_v_05_rp_two_right * $value};
        }
        &.three {
          top: auto;
          right: auto;
          bottom: #{$b_v_05_rp_three_bottom * $value};
          left: #{$b_v_05_rp_three_left * $value};
        }
        &.four {
          top: auto;
          right: auto;
          bottom: #{$b_v_05_rp_four_bottom * $value};
          left: #{$b_v_05_rp_four_left * $value};
        }
      }
    }
  }
  &.b_v_03a,
  &.b_v_03b{
    .blob {
      height: #{$b_v_03_size * $value};
      width: #{$b_v_03_size * $value};
      left: #{$b_v_03_left * $value};
      top: #{$b_v_03_top * $value};
      &.two {
        height: #{$b_v_03_two_size * $value};
        width: #{$b_v_03_two_size * $value};
        left: #{$b_v_03_two_left * $value};
        top: #{$b_v_03_two_top * $value};
      }
      &.three {
        height: #{$b_v_03_three_size * $value};
        width: #{$b_v_03_three_size * $value};
        left: #{$b_v_03_three_left * $value};
        top: #{$b_v_03_three_top * $value};
      }
      &.four {
        height: #{$b_v_03_four_size * $value};
        width: #{$b_v_03_four_size * $value};
        left: #{$b_v_03_four_left * $value};
        top: auto;
        bottom: #{$b_v_03_four_bottom * $value};
      }
    }
  }
  &.b_v_04a,
  &.b_v_04b {
    .blob {
      height: #{$b_v_04_size * $value};
      width: #{$b_v_04_size * $value};
      left: 45.52%;
      top: 87.29%;
      &.two {
        height: #{$b_v_04_two_size * $value};
        width: #{$b_v_04_two_size * $value};
        left: 88.13%;
        top: 5.39%;
      }
      &.three {
        height: #{$b_v_04_three_size * $value};
        width: #{$b_v_04_three_size * $value};
        left: 0.31%;
        top: 9.5%;
      }
      &.four {
        height: #{$b_v_04_four_size * $value};
        width: #{$b_v_04_four_size * $value};
        left: 53.31%;
        top: 1.5%;
      }
      &.five {
        height: #{$b_v_04_five_size * $value};
        width: #{$b_v_04_five_size * $value};
        left: 83.31%;
        top: 59.5%;
      }
      &.six {
        height: #{$b_v_04_six_size * $value};
        width: #{$b_v_04_six_size * $value};
        left: 3.31%;
        top: 79.5%;
      }
    }
  }
  &.b_v_01a,
  &.b_v_01b {
    .blob {
      &.one,
      &.two,
      &.three,
      &.four {
        max-width: #{$b_v_01_max_width * $value};
      }
      &.one {
        top: #{$b_v_01_one_top * $value};
        right: #{$b_v_01_one_right * $value};
      }
      &.two {
        top: #{$b_v_01_two_top * $value};
        right: #{$b_v_01_two_right * $value};
      }
      &.three {
        top: auto;
        right: auto;
        bottom: #{$b_v_01_three_bottom * $value};
        left: #{$b_v_01_three_left * $value};
      }
    }
  }
}

// animations
@keyframes b_v_03a_transform {
  0%,
  100% {
    border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
  }
  20% {
    border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
  }
  40% {
    border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
  }
  60% {
    border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
  }
  80% {
    border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
  }
}

@keyframes b_v_03a_movement_03a {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: translate(50%, 20%) rotateY(10deg) scale(1.2);
  }
}

@keyframes b_v_03a_movement_two {
  0%,
  500% {
    transform: none;
  }
  50% {
    transform: translate(50%, 20%) rotate(-200deg) scale(1.2);
  }
}

section {
  &.block-41 {
    //border: 1px solid red;

    @include media-breakpoint-md-down {
      // applies the following rules only
      // if the section is first section of the page
      // if the block is 41
      // if it is parallax
      // if the screens is below 767.98px
      &.first-section {
        &.parallax {
          > .background-image-holder {
            min-height: 120% !important;
            top: -14% !important;
          }
        }
      }
    }

    @include media-breakpoint-down-height-md {
      .pos-vertical-center {
        top: 0;
        transform: none;
        -webkit-transform: none;
      }

      &.height-100 {
        height: auto;
      }
    }

    @media (max-width: 1024px) {
      .yt-wrapper {
        position: unset;
      }
    }

    @include media-comb-one {
      &.height-100 {
        height: 100vh;
      }
    }

    @include media-comb-two {
      &.first-section {
        &.height-half {
          &.parallax {
            > .background-image-holder {
              min-height: 60vh;
            }
          }
        }
      }
    }

    &.block-align-left {
      .img-container {
        display: inline-flex;
      }

      .c-btn-group {
        .btn {
          &.btn--secondary {
            margin-left: 0 !important;
          }

          @include media-breakpoint-md-down {
            + {
              .btn {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }

    &.block-align-right {
      .img-container {
        display: inline-flex;
      }
    }

    img,
    svg {
      &.banner_logo {
        max-height: 300px;
        &.s-large {
          max-height: 400px;
        }
        &.s-small {
          max-height: 200px;
        }
      }
    }

    &[data-gradient-bg] {
      // show Gradient BG on top of the YoutubE Video
      > canvas {
        + .yt-container {
          opacity: 0.2;
        }
      }
    }

    &[data-overlay] {
      // show Data Overlay on top of the YoutubE Video
      .yt-container {
        z-index: 0;
      }
    }

    .blobs {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      .blob {
        &.one,
        &.two,
        &.three,
        &.four,
        &.five {
          fill: var(--website-primary-color);
        }
        &.one {
          //fill: var(--mcs_complimentary_color_00);
        }
        &.two {
          fill: var(--mcs_complimentary_color_00);
        }
        &.three {
          fill: var(--mcs_complimentary_color_01);
        }
        &.four {
          fill: var(--mcs_complimentary_color_02);
        }
      }
      &.b_v_02a,
      &.b_v_02b,
      &.b_v_01a,
      &.b_v_01b {
        .blob {
          &.one,
          &.two,
          &.three,
          &.four,
          &.five {
            width: 100vw;
            position: absolute;
          }
          &.one {
          }
        }
      }
      &.b_v_02a,
      &.b_v_02b {
      }
      &.b_v_03a,
      &.b_v_03b,
      &.b_v_04a,
      &.b_v_04b {
        .blob {
          border-radius: 30% 50% 20% 40%;
          animation: b_v_03a_transform 20s ease-in-out infinite both alternate,
            b_v_03a_movement_03a 40s ease-in-out infinite both;
          position: absolute;
          &.two {
            transform: rotate(-180deg);
            animation: b_v_03a_transform 30s ease-in-out infinite both alternate,
              b_v_03a_movement_two 60s ease-in-out infinite both;
          }
          &.three,
          &.four {
            transform: rotate(-180deg);
            animation: b_v_03a_transform 30s ease-in-out infinite both alternate,
              b_v_03a_movement_two 60s ease-in-out infinite both;
          }
          &.four {
          }
          &.five {
            transform: rotate(-180deg);
            animation: b_v_03a_transform 30s ease-in-out infinite both alternate,
              b_v_03a_movement_two 60s ease-in-out infinite both;
          }
          &.six {
            transform: rotate(-180deg);
            animation: b_v_03a_transform 30s ease-in-out infinite both alternate,
              b_v_03a_movement_two 60s ease-in-out infinite both;
          }
        }
      }
      &.b_v_03a,
      &.b_v_03b {
        .blob {
          &.five,
          &.six {
            display: none;
          }
        }
      }
      &.b_v_01a,
      &.b_v_01b {
        .blob {
          &.one,
          &.two,
          &.three,
          &.four {
            width: 150vw;
          }
          &.four {
            display: none;
          }
        }
      }

      @include blobs(1);
      @include media-breakpoint-lg-down {
        @include blobs(0.4);
        &.b_v_03a,
        &.b_v_03b {
          .blob {
            left: 80%;
            bottom: 25%;
            top: auto;
            &.two {
              left: -40%;
            }
            &.three {
              left: 34.2%;
              top: -7%;
            }
          }
        }
      }
      // colors
      // Overwrite default Smart colors with Primary Color of the Website
      &.b_v_02a,
      &.b_v_01a {
        .blob {
          &.one,
          &.two,
          &.three,
          &.four,
          &.five {
            fill: var(--website-primary-color);
          }
          &.one {
            opacity: 0.8;
          }
          &.two {
            opacity: 0.65;
          }
          &.three {
            opacity: 0.5;
          }
          &.four {
            opacity: 0.35;
          }
        }
      }
      // As 3a and 3b uses Div's, setting background color
      &.b_v_03a,
      &.b_v_03b,
      &.b_v_04a,
      &.b_v_04b {
        .blob {
          background: var(--website-primary-color);
        }
      }
      // Overwites the 3b's 2nd and 3rd blobs with auto colors
      &.b_v_03b,
      &.b_v_04b {
        .blob {
          &.two {
            background: var(--mcs_complimentary_color_00);
          }
          &.three {
            background: var(--mcs_complimentary_color_01);
          }
          &.four {
            background: var(--mcs_complimentary_color_02);
          }
          &.five {
            background: var(--mcs_complimentary_color_03);
          }
          &.six {
            background: var(--mcs_complimentary_color_04);
          }
        }
      }
      // Blbos Color selected from color palette
      &.blobs_color {
        &.b_v_01a,
        &.b_v_02a {
          .blob {
            &.one,
            &.two,
            &.three,
            &.four,
            &.five {
              fill: var(--blobs-color);
            }
          }
        }
        &.b_v_01b,
        &.b_v_02b {
          .blob {
            &.one {
              fill: var(--blobs-color);
            }
          }
        }
        &.b_v_03a,
        &.b_v_04a {
          .blob {
            background-color: var(--blobs-color);
          }
        }
        &.b_v_03b,
        &.b_v_04b {
          .blob {
            &.one {
              background-color: var(--blobs-color);
            }
          }
        }
      }
    }

    &.height-half {
      @include media-breakpoint-lg {
        .blobs {
          @include blobs(0.6);
          &.b_v_03a,
          &.b_v_03b {
            .blob {
              left: 80%;
            }
          }
        }
      }
    }
  }
}
