//
// Titles
//

// variables
$b-tiles-gap : 24px;

// styles
.b-titles {

  &.text-left{
    .textarea-edit{
      text-align: left !important;
    }
  }
  &.text-right{
    .textarea-edit{
      text-align: right !important;
    }
  }

  &:not(.text-left, .text-right){
    text-align: center;
    justify-content: center;
  }

  .b-heading{
    margin-bottom: $b-tiles-gap;
  }

  .b-sub-heading {
    margin-bottom: $b-tiles-gap*2.25;
  }

  .b-heading+.b-sub-heading {
    margin-top: -($b-tiles-gap/2);
  }

  &.dc-mb-1{
    margin-bottom: 32px - $b-tiles-gap !important;
  }

}

// overwrites
section{

  &.block-39,
  &.block-38,
  &.block-40{
    .b-titles {

      .b-sub-heading {
        margin-bottom: $b-tiles-gap - 16px;
      }

    }
  }

  &.block-08,
  &.block-11{
    .b-titles {

      .b-sub-heading {
        margin-bottom: 0;
      }

    }
  }

}
