.c-widgets {

  &:not(.open) {
    .bg-mask {
      display: none;
    }
  }

  &.open {
    .bg-mask {
      background: transparent;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
    }
  }

  &.close {
    // close state
    .c-widgets-box,
    .c-widgets-button .close {
      display: none;
    }
  }

  &.open {
    // open state
    .c-widgets-button .default {
      display: none;
    }
  }

  .c-widgets-box {
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
    z-index: 99;
    overflow: hidden;
    z-index: 2000;
    bottom: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 993px) {
      width: 376px;
      min-height: 250px;
      max-height: 585px;
      right: 20px;
      bottom: 100px;
      border-radius: 8px;
    }

    .c-widgets-head {
      background: linear-gradient(
        135deg,
        var(--website-primary-color-o-90) 0%,
        var(--website-primary-color) 100%
      );
      min-height: 75px;
      color: white;

      .logo img {
        height: 40px;
      }

      a.close {
        &:hover {
          text-decoration: none;
        }
      }
    }

    .c-widgets-footer {
      &.d-none {
        display: none !important;
      }

      background: #fff;
      padding: 13px 16px;
      box-shadow: rgba(0, 0, 0, 0.04) 0px -10px 10px 1px;
      opacity: 0.97;

      svg {
        width: 24px;
        height: 16px;
        color: rgb(119, 118, 120);
        vertical-align: sub;
        padding-right: 8px;
      }

      img {
        width: 32px;
        padding-right: 8px;
        margin-bottom: 0;
      }
    }
  }

  .c-widgets-button {
    position: fixed;
    background: var(--website-primary-color);
    border-radius: 999rem;
    right: 20px;
    bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 32px 0px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 26px 13px 22px;
    color: #fff;
    transition: all 0.5s ease;

    svg {
      height: 20px;
      width: 20px;
      margin: 2px 2px 2px 4px;
      margin-right: 8px;
      path {
        fill: #fff;
      }
    }

    span.close {
      margin-right: 8px;
    }

    .material-icons {
      color: #fff;
    }

    &:hover {
      text-decoration: none;
    }

    &.up-from-btm {
      bottom: 79px;
    }
  }

  @import "./chat/live-chat";
  @import "./payment/payment";

}
